import dayjs from 'dayjs';

import { EMPTY_STRING } from 'constants/common';

import { DateTime } from 'types/message';

/**
 * @param {string} time - HH:mm 형식의 문자열
 * @returns {{hour: number, minute: string}}
 */
export const getParsedTime = (time: string) => {
  const splitTime = time.split(':');
  const parsedTime = {
    hour: Number(splitTime[0]),
    minute: Number(splitTime[1]),
  };
  return parsedTime;
};

export const getDateTimeString = (dateTime: DateTime) => {
  const { hour, minute } = getParsedTime(dateTime.time);
  const dateTimeISOString = dayjs(dateTime.date)
    .hour(hour)
    .minute(minute)
    .second(0)
    .millisecond(0)
    .toISOString();
  return dateTimeISOString;
};

export const validateDateTime = (dateTime: DateTime) => {
  const dateTimeString = getDateTimeString(dateTime);
  return dayjs(dateTimeString).isAfter(dayjs(), 'minutes');
};

export const getDateTime = (rawDate: dayjs.ConfigType) => {
  const dateTime: DateTime = {
    date: EMPTY_STRING,
    time: EMPTY_STRING,
  };

  const dayjsObject = dayjs(rawDate);
  dateTime.date = dayjsObject.toISOString();
  dateTime.time = dayjsObject.format('HH:mm');

  return dateTime;
};
