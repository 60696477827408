import { ExternalUserProfileResponse } from '@pickme/core';
import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import { SocialProvider } from 'types/auth';

const APIS: Apis = {
  getProfile: { method: 'GET', url: '/v2/auth/profile' },
  socialDisconnect: { method: 'PUT', url: '/v2/user/social/unlink' },
  signOut: { method: 'POST', url: '/v2/auth/logout' },
};

export const createGetProfile = () =>
  createHttpRequest<ExternalUserProfileResponse>({
    ...APIS.getProfile,
    withoutOrganizationOid: true,
  });

export const createSignOutRequest = () =>
  createHttpRequest({
    ...APIS.signOut,
    withoutOrganizationOid: true,
  });

export const createSocialDisconnectRequest = (provider: SocialProvider) =>
  createHttpRequest({
    ...APIS.socialDisconnect,
    data: {
      provider,
    },
    withoutOrganizationOid: true,
  });
