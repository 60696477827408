import { ChangeEventHandler, Ref, forwardRef } from 'react';
import { IconInput, colors } from '@pickme/design-system';

import CloseIcon from 'resources/icons/application-v2/close';
import SearchIcon from 'resources/icons/dashboard/search.svg';

import { input } from './index.css';

type Props = {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClear: () => void;
  onSubmit: () => void;
};

function PollSearchInput(
  { value, onChange, onClear, onSubmit }: Props,
  ref: Ref<HTMLInputElement>,
) {
  const isKeywordRemovable = !!value;

  return (
    <IconInput
      icon={isKeywordRemovable ? <CloseIcon color={colors['gray-700']} /> : SearchIcon}
      placeholder='검색어를 입력해주세요'
      width={420}
      containerClassName={input}
      value={value}
      onChange={onChange}
      onClick={() => {
        if (isKeywordRemovable) {
          onClear();
          return;
        }
        onSubmit();
      }}
      ref={ref}
    />
  );
}

export default forwardRef(PollSearchInput);
