import { useEffect, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Callout, Button, OutlineButton } from '@pickme/design-system';
import { Poll } from '@pickme/core';
import { t } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Spinner from 'components/common/Spinner';
import PollSelectModal from 'components/features/notification-v2/modal/PollSelect';
import BalanceModal from 'components/features/notification-v2/modal/Balance';
import AlimTalkTemplate from 'components/features/notification-v2/Template';
import TargetFields from 'components/features/notification-v2/fields/send/Target';
import MessageFields from 'components/features/notification-v2/fields/send/Message';
import NotificationSendCard from 'components/features/notification-v2/cards/Send';
import SendReservationModal from 'components/features/notification-v2/modal/Reservation';
import ReservationDate from 'components/features/notification-v2/ReservationDate';

import { useSendAlimTalk } from 'query-hooks/message';
import { useGetKakaoChannel } from 'query-hooks/kakao-channel-v2';
import { useGetPollsWithIds } from 'query-hooks/poll-v2';

import { useGetFeatureAccess } from 'hooks/useGetFeatures-v2';

import { toast } from 'states/toast';

import { FeatureTitleV2, MessagingAction } from 'types/features-v2';
import { AlimTalkForm, TemplateType, MessagingType, RecipientType } from 'types/message';
import { type SendOutletContext } from 'pages/notification/Send';

import { sendButtons, calloutTitle, point } from '../common.css';
import { formContainer, leftSide, rightSide } from './index.css';

function AlimTalk() {
  const setToast = useSetRecoilState(toast);
  const { enabled: enableToUseNotification } = useGetFeatureAccess({
    feature: FeatureTitleV2.메시지,
    actionName: MessagingAction.메시지보내기,
  });
  const { enabled: enableToUseNotificationChannel } = useGetFeatureAccess({
    feature: FeatureTitleV2.메시지,
    actionName: MessagingAction.채널조회,
  });

  const [isPollSelectorModalVisible, setPollSelectorModalVisible] = useState(false);
  const [isBalanceModalVisible, setBalanceModalVisible] = useState(false);
  const [isSendReservationModalVisible, setIsSendReservationModalVisible] = useState(false);

  const { pollId } = useOutletContext<SendOutletContext>();

  const methods = useForm<AlimTalkForm>({
    mode: 'onTouched',
    defaultValues: {
      pollIds: pollId ? [pollId] : [],
      recipientType: RecipientType.All,
      templateType: '1',
      channelId: '',
      dateTime: {
        date: '',
        time: '',
      },
    },
    resolver: yupResolver(schema),
  });
  const pollIds = useWatch({ control: methods.control, name: 'pollIds' });
  const results = useGetPollsWithIds(pollIds);
  const polls = results.map((result) => result.data).filter(Boolean) as Poll[];

  const { mutate, isLoading } = useSendAlimTalk();
  const { data: channel, isSuccess } = useGetKakaoChannel();

  useEffect(() => {
    if (enableToUseNotificationChannel && channel && channel.status === 'approved') {
      methods.setValue('channelId', channel.channelId);
    }
  }, [isSuccess]);

  const onSubmit: SubmitHandler<AlimTalkForm> = (form) => {
    const type =
      form.dateTime?.date && form.dateTime.time ? MessagingType.Reserve : MessagingType.Send;
    const data = { form, type };
    mutate(data, {
      onError: (error) => {
        if (error.code === 'MESSAGE-004' || error.code === 'WALLET-001') {
          setBalanceModalVisible(true);
        }
      },
    });
  };

  const onError: SubmitErrorHandler<AlimTalkForm> = (data) => {
    setToast({
      isVisible: true,
      type: 'error',
      message: data.pollIds?.message || data.dateTime?.message || '',
    });
  };

  return (
    <>
      <Callout>
        <Callout.Title className={calloutTitle}>
          알림톡 1건 : 10P
          <Link to='/service/notification/point' className={point} unstable_viewTransition>
            충전하기
          </Link>
        </Callout.Title>

        <Callout.Sentence>
          참여 설정을 명부에 등록된 사람으로 한 경우에만 메시지 전송 기능을 이용할 수 있어요.
        </Callout.Sentence>
        <Callout.Sentence>
          참여자 명부에 휴대전화번호가 등록되어 있어야 메시지를 전송할 수 있어요.
        </Callout.Sentence>

        {/* TODO: 기능 추가 후 복원 */}
        {/* {enableToEnterIntegration ? (
          <Callout.Sentence>
            {'발신 프로필 설정은 [설정>연동] 메뉴에서 카카오 알림톡 연동 기능을 이용해주세요.'}
            <Link to='/settings/integration' className={link} unstable_viewTransition>
              연동하기
            </Link>
          </Callout.Sentence>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )} */}
      </Callout>

      <FormProvider {...methods}>
        <form className={formContainer} onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <div className={leftSide}>
            <NotificationSendCard title={t('admin:pages.notification.send.form.header.0')}>
              <TargetFields
                polls={polls}
                isRecipientTypeDisabled={!enableToUseNotification}
                onClickAddPolls={() => setPollSelectorModalVisible(true)}
                pointPerVoter={10}
              />
            </NotificationSendCard>

            <NotificationSendCard title={t('admin:pages.notification.send.form.header.1')}>
              <MessageFields
                isChannelIdDisabled={!enableToUseNotificationChannel}
                isSendingMethodDisabled={!enableToUseNotification}
              />
            </NotificationSendCard>
          </div>

          <div className={rightSide}>
            <AlimTalkTemplate template={methods.watch('templateType')} />

            <div className={sendButtons.container}>
              <OutlineButton
                size='lg'
                variant='gray'
                className={sendButtons.reserve}
                onClick={() => setIsSendReservationModalVisible(true)}
              >
                예약
              </OutlineButton>

              <Button
                size='lg'
                type='submit'
                disabled={isLoading || !enableToUseNotification}
                className={sendButtons.submit}
              >
                {isLoading ? <Spinner /> : '전송하기'}
              </Button>
            </div>

            <ReservationDate />
          </div>
        </form>
      </FormProvider>

      <BalanceModal
        isVisible={isBalanceModalVisible}
        onClose={() => setBalanceModalVisible(false)}
      />

      <PollSelectModal
        isVisible={isPollSelectorModalVisible}
        submittedPollIds={pollIds}
        onClose={() => setPollSelectorModalVisible(false)}
        onSubmit={(selectedPollIds) => {
          methods.setValue('pollIds', selectedPollIds);
          setPollSelectorModalVisible(false);
        }}
      />

      <SendReservationModal
        title='발송 예약'
        description='설정한 예약 시간에 알림톡이 전송됩니다.'
        isVisible={isSendReservationModalVisible}
        onClose={() => setIsSendReservationModalVisible(false)}
        value={methods.watch('dateTime')}
        onSubmit={(value) => {
          methods.setValue('dateTime', value);
        }}
      />
    </>
  );
}

const schema = yup.object().shape({
  pollIds: yup.array().required().min(1, t('admin:pages.notification.send.errorMessage.noPolls')),
  recipientType: yup.mixed<RecipientType>().oneOf(Object.values(RecipientType)).required(),
  templateType: yup.mixed<TemplateType>().oneOf(['1', '2', '3', '4', '5']).required(),
  channelId: yup.string().notRequired().default('').nonNullable(),
});

export default AlimTalk;
