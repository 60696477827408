import { PollKind } from '@pickme/core';
import { Tooltip } from '@pickme/design-system';
import { POLL_KIND_LABEL_V2 } from 'constants/poll-v2';

import CandidateElectionIcon from 'resources/icons/poll-v2/candidate-election.svg';
import IssueElectionIcon from 'resources/icons/poll-v2/issue-election.svg';
import SurveyIcon from 'resources/icons/poll-v2/survey.svg';
import { icon } from './index.css';

type Props = {
  kind: PollKind;
};

const ICON = {
  [PollKind.CandidateElection]: CandidateElectionIcon,
  [PollKind.IssueElection]: IssueElectionIcon,
  [PollKind.Survey]: SurveyIcon,
};

function PollKindIcon({ kind }: Props) {
  return (
    <Tooltip message={POLL_KIND_LABEL_V2[kind]} placement='left'>
      <img src={ICON[kind]} alt='poll kind' className={icon} />
    </Tooltip>
  );
}

export default PollKindIcon;
