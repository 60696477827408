import { isEqual } from 'lodash-es';
import { UseFormReturn } from 'react-hook-form';

import { useGetDraft } from 'query-hooks/draft';

import {
  BasicFormBody,
  SubElectionsFormBody,
  VoterFormBody,
  AdditionalFormBody,
  QuestionsFormBody,
} from 'types/application';

export function useElectionFormChanged(form: {
  basicForm: UseFormReturn<BasicFormBody>;
  subElectionsForm: UseFormReturn<SubElectionsFormBody>;
  votersForm: UseFormReturn<VoterFormBody>;
  additionalForm: UseFormReturn<AdditionalFormBody>;
}) {
  const { basicForm, subElectionsForm, votersForm, additionalForm } = form;

  return (
    basicForm.formState.isDirty ||
    subElectionsForm.formState.isDirty ||
    votersForm.formState.isDirty ||
    additionalForm.formState.isDirty
  );
}

export function useSurveyFormChanged(form: {
  basicForm: UseFormReturn<BasicFormBody>;
  questionsForm: UseFormReturn<QuestionsFormBody>;
  votersForm: UseFormReturn<VoterFormBody>;
  additionalForm: UseFormReturn<AdditionalFormBody>;
}) {
  const { basicForm, questionsForm, votersForm, additionalForm } = form;

  return (
    basicForm.formState.isDirty ||
    questionsForm.formState.isDirty ||
    votersForm.formState.isDirty ||
    additionalForm.formState.isDirty
  );
}

export function useElectionDraftFormChanged(
  draftId: string,
  form: {
    basicForm: UseFormReturn<BasicFormBody>;
    subElectionsForm: UseFormReturn<SubElectionsFormBody>;
    votersForm: UseFormReturn<VoterFormBody>;
    additionalForm: UseFormReturn<AdditionalFormBody>;
  },
) {
  const { data } = useGetDraft('election', draftId);

  const { basicForm, subElectionsForm, votersForm, additionalForm } = form;

  const expectedBasicForm = {
    type: 'Election',
    voterType: 'voterBook',
    name: data?.content.name,
    periods: data?.content.periods,
    description: data?.content.description,
    subscriptionType: data?.content.subscriptionType,
  };

  const expectedSubElectionsForm = {
    subElections: data?.content.subElections,
  };

  const expectedVotersForm = {
    voterBook: data?.content.voterBook,
    incorrectVoters: data?.content.incorrectVoters,
  };

  const expectedAdditionalForm = {
    openThreshold: data?.content.openThreshold,
    isOpenPollRate: data?.content.isOpenPollRate,
    isOpenPollResult: data?.content.isOpenPollResult,
    voterAuthMethods: data?.content.voterAuthMethods,
  };

  const hasBasicFormChanged = !isEqual(expectedBasicForm, basicForm.getValues());
  const hasSubElectionsFormChanged = !isEqual(
    expectedSubElectionsForm,
    subElectionsForm.getValues(),
  );
  const hasVotersFormChanged = !isEqual(expectedVotersForm, votersForm.getValues());
  const hasAdditionalFormChanged = !isEqual(expectedAdditionalForm, additionalForm.getValues());

  return (
    hasBasicFormChanged ||
    hasSubElectionsFormChanged ||
    hasVotersFormChanged ||
    hasAdditionalFormChanged
  );
}

export function useSurveyDraftFormChanged(
  draftId: string,
  form: {
    basicForm: UseFormReturn<BasicFormBody>;
    questionsForm: UseFormReturn<QuestionsFormBody>;
    votersForm: UseFormReturn<VoterFormBody>;
    additionalForm: UseFormReturn<AdditionalFormBody>;
  },
) {
  const { data } = useGetDraft('survey', draftId);
  const { basicForm, questionsForm, votersForm, additionalForm } = form;

  const expectedBasicForm = {
    type: 'Survey',
    voterType: 'voterBook',
    name: data?.content.name,
    periods: data?.content.periods,
    description: data?.content.description,
    subscriptionType: data?.content.subscriptionType,
    coverImageFileUrl: data?.content.coverImageFileUrl,
  };

  const expectedQuestionsForm = {
    questions: data?.content.questions,
  };

  const expectedVotersForm = {
    voterBook: data?.content.voterBook,
    hasInstantVoter: data?.content.hasInstantVoter,
    incorrectVoters: data?.content.incorrectVoters,
  };

  const expectedAdditionalForm = {
    allowRealTimeResult: data?.content.allowRealTimeResult,
    openThreshold: data?.content.openThreshold,
    isOpenPollRate: data?.content.isOpenPollRate,
    isOpenPollResult: data?.content.isOpenPollResult,
    voterAuthMethods: data?.content.voterAuthMethods,
    hasOpenVotes: data?.content.hasOpenVotes,
  };

  const hasBasicFormChanged = !isEqual(expectedBasicForm, basicForm.getValues());
  const hasQuestionsFormChanged = !isEqual(expectedQuestionsForm, questionsForm.getValues());
  const hasVotersFormChanged = !isEqual(expectedVotersForm, votersForm.getValues());
  const hasAdditionalFormChanged = !isEqual(expectedAdditionalForm, additionalForm.getValues());

  return (
    hasBasicFormChanged ||
    hasQuestionsFormChanged ||
    hasVotersFormChanged ||
    hasAdditionalFormChanged
  );
}
