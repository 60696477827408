import { POLL_LABELS, PollGroup, PollKind, VoterAuthMethodType } from '@pickme/core';
import { colors } from '@pickme/design-system';

import { ElectionTypeCase, SurveyTypeCase, SurveyTypeCategory } from 'types/application-v2';
import {
  ApprovedPollSearchGroup,
  PollAction,
  PollKindForSearchV2,
  PollSearchGroupV2,
} from 'types/poll-v2';

import CheckBoxIcon from 'resources/icons/poll-v2/checkbox.svg';
import GraphIcon from 'resources/icons/poll-v2/graph.svg';
import OXIcon from 'resources/icons/poll-v2/ox.svg';
import ScoreIcon from 'resources/icons/poll-v2/score.svg';

import 개인정보Icon from 'resources/icons/poll-v2/개인정보.svg';
import 객관식Icon from 'resources/icons/poll-v2/객관식.svg';
import 날짜Icon from 'resources/icons/poll-v2/날짜.svg';
import 동영상Icon from 'resources/icons/poll-v2/동영상.svg';
import 드롭다운Icon from 'resources/icons/poll-v2/드롭다운.svg';
import 링크Icon from 'resources/icons/poll-v2/링크.svg';
import 별점Icon from 'resources/icons/poll-v2/별점.svg';
import 선형배율Icon from 'resources/icons/poll-v2/선형배율.svg';
import 선호도Icon from 'resources/icons/poll-v2/선호도.svg';
import 시간Icon from 'resources/icons/poll-v2/시간.svg';
import 이메일Icon from 'resources/icons/poll-v2/이메일.svg';
import 이미지Icon from 'resources/icons/poll-v2/이미지.svg';
import 점수평가Icon from 'resources/icons/poll-v2/점수평가.svg';
import 주관식Icon from 'resources/icons/poll-v2/주관식.svg';
import 휴대폰번호Icon from 'resources/icons/poll-v2/휴대폰번호.svg';

export const ELECTION_BADGES: {
  type: ElectionTypeCase;
  backgroundColor: string;
  textColor: string;
  icon: string;
}[] = [
  {
    type: ElectionTypeCase.선택투표,
    backgroundColor: '#E3EBFF',
    textColor: '#4356FF',
    icon: CheckBoxIcon,
  },
  {
    type: ElectionTypeCase.찬반투표,
    backgroundColor: '#E1F4FF',
    textColor: '#23B6F0',
    icon: OXIcon,
  },
  {
    type: ElectionTypeCase.순위투표,
    backgroundColor: '#E6E1FF',
    textColor: '#8340E2',
    icon: GraphIcon,
  },
  {
    type: ElectionTypeCase.점수투표,
    backgroundColor: '#FAE8C0',
    textColor: '#FF9318',
    icon: ScoreIcon,
  },
];

export const ISSUE_ELECTION_TYPES = [
  {
    id: ElectionTypeCase.선택투표,
    label: '선택투표',
  },
  {
    id: ElectionTypeCase.찬반투표,
    label: '찬반투표',
  },
  {
    id: ElectionTypeCase.순위투표,
    label: '순위투표',
  },
  {
    id: ElectionTypeCase.점수투표,
    label: '점수투표',
  },
];

export const CANDIDATE_ELECTION_TYPES = [
  {
    id: ElectionTypeCase.선택투표,
    label: '선택선거',
  },
  {
    id: ElectionTypeCase.찬반투표,
    label: '찬반선거',
  },
  {
    id: ElectionTypeCase.순위투표,
    label: '순위선거',
  },
  {
    id: ElectionTypeCase.점수투표,
    label: '점수선거',
  },
];

export const CANDIDATE_ELECTION_TOOLTIP_MESSAGES = {
  [ElectionTypeCase.선택투표]:
    '1명 이상의 후보 중 관리자가 설정한 후보 수만큼 선택할 수 있는 투표 유형입니다.',
  [ElectionTypeCase.찬반투표]:
    '1명 이상의 각 후보자를 찬성 또는 반대할 수 있는 투표 유형입니다. 단일후보일 경우 찬반투표를 이용해 보세요.',
  [ElectionTypeCase.순위투표]: '2명 이상의 후보자에게 순위를 부여할 수 있는 투표 유형입니다.',
  [ElectionTypeCase.점수투표]: '1명 이상의 후보자에게 점수를 부여할 수 있는 투표 유형입니다.',
};

export const ISSUE_ELECTION_TOOLTIP_MESSAGES = {
  [ElectionTypeCase.선택투표]:
    '1개 이상의 항목 중 관리자가 설정한 항목 수만큼 선택할 수 있는 투표 유형입니다.',
  [ElectionTypeCase.찬반투표]: '1개 이상의 항목에 찬성 또는 반대할 수 있는 투표 유형입니다.',
  [ElectionTypeCase.순위투표]: '2개 이상의 항목에 순위를 부여할 수 있는 투표 유형입니다.',
  [ElectionTypeCase.점수투표]: '1개 이상의 항목에 점수를 부여할 수 있는 투표 유형입니다.',
};

export const SURVEY_CATEGORY_LABELS = {
  [SurveyTypeCategory.문제유형]: '문제 유형',
  [SurveyTypeCategory.정보입력유형]: '정보 입력 유형',
  [SurveyTypeCategory.개인정보수집동의]: '개인정보 수집 동의',
};

export const SURVEY_TYPES = [
  { id: SurveyTypeCase.객관식, category: SurveyTypeCategory.문제유형, label: '객관식' },
  { id: SurveyTypeCase.주관식, category: SurveyTypeCategory.문제유형, label: '주관식' },
  { id: SurveyTypeCase.이미지선택, category: SurveyTypeCategory.문제유형, label: '이미지선택' },
  { id: SurveyTypeCase.동영상선택, category: SurveyTypeCategory.문제유형, label: '동영상선택' },
  { id: SurveyTypeCase.선호도평가, category: SurveyTypeCategory.문제유형, label: '선호도평가' },
  { id: SurveyTypeCase.점수평가, category: SurveyTypeCategory.문제유형, label: '점수평가' },
  { id: SurveyTypeCase.선형배율, category: SurveyTypeCategory.문제유형, label: '선형배율' },
  { id: SurveyTypeCase.별점, category: SurveyTypeCategory.문제유형, label: '별점' },
  { id: SurveyTypeCase.드롭다운, category: SurveyTypeCategory.문제유형, label: '드롭다운' },
  { id: SurveyTypeCase.이메일주소, category: SurveyTypeCategory.정보입력유형, label: '이메일주소' },
  { id: SurveyTypeCase.휴대폰번호, category: SurveyTypeCategory.정보입력유형, label: '휴대폰번호' },
  { id: SurveyTypeCase.날짜, category: SurveyTypeCategory.정보입력유형, label: '날짜' },
  { id: SurveyTypeCase.시간, category: SurveyTypeCategory.정보입력유형, label: '시간' },
  { id: SurveyTypeCase.링크, category: SurveyTypeCategory.정보입력유형, label: '링크' },
  {
    id: SurveyTypeCase.개인정보수집,
    category: SurveyTypeCategory.개인정보수집동의,
    label: '개인정보수집',
  },
];

export const SURVEY_BADGES: {
  type: SurveyTypeCase;
  backgroundColor: string;
  textColor: string;
  icon: string;
}[] = [
  {
    type: SurveyTypeCase.객관식,
    backgroundColor: '#E3EBFF',
    textColor: '#4356FF',
    icon: 객관식Icon,
  },
  {
    type: SurveyTypeCase.주관식,
    backgroundColor: '#F6F6F6',
    textColor: '#848E9C',
    icon: 주관식Icon,
  },
  {
    type: SurveyTypeCase.이미지선택,
    backgroundColor: '#FBF8E0',
    textColor: '#F2B016',
    icon: 이미지Icon,
  },
  {
    type: SurveyTypeCase.동영상선택,
    backgroundColor: '#FFE3E4',
    textColor: '#EA3E60',
    icon: 동영상Icon,
  },
  {
    type: SurveyTypeCase.선호도평가,
    backgroundColor: '#E6E1FF',
    textColor: '#8340E2',
    icon: 선호도Icon,
  },
  {
    type: SurveyTypeCase.점수평가,
    backgroundColor: '#DEF2F6',
    textColor: '#6CC8DA',
    icon: 점수평가Icon,
  },
  {
    type: SurveyTypeCase.선형배율,
    backgroundColor: '#E5F7D6',
    textColor: '#57AD51',
    icon: 선형배율Icon,
  },
  {
    type: SurveyTypeCase.별점,
    backgroundColor: '#F6F9CD',
    textColor: '#AFB42B',
    icon: 별점Icon,
  },
  {
    type: SurveyTypeCase.드롭다운,
    backgroundColor: '#FCEBF1',
    textColor: '#C96D8E',
    icon: 드롭다운Icon,
  },
  {
    type: SurveyTypeCase.이메일주소,
    backgroundColor: '#F5F5F5',
    textColor: '#9E9E9E',
    icon: 이메일Icon,
  },
  {
    type: SurveyTypeCase.휴대폰번호,
    backgroundColor: '#F5F5F5',
    textColor: '#9E9E9E',
    icon: 휴대폰번호Icon,
  },
  {
    type: SurveyTypeCase.날짜,
    backgroundColor: '#F5F5F5',
    textColor: '#9E9E9E',
    icon: 날짜Icon,
  },
  {
    type: SurveyTypeCase.시간,
    backgroundColor: '#F5F5F5',
    textColor: '#9E9E9E',
    icon: 시간Icon,
  },
  {
    type: SurveyTypeCase.링크,
    backgroundColor: '#F5F5F5',
    textColor: '#9E9E9E',
    icon: 링크Icon,
  },
  {
    type: SurveyTypeCase.개인정보수집,
    backgroundColor: '#F5F5F5',
    textColor: '#9E9E9E',
    icon: 개인정보Icon,
  },
];

export const POLL_ACTION_BUTTONS: {
  [key in PollAction]: {
    text: string;
    variant: 'primary' | 'gray' | 'danger' | 'black';
    useDisabled?: boolean;
  };
} = {
  [PollAction.저장]: {
    text: '저장',
    variant: 'gray',
    useDisabled: true,
  },
  [PollAction.수정하기]: {
    text: '저장',
    variant: 'gray',
    useDisabled: true,
  },
  [PollAction.시작하기]: {
    text: '시작하기',
    variant: 'primary',
    useDisabled: true,
  },
  [PollAction.예약하기]: {
    text: '예약하기',
    variant: 'primary',
    useDisabled: true,
  },

  [PollAction.종료하기]: {
    text: '종료하기',
    variant: 'danger',
  },
  [PollAction.강제종료]: {
    text: '강제 종료',
    variant: 'danger',
  },
  [PollAction.예약취소]: {
    text: '예약취소',
    variant: 'danger',
  },
  [PollAction.일시중지]: {
    text: '일시 중지',
    variant: 'gray',
  },
  [PollAction.재시작하기]: {
    text: '재시작하기',
    variant: 'primary',
  },
  [PollAction.개표하기]: {
    text: '개표하기',
    variant: 'black',
  },
};

export const POLL_KIND_LABEL_V2 = {
  [PollKind.CandidateElection]: '선거',
  [PollKind.IssueElection]: '투표',
  [PollKind.Survey]: '설문',
};

export const POLL_KIND_SELECT_ITEMS_V2: { label: string; value: PollKindForSearchV2 }[] = [
  { label: '전체', value: PollKindForSearchV2.All },
  {
    label: POLL_KIND_LABEL_V2[PollKind.CandidateElection],
    value: PollKindForSearchV2.CandidateElection,
  },
  {
    label: POLL_KIND_LABEL_V2[PollKind.IssueElection],
    value: PollKindForSearchV2.IssueElection,
  },
  { label: POLL_KIND_LABEL_V2[PollKind.Survey], value: PollKindForSearchV2.Survey },
];

export const POLL_SEARCH_GROUP_V2: Record<
  ApprovedPollSearchGroup,
  { label: string; color?: string }
> = {
  [PollSearchGroupV2.All]: {
    label: '전체',
  },
  [PollSearchGroupV2.Ready]: {
    label: POLL_LABELS[PollSearchGroupV2.Ready],
    color: colors['yellow-400'],
  },
  [PollSearchGroupV2.Ongoing]: {
    label: POLL_LABELS[PollSearchGroupV2.Ongoing],
    color: colors['primary-500'],
  },
  [PollSearchGroupV2.Paused]: {
    label: POLL_LABELS[PollSearchGroupV2.Paused],
    color: colors['blue-gray-300'],
  },
  [PollSearchGroupV2.Ended]: {
    label: POLL_LABELS[PollSearchGroupV2.Ended],
    color: colors['blue-gray-700'],
  },
};

export const POLL_SEARCH_GROUP_SELECT_ITEMS_V2: {
  label: string;
  value: ApprovedPollSearchGroup;
}[] = Object.entries(POLL_SEARCH_GROUP_V2).map(([key, value]) => ({
  label: value.label,
  value: key as ApprovedPollSearchGroup,
}));

export const POLL_VOTER_AUTH_TYPE_V2 = {
  [VoterAuthMethodType.Everyone]: '공개',
  [VoterAuthMethodType.Code]: '참여 코드',
  [VoterAuthMethodType.VoterBook]: '구성원',
  [VoterAuthMethodType.OTP]: '인증',
};

export const GROUPS_ABLE_TO_PAUSE_OR_RESTART = [PollGroup.Ongoing, PollGroup.Paused];
export const GROUPS_ABLE_TO_EARLY_EXIT = [PollGroup.Ongoing];
export const GROUPS_ABLE_TO_REMOVE = [PollGroup.Ready, PollGroup.Ended, PollGroup.Counted];
