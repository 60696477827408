import Badge from 'components/common/Badge';
import ProPlanBadge from 'components/features/subscription-v2/badge/Pro';
import NoticeIcon from 'components/features/poll-settings-v2/icons/Notice';
import CollaboratorIcon from 'components/features/poll-settings-v2/icons/Collaborator';
import CommentIcon from 'components/features/poll-settings-v2/icons/Comment';
import RaffleIcon from 'components/features/poll-settings-v2/icons/Raffle';

import { button, container, iconStyle, menus, pendingBadge } from './index.css';

export enum MenuValue {
  Collaborator = 'collaborator',
  Notice = 'notice',
  Comment = 'comment',
  Raffle = 'raffle',
}

export type Menu = {
  label: string;
  value: MenuValue;
  needProPlan: boolean;
  notImplemented: boolean;
  Icon: ({ className }: { className: string }) => JSX.Element;
};

type Props = {
  activeMenu: MenuValue;
  onMenuClick: (value: MenuValue) => void;
};

function SettingsPanel({ activeMenu, onMenuClick }: Props) {
  return (
    <div className={container}>
      <div className={menus}>
        {MENUS.map((menu) => {
          const { Icon } = menu;
          const active = activeMenu === menu.value;

          return (
            <button
              type='button'
              key={menu.value}
              className={button({ active })}
              onClick={() => onMenuClick(menu.value)}
              disabled={menu.notImplemented}
            >
              <Icon className={iconStyle} />

              {menu.label}

              {menu.notImplemented && (
                <Badge color='indigo' className={pendingBadge}>
                  예정
                </Badge>
              )}
              {menu.needProPlan && <ProPlanBadge />}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default SettingsPanel;

const MENUS: Menu[] = [
  {
    label: '공동작업자 관리',
    value: MenuValue.Collaborator,
    needProPlan: true,
    notImplemented: false,
    Icon: CollaboratorIcon,
  },
  {
    label: '공지사항',
    value: MenuValue.Notice,
    needProPlan: false,
    notImplemented: false,
    Icon: NoticeIcon,
  },
  {
    label: '댓글',
    value: MenuValue.Comment,
    needProPlan: false,
    notImplemented: false,
    Icon: CommentIcon,
  },
  {
    label: '추첨',
    value: MenuValue.Raffle,
    needProPlan: false,
    notImplemented: true,
    Icon: RaffleIcon,
  },
] as const;
