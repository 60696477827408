import { RefObject, useCallback, useEffect } from 'react';

export const useInfiniteScroll = ({
  ref,
  onScrollFinished,
}: {
  ref: RefObject<any>;
  onScrollFinished: () => void;
}) => {
  const onIntersection: IntersectionObserverCallback = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting) {
        onScrollFinished();
      }
    },
    [onScrollFinished],
  );

  useEffect(() => {
    let observer: IntersectionObserver;

    if (ref.current) {
      const option = { threshold: 0 };

      observer = new IntersectionObserver(onIntersection, option);
      observer.observe(ref.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [onIntersection]);
};
