import { Ref, forwardRef } from 'react';
import { Dropdown, Text } from '@pickme/design-system';

import PollBadge from 'components/features/poll-v2/Badge';

import { ElectionTypeCase } from 'types/application-v2';

import CheckBoxIcon from 'resources/icons/poll-v2/checkbox.svg';
import GraphIcon from 'resources/icons/poll-v2/graph.svg';
import OXIcon from 'resources/icons/poll-v2/ox.svg';
import ScoreIcon from 'resources/icons/poll-v2/score.svg';

import { menuContainer, menuItemStyle, triggerContainer, triggerContents } from './index.css';

type Props = {
  type: ElectionTypeCase;
  disabled?: boolean;
  onSelect: (type: ElectionTypeCase) => void;
};

function TypeDropdown({ type, disabled, onSelect }: Props, ref: Ref<HTMLDivElement>) {
  const selectedItem = ITEMS.find((item) => item.type === type);

  return (
    <Dropdown ref={ref}>
      <Dropdown.Trigger arrowColor='blue-gray-700' className={triggerContainer} disabled={disabled}>
        {selectedItem ? (
          <div className={triggerContents}>
            <PollBadge type={selectedItem.type} />
            <Text size={14} fontWeight={400}>
              {selectedItem.title.full}
            </Text>
          </div>
        ) : (
          '선택해주세요'
        )}
      </Dropdown.Trigger>

      <Dropdown.Menu className={menuContainer} width={248}>
        {ITEMS.map((item) => (
          <Dropdown.Item
            key={item.title.full}
            className={menuItemStyle}
            onClick={() => onSelect(item.type)}
            allowPropagation
          >
            <PollBadge type={item.type} />

            <Text size={14} fontWeight={400}>
              {item.title.short}
            </Text>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const ElectionTypeDropdown = forwardRef(TypeDropdown);
export default ElectionTypeDropdown;

const ITEMS = [
  {
    type: ElectionTypeCase.선택투표,
    title: { short: '선택투표', full: '선택투표' },
    icon: CheckBoxIcon,
  },
  {
    type: ElectionTypeCase.찬반투표,
    title: { short: '찬반투표', full: '찬반투표' },
    icon: OXIcon,
  },
  {
    type: ElectionTypeCase.순위투표,
    title: { short: '순위투표', full: '순위투표' },
    icon: GraphIcon,
  },
  {
    type: ElectionTypeCase.점수투표,
    title: { short: '점수투표', full: '점수투표' },
    icon: ScoreIcon,
  },
];
