import { PrimaryBadge } from '@pickme/ui';
import { ManagerRoleTitleV2 } from '@pickme/core';

type Props = {
  role: ManagerRoleTitleV2;
};

const BADGE_VALUES_FOR_CHANNEL_SELECTOR: {
  [level in ManagerRoleTitleV2]: {
    color: string;
    text: string;
  };
} = {
  [ManagerRoleTitleV2.Admin]: {
    color: 'vivid-blue',
    text: '소유자',
  },
  [ManagerRoleTitleV2.Editor]: {
    color: 'vivid-purple',
    text: '매니저',
  },
  [ManagerRoleTitleV2.Viewer]: {
    color: 'vivid-purple',
    text: '매니저',
  },
};

function ManagerRoleBadgeForChannelSelector({ role }: Props) {
  return (
    <PrimaryBadge variant={BADGE_VALUES_FOR_CHANNEL_SELECTOR[role].color}>
      {BADGE_VALUES_FOR_CHANNEL_SELECTOR[role].text}
    </PrimaryBadge>
  );
}

export default ManagerRoleBadgeForChannelSelector;
