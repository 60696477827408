import * as yup from 'yup';

import {
  ENDING_DESCRIPTION_MAX_LENGTH,
  ENDING_LINK_TEXT_MAX_LENGTH,
} from 'constants/application-v2';

export const getEndingFormSchema = () =>
  yup.object().shape({
    cover: yup.object().shape({
      type: yup.string(),
      url: yup.string().url(),
    }),
    description: yup
      .string()
      .test(
        'description-length',
        `엔딩 메세지는 최대 ${ENDING_DESCRIPTION_MAX_LENGTH}자까지 입력 가능합니다`,
        (value) => {
          const parsedValue = value?.replace(/<(img|iframe)[^>]*>/g, '!')?.replace(/<[^>]*>/g, '');

          return !parsedValue || parsedValue.length <= ENDING_DESCRIPTION_MAX_LENGTH;
        },
      ),
    button: yup.object().shape({
      text: yup
        .string()
        .max(
          ENDING_LINK_TEXT_MAX_LENGTH,
          `링크 버튼 텍스트는 ${ENDING_LINK_TEXT_MAX_LENGTH}자 이하로 입력해 주세요`,
        ),
      link: yup.string().url(),
    }),
    share: yup.object().shape({
      enabled: yup.object().shape({
        kakao: yup.boolean(),
        instagram: yup.boolean(),
        facebook: yup.boolean(),
        line: yup.boolean(),
      }),
    }),
  });
