import clsx from 'clsx';
import { forwardRef, Ref, TextareaHTMLAttributes, useImperativeHandle, useRef } from 'react';
import { Text } from '@pickme/design-system';

import useAutoSizeTextArea from 'hooks/useAutoSizeTextArea';

import { textarea, PADDING_SIZE, container } from './index.css';

type CommonProps = {
  isValid?: boolean;
  errorMessage?: string;
  size?: 'sm' | 'md';
  containerClassName?: string;
  underlined?: boolean;
  isNewLineDisabled?: boolean;
};

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {}
type Props = TextAreaProps & CommonProps;

function Textarea(
  {
    isValid = true,
    errorMessage,
    size = 'md',
    containerClassName,
    underlined,
    isNewLineDisabled,
    ...props
  }: Props & CommonProps,
  ref: Ref<HTMLTextAreaElement>,
) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutoSizeTextArea(textAreaRef.current, PADDING_SIZE);
  useImperativeHandle(ref, () => textAreaRef.current as HTMLTextAreaElement);

  return (
    <div className={clsx(container, containerClassName)}>
      <textarea
        {...(props as TextAreaProps)}
        className={clsx(
          textarea({
            size,
            isValid,
            underlined,
          }),
          props.className,
        )}
        ref={textAreaRef}
        maxLength={props.maxLength}
        onKeyPress={(event) => {
          if (!isNewLineDisabled) {
            return;
          }

          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
      />

      {errorMessage && (
        <Text size={12} fontWeight={400} color='red-500'>
          {errorMessage}
        </Text>
      )}
    </div>
  );
}

const UnderlinedTextarea = forwardRef(Textarea);
export default UnderlinedTextarea;
