import { useEffect } from 'react';
import moment from 'moment-timezone';
import { UseFormReturn } from 'react-hook-form';

import { useGetPoll, useGetVoters } from 'query-hooks/poll';

import {
  AdditionalFormBody,
  BasicFormBody,
  QuestionsFormBody,
  SubElectionsFormBody,
  VoterAuthMethod,
  VoterFormBody,
} from 'types/application';

export function useInitializeElectionEditForm(
  pollId: string,
  forms: {
    basicForm: UseFormReturn<BasicFormBody>;
    subElectionsForm: UseFormReturn<SubElectionsFormBody>;
    votersForm: UseFormReturn<VoterFormBody>;
    additionalForm: UseFormReturn<AdditionalFormBody>;
  },
) {
  const { data: poll, isFetched: isPollFetched } = useGetPoll(pollId);
  const { data: voters, isFetched: isVoterFetched } = useGetVoters(pollId);

  const { basicForm, subElectionsForm, votersForm, additionalForm } = forms;

  useEffect(() => {
    if (isPollFetched && poll) {
      basicForm.reset({
        subscriptionType: poll?.subscriptionType,
        name: poll?.name,
        description: poll?.description,
        periods:
          poll?.periods?.map(({ startDate, endDate }) => ({
            startDate: moment(startDate).tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm'),
            endDate: moment(endDate).tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm'),
          })) ?? [],
        coverImageFileUrl: poll.coverImageFileUrl ?? '',
      });

      subElectionsForm.reset({ subElections: poll?.subElections || [] });
      additionalForm.reset({
        isOpenPollRate: poll?.isOpenPollRate,
        isOpenPollResult: poll?.isOpenPollResult ?? false,
        openThreshold: poll?.openThreshold ?? 0,
        voterAuthMethods: poll?.voterAuthMethods ?? [VoterAuthMethod.Email, VoterAuthMethod.Phone],
      });
    }
  }, [isPollFetched, poll]);

  useEffect(() => {
    if (isVoterFetched && voters) {
      votersForm.setValue('voterBook', voters || []);
    }
  }, [isVoterFetched, voters]);
}

export function useInitializeSurveyEditForm(
  pollId: string,
  forms: {
    basicForm: UseFormReturn<BasicFormBody>;
    questionsForm: UseFormReturn<QuestionsFormBody>;
    votersForm: UseFormReturn<VoterFormBody>;
    additionalForm: UseFormReturn<AdditionalFormBody>;
  },
) {
  const { data: poll, isFetched: isPollFetched } = useGetPoll(pollId);
  const { data: voters, isFetched: isVoterFetched } = useGetVoters(pollId);

  const { basicForm, questionsForm, votersForm, additionalForm } = forms;

  useEffect(() => {
    if (isPollFetched && poll) {
      basicForm.reset({
        subscriptionType: poll?.subscriptionType,
        name: poll?.name,
        description: poll?.description,
        periods:
          poll?.periods?.map(({ startDate, endDate }) => ({
            startDate: moment(startDate).tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm'),
            endDate: moment(endDate).tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm'),
          })) ?? [],
        coverImageFileUrl: poll.coverImageFileUrl ?? '',
      });

      questionsForm.reset({ questions: poll?.questions || [] });
      additionalForm.reset({
        allowRealTimeResult: poll?.allowRealTimeResult,
        isOpenPollRate: poll?.isOpenPollRate,
        isOpenPollResult: poll?.isOpenPollResult ?? false,
        openThreshold: poll?.openThreshold ?? 0,
        voterAuthMethods: poll?.voterAuthMethods ?? [VoterAuthMethod.Email, VoterAuthMethod.Phone],
        hasOpenVotes: poll?.hasOpenVotes ?? false,
      });

      votersForm.setValue('hasInstantVoter', poll?.hasInstantVoter);
    }
  }, [isPollFetched, poll]);

  useEffect(() => {
    if (!isVoterFetched) {
      return;
    }

    if (voters) {
      votersForm.setValue('voterBook', voters);
    } else {
      votersForm.setValue('voterBook', []);
    }
  }, [isVoterFetched, voters]);
}
