import { Button, Text } from '@pickme/design-system';
import { t } from 'i18next';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useSetRecoilState } from 'recoil';

import ImageCloseButton from 'components/features/application-v2/button/ImageCloseButton';

import { useUploadFile } from 'query-hooks/file';

import { toast } from 'states/toast';

import ImageIcon from 'resources/icons/application/image.svg';

import { container, outerContainer, coverImage, card, icon, removeButton } from './index.css';

type Props = {
  imageUrl: string;
  setImageUrl: (imageUrl: string) => void;
};

function FileUpload({ imageUrl, setImageUrl }: Props) {
  const setToast = useSetRecoilState(toast);
  const { mutate: fileUploadMutate, isLoading: isFileUploadLoading } = useUploadFile();

  const onDrop = (files: File[]) => {
    const file = files[0];
    const isAllowedMimeType =
      file?.name?.toLowerCase().endsWith('.jpg') ||
      file?.name?.toLowerCase().endsWith('.jpeg') ||
      file?.name?.toLowerCase().endsWith('.png');

    if (!file || !isAllowedMimeType) {
      setToast({
        isVisible: true,
        type: 'error',
        message: "'jpg', 'jpeg', 'png' 이미지만 등록할 수 있어요.",
      });
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setToast({
        isVisible: true,
        type: 'error',
        message: '이미지 용량이 5MB를 초과했어요.',
      });
      return;
    }

    fileUploadMutate(file, {
      onSuccess: setImageUrl,
    });
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const isDisabledMIMEType = fileRejections?.[0]?.errors?.some(
      (error) => error.code === 'file-invalid-type',
    );
    const isOverSizeLimit = fileRejections?.[0]?.errors?.some(
      (error) => error.code === 'file-too-large',
    );
    if (isDisabledMIMEType) {
      setToast({
        isVisible: true,
        type: 'error',
        message: "'jpg', 'jpeg', 'png' 이미지만 등록할 수 있어요.",
      });
      return;
    }

    if (isOverSizeLimit) {
      setToast({
        isVisible: true,
        type: 'error',
        message: '이미지 용량이 5MB를 초과했어요.',
      });
      return;
    }

    setToast({
      isVisible: true,
      type: 'error',
      message: t('admin:components.features.application.fields.cover.errorMessages.common'),
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024,
    multiple: false,
    minSize: 50,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
  });

  return (
    <div className={outerContainer}>
      <div
        className={`${container({ isActive: isDragActive || isFileUploadLoading })}`}
        {...getRootProps()}
      >
        <input
          {...getInputProps({
            type: 'file',
            hidden: true,
            disabled: isFileUploadLoading,
            accept: 'image/jpeg, image/jpg, image/png',
          })}
        />

        {imageUrl ? (
          <img
            className={`${coverImage({ isActive: isDragActive || isFileUploadLoading })}`}
            src={imageUrl}
            alt='cover'
          />
        ) : (
          <div className={card}>
            <img className={icon} src={ImageIcon} alt='upload' />

            <Text size={14} fontWeight={400} color='gray-700'>
              이미지 파일을 드래그해주세요.
            </Text>

            <Button variant='gray' size='sm'>
              이미지 파일 선택
            </Button>
          </div>
        )}

        {imageUrl && (
          <ImageCloseButton
            className={removeButton}
            onClick={(event) => {
              event.stopPropagation();
              setImageUrl('');
            }}
          />
        )}
      </div>

      <Text size={12} fontWeight={400} color='gray-400'>
        {t('admin:components.features.application.fields.cover.description')}
      </Text>
    </div>
  );
}

export default FileUpload;
