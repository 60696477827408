/* eslint-disable react/jsx-no-useless-fragment */
import { PropsWithChildren, useRef } from 'react';

import { useInfiniteScroll } from 'hooks/useInfiniteScroll';

import { trigger } from './index.css';

type Props = PropsWithChildren<{
  onScrollFinished: () => void;
}>;

function InfiniteScrollHandler({ children, onScrollFinished }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useInfiniteScroll({ ref, onScrollFinished });

  return (
    <>
      {children}
      <div className={trigger} ref={ref} />
    </>
  );
}

export default InfiniteScrollHandler;
