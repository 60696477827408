import { CollaboratorRoleV2, ManagerRoleTitleV2, PlanTitleV2 } from '@pickme/core';

export enum FeatureTitleV2 {
  구독및결제 = '구독및결제',
  메시지 = '메시지',
  매니저 = '매니저',
  참여자인증 = '참여자인증',
  연동 = '연동',
  공동작업자 = '공동작업자',
  만들기 = '만들기',
  공지사항 = '공지사항',
  댓글 = '댓글',
}

export enum SubscriptionAction {
  구독페이지진입 = '구독페이지진입',
  구독정보변경 = '구독정보변경',
  결제페이지진입 = '결제페이지진입',
}

export enum MessagingAction {
  보내기페이지진입 = '보내기페이지진입',
  포인트충전 = '포인트충전',
  채널등록 = '채널등록',
  채널조회 = '채널조회',
  메시지보내기 = '메시지보내기',
}

export enum ManagerAction {
  페이지진입 = '페이지진입',
  매니저수정 = '매니저수정',
  매니저추가결제 = '매니저추가결제',
  매니저초대 = '매니저초대',
}

export enum VoterSeatAction {
  사용내역조회페이지진입 = '사용내역조회페이지진입',
  충전내역조회페이지진입 = '충전내역조회페이지진입',
  참여자인증충전 = '참여자인증충전',
}

export enum IntegrationAction {
  페이지진입 = '페이지진입',
  카카오연동 = '카카오연동',
}

export enum CollaboratorAction {
  페이지진입 = '페이지진입',
  편집 = '편집',
}

export enum ApplicationAction {
  페이지진입 = '페이지진입',
  엔딩 = '엔딩',
  명부 = '명부',
  OTP = '인증한유저만',
  만들기 = '만들기',
  수정하기 = '수정하기',
  삭제하기 = '삭제하기',
  비공개설정 = '비공개설정',
  중복응답방지 = '중복응답방지',
}

export enum NoticeAction {
  페이지진입 = '페이지진입',
  편집 = '편집',
}

export enum CommentAction {
  페이지진입 = '페이지진입',
  편집 = '편집',
}

export type FeatureAction = {
  [FeatureTitleV2.구독및결제]: SubscriptionAction;
  [FeatureTitleV2.메시지]: MessagingAction;
  [FeatureTitleV2.매니저]: ManagerAction;
  [FeatureTitleV2.참여자인증]: VoterSeatAction;
  [FeatureTitleV2.연동]: IntegrationAction;
  [FeatureTitleV2.공동작업자]: CollaboratorAction;
  [FeatureTitleV2.만들기]: ApplicationAction;
  [FeatureTitleV2.공지사항]: NoticeAction;
  [FeatureTitleV2.댓글]: CommentAction;
};

export type RoleToActionMap = {
  [K in FeatureTitleV2]: Record<ManagerRoleTitleV2, FeatureAction[K][]>;
};

export type PlanToFeatureMap = {
  [K in FeatureTitleV2]: Record<PlanTitleV2, FeatureAction[K][]>;
};

export type CollaboratorRoleToActionMap = {
  [K in FeatureTitleV2]: Record<CollaboratorRoleV2, FeatureAction[K][]>;
};

export enum DisabledWith {
  Plan = 'plan',
  ChannelRole = 'channelRole',
  CollaboratorRole = 'collaboratorRole',
}
