import { ButtonHTMLAttributes, forwardRef } from 'react';
import { Text } from '@pickme/design-system';
import { Controller, useFormContext } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';

import ProPlanBadge from 'components/features/subscription-v2/badge/Pro';

import { usePollOutletContext } from 'hooks/poll-v2/usePollOutletContext';
import { useGetFeatureAccess } from 'hooks/useGetFeatures-v2';

import { toast } from 'states/toast';

import { ApplicationAction, FeatureTitleV2 } from 'types/features-v2';
import { SettingsForm } from 'types/application-v2';

import VisibleIcon from 'resources/icons/application-v2/visibility.svg';
import InvisibleIcon from 'resources/icons/application-v2/invisibility.svg';

import { badgeContainer, button, buttonGroup, buttonTitleContainer, container } from './index.css';

function PublicField() {
  const setToast = useSetRecoilState(toast);

  const methods = useFormContext<SettingsForm>();
  const { checkFieldEditable } = usePollOutletContext();

  const { enabled: isPrivateEnabled } = useGetFeatureAccess({
    feature: FeatureTitleV2.만들기,
    actionName: ApplicationAction.비공개설정,
  });

  return (
    <div className={container}>
      <div className={buttonGroup}>
        <Controller
          control={methods.control}
          name='isPublic'
          render={({ field }) => (
            <ForwardedPublicButton
              ref={field.ref}
              disabled={!checkFieldEditable('after')}
              onClick={() => field.onChange(true)}
              isSelected={field.value}
            >
              <div className={buttonTitleContainer}>
                <img src={VisibleIcon} alt='채널에 공개' />
                <Text size={16} fontWeight={600} color='gray-800'>
                  채널에 공개
                </Text>
              </div>

              <Text size={14} fontWeight={400} color='gray-700'>
                {LABELS[0]}
              </Text>
            </ForwardedPublicButton>
          )}
        />

        <Controller
          control={methods.control}
          name='isPublic'
          render={({ field }) => (
            <ForwardedPublicButton
              ref={field.ref}
              disabled={!checkFieldEditable('after')}
              onClick={() => {
                if (!isPrivateEnabled) {
                  setToast({
                    isVisible: true,
                    type: 'error',
                    message: '프로 플랜을 구독해 주세요',
                  });
                  return;
                }

                field.onChange(false);
              }}
              isSelected={!field.value}
            >
              <div className={buttonTitleContainer}>
                <img src={InvisibleIcon} alt='채널에 비공개' />
                <Text size={16} fontWeight={600} color='gray-800'>
                  채널에 비공개
                </Text>

                <ProPlanBadge className={badgeContainer} />
              </div>

              <Text size={14} fontWeight={400} color='gray-700'>
                {LABELS[1]}
              </Text>
            </ForwardedPublicButton>
          )}
        />
      </div>
    </div>
  );
}

function PublicButton(
  props: ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    isSelected: boolean;
  },
  ref: React.Ref<HTMLButtonElement>,
) {
  const { children, isSelected, ...rest } = props;
  return (
    <button
      className={button({
        isSelected,
        disabled: rest.disabled,
      })}
      type='button'
      ref={ref}
      {...rest}
    >
      {children}
    </button>
  );
}

const ForwardedPublicButton = forwardRef(PublicButton);

export default PublicField;

const LABELS = [
  '콘텐츠가 예약 혹은 시작되면 채널에 공개됩니다.',
  '생성된 콘텐츠가 채널에 공개 되지 않습니다.',
];
