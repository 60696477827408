import clsx from 'clsx';
import { PlainButton } from '@pickme/design-system';

import { container, rightButton, tabStyle } from './index.css';

type Tab = {
  text: string;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
};

type Props = {
  selectedIndex: number;
  tabs: Tab[];
  button?: {
    icon: string;
    onClick: () => void;
  };
  unsetBottomBorder?: boolean;
  fullWidth?: boolean;
  className?: string;
  variant?: 'primary' | 'black';
};

function Tabs({
  selectedIndex,
  tabs,
  button,
  unsetBottomBorder = false,
  fullWidth = true,
  className,
  variant = 'primary',
}: Props) {
  return (
    <div className={clsx(container({ unsetBottomBorder, fullWidth, variant }), className)}>
      <div className={tabStyle.container}>
        {tabs.map((tab, index) => {
          const selected = selectedIndex === index;

          return (
            <button
              key={tab.text}
              className={clsx(tabStyle.item({ selected }), tab.className)}
              onClick={tab.onClick}
              disabled={tab.disabled}
              type='button'
            >
              {tab.text}
            </button>
          );
        })}
      </div>

      {!!button && (
        <PlainButton
          className={rightButton.container}
          type='button'
          onClick={() => button.onClick()}
        >
          <img className={rightButton.image} src={button.icon} alt='button' />
        </PlainButton>
      )}
    </div>
  );
}

export default Tabs;
