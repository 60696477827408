import { createHttpRequest } from 'apis/common';

import { UnsplashImageResponse } from 'types/image';
import { HostType } from 'types/api';

export const UNSPLASH_APIS = {
  getFeaturedImages: { method: 'GET', url: '/photos', hostType: HostType.Unsplash },
  searchImages: { method: 'GET', url: '/search/photos', hostType: HostType.Unsplash },
  getTopicImages: { method: 'GET', url: '/topics/:id/photos', hostType: HostType.Unsplash },
  imageDownload: { method: 'GET', url: '/photos/:id/download', hostType: HostType.Unsplash },
};

export const createGetFeaturedImagesRequest = ({ page }: { page: number }) =>
  createHttpRequest<UnsplashImageResponse[]>({
    ...UNSPLASH_APIS.getFeaturedImages,
    withoutOrganizationOid: true,
    delay: page === 1 ? 0 : 500,
    query: {
      page,
      per_page: 20,
      order_by: 'popular',
    },
  });

export const createGetSearchImagesRequest = ({
  page,
  keyword,
}: {
  page: number;
  keyword: string;
}) =>
  createHttpRequest<{ total: number; total_pages: number; results: UnsplashImageResponse[] }>({
    ...UNSPLASH_APIS.searchImages,
    withoutOrganizationOid: true,
    delay: page === 1 ? 0 : 500,
    query: {
      query: keyword,
      page,
      per_page: 20,
      order_by: 'popular',
    },
  });

export const createGetTopicImagesRequest = ({ id, page }: { id: string; page: number }) =>
  createHttpRequest<UnsplashImageResponse[]>({
    ...UNSPLASH_APIS.getTopicImages,
    withoutOrganizationOid: true,
    params: {
      id,
    },
    delay: page === 1 ? 0 : 500,
    query: {
      page,
      per_page: 20,
      order_by: 'popular',
    },
  });

// NOTE: 실제 다운로드가 필요하지는 않지만,
// unsplash api 규정 상, 이미지 선택 시, 다운로드 api를 호출해야합니다.
export const createGetImageDownloadRequest = ({ id }: { id: string }) =>
  createHttpRequest<UnsplashImageResponse[]>({
    ...UNSPLASH_APIS.imageDownload,
    withoutOrganizationOid: true,
    params: {
      id,
    },
  });
