import { Text } from '@pickme/design-system';
import { PlainButton } from '@pickme/ui';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Dropdown from 'components/features/layout/Dropdown';
import { useFeedbackModal } from 'components/features/modal/FeedbackModal/useFeedbackModal';

import { useGetProfile, useSignOut } from 'query-hooks/auth-v2';

import { useActiveChannel } from 'hooks/useActiveChannel';

import ProfileIcon from 'resources/icons/nav/default-profile.svg';

import { button, outerContainer, container, item, profileIcon } from './index.css';

type Props = {
  disabledToShowFeedback?: boolean;
};

function UserMenu({ disabledToShowFeedback }: Props) {
  const [isDropdownOpened, setDropdownOpen] = useState(false);

  const { data: profile } = useGetProfile();
  const { mutate: signOut } = useSignOut();
  const { activeChannel } = useActiveChannel();

  const { setFeedBackModal } = useFeedbackModal();

  return (
    <div className={outerContainer}>
      <Dropdown
        position='bottom'
        isOpen={isDropdownOpened}
        onOpenDropdown={() => setDropdownOpen(true)}
        onCloseDropdown={() => setDropdownOpen(false)}
        trigger={
          <div className={button}>
            <img
              src={profile?.profileImageFileUrl || ProfileIcon}
              alt={profile?.name}
              className={profileIcon}
            />

            <Text>{profile?.name}</Text>
          </div>
        }
      >
        <div className={container}>
          <NavLink
            type='button'
            className={item({ type: 'button' })}
            to={`${import.meta.env.VITE_AUTH_URL}/account`}
            unstable_viewTransition
          >
            <Text size={14} fontWeight={400}>
              마이페이지
            </Text>
          </NavLink>

          <NavLink
            type='button'
            className={item({ type: 'button' })}
            to={`${import.meta.env.VITE_MAIN_URL}/channel`}
            onClick={() => setDropdownOpen(false)}
            unstable_viewTransition
          >
            <Text size={14} fontWeight={400}>
              치즈버튼 커뮤니티
            </Text>
          </NavLink>

          {!disabledToShowFeedback && (
            <PlainButton
              className={item({ type: 'button' })}
              onClick={() => {
                if (!profile || !activeChannel) {
                  return;
                }

                setFeedBackModal({
                  isOpened: true,
                  profile,
                  channelName: activeChannel.name,
                });
              }}
            >
              <Text size={14} fontWeight={400}>
                피드백 보내기
              </Text>
            </PlainButton>
          )}

          <PlainButton
            type='button'
            className={item({ type: 'button' })}
            onClick={() => {
              signOut({ redirectToIntroduction: true });
            }}
          >
            <Text size={14} fontWeight={400} color='red-500'>
              로그아웃
            </Text>
          </PlainButton>
        </div>
      </Dropdown>
    </div>
  );
}

export default UserMenu;
