import { atom } from 'recoil';

import { EMPTY_STRING } from 'constants/common';

export const pointPaymentCancelModal = atom<{
  isVisible: boolean;
  chargeId: string;
  orderId: string;
}>({
  key: 'point-payment-cancel-modal',
  default: { isVisible: false, chargeId: EMPTY_STRING, orderId: EMPTY_STRING },
});

export const pointIncomingDetailModal = atom<{
  isVisible: boolean;
  chargeId: string;
  orderId: string;
}>({
  key: 'point-incoming-detail-modal',
  default: { isVisible: false, chargeId: EMPTY_STRING, orderId: EMPTY_STRING },
});
