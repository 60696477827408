import { PollKind } from '@pickme/core';

import { Template, TemplateCategory } from 'types/application-v2';

import 대학교총학생회장 from './candidate-election/대학교총학생회장.json';
import 학급반장 from './candidate-election/학급반장.json';
import 학부모회임원 from './candidate-election/학부모회임원.json';
import 대학교총동문회장 from './candidate-election/대학교총동문회장.json';
import 협회학회임원 from './candidate-election/협회학회임원.json';
import 협회학회이사장 from './candidate-election/협회학회이사장.json';
import 소모임동호회임원 from './candidate-election/소모임동호회임원.json';
import 노동조합위원장 from './candidate-election/노동조합위원장.json';
import 공동주택입주자대표 from './candidate-election/공동주택입주자대표.json';

import 단체대표로고 from './issue-election/단체대표로고.json';
import 단체대표캐릭터 from './issue-election/단체대표캐릭터.json';
import 대학교홍보슬로건 from './issue-election/대학교홍보슬로건.json';
import 임직원추석선물 from './issue-election/임직원추석선물.json';
import 숏폼공모전 from './issue-election/숏폼공모전.json';
import 노래경연대회 from './issue-election/노래경연대회.json';
import 노동조합쟁의행위 from './issue-election/노동조합쟁의행위.json';
import 대학교통합 from './issue-election/대학교통합.json';
import 올해의KPOP아이돌 from './issue-election/올해의KPOP아이돌.json';
import 여름휴가국내여행지 from './issue-election/여름휴가국내여행지.json';

import 굿즈구매수요조사 from './survey/굿즈구매수요조사.json';
import 굿즈구매신청접수 from './survey/굿즈구매신청접수.json';
import 단체옷공동구매수요조사 from './survey/단체옷공동구매수요조사.json';
import 단체옷공동구매신청접수 from './survey/단체옷공동구매신청접수.json';
import 단체옷불량제품환불접수 from './survey/단체옷불량제품환불접수.json';
import 직장내성희롱및성폭력신고접수 from './survey/직장내성희롱및성폭력신고접수.json';
import 부패및갑질신고접수 from './survey/부패및갑질신고접수.json';
import 교육참가신청접수 from './survey/교육참가신청접수.json';
import 버스대절신청접수 from './survey/버스대절신청접수.json';
import 버스대절수요조사 from './survey/버스대절수요조사.json';
import 대회참가신청접수 from './survey/대회참가신청접수.json';
import 이름짓기공모전접수 from './survey/이름짓기공모전접수.json';
import 노동조합워크숍만족도조사 from './survey/노동조합워크숍만족도조사.json';
import 화장품패키지디자인선호도조사 from './survey/화장품패키지디자인선호도조사.json';

export const TEMPLATES_V2: Template[] = [
  {
    id: '1',
    title: '대학교 총학생회장 선거',
    description: '대학교 총학생회장 선거(팀 후보) 템플릿입니다.',
    kind: PollKind.CandidateElection,
    categories: [TemplateCategory.학교],
    poll: 대학교총학생회장,
  },
  {
    id: '2',
    title: '학급 반장 선거',
    description: '초∙중∙고 반장 선거(개인 후보) 템플릿입니다.',
    kind: PollKind.CandidateElection,
    categories: [TemplateCategory.학교],
    poll: 학급반장,
  },
  {
    id: '3',
    title: '학부모회 임원 선거',
    description: '학부모회 임원 선거 템플릿입니다.',
    kind: PollKind.CandidateElection,
    categories: [TemplateCategory.전체],
    poll: 학부모회임원,
  },
  {
    id: '4',
    title: '대학교 총동문회장 선거',
    description: '총동문회 임원 선거 템플릿입니다.',
    kind: PollKind.CandidateElection,
    categories: [TemplateCategory.전체],
    poll: 대학교총동문회장,
  },
  {
    id: '5',
    title: '협회∙학회 임원 선거',
    description: '학회 및 협회 임원 선거 템플릿입니다.',
    kind: PollKind.CandidateElection,
    categories: [TemplateCategory.전체],
    poll: 협회학회임원,
  },
  {
    id: '6',
    title: '협회∙학회 이사장 선거',
    description: '학회 및 협회 임원 선거 템플릿입니다.',
    kind: PollKind.CandidateElection,
    categories: [TemplateCategory.전체],
    poll: 협회학회이사장,
  },
  {
    id: '7',
    title: '소모임∙동호회 임원 선거',
    description: '동호회 및 소모임 임원 선거 템플릿입니다.',
    kind: PollKind.CandidateElection,
    categories: [TemplateCategory.전체],
    poll: 소모임동호회임원,
  },
  {
    id: '8',
    title: '노동조합 위원장 및 신규 위원 선거',
    description: '노동조합 위원장 및 위원 선거 템플릿입니다.',
    kind: PollKind.CandidateElection,
    categories: [TemplateCategory.전체],
    poll: 노동조합위원장,
  },
  {
    id: '9',
    title: '공동주택 입주자 대표 선거',
    description: '공동주택(아파트/오피스텔) 입주자 대표 선거 템플릿입니다.',
    kind: PollKind.CandidateElection,
    categories: [TemplateCategory.전체],
    poll: 공동주택입주자대표,
  },
  {
    id: '10',
    title: '단체 대표 로고 선정 투표',
    description: '단체 대표 로고 선정 선택 투표 템플릿입니다.',
    kind: PollKind.IssueElection,
    categories: [TemplateCategory.전체],
    poll: 단체대표로고,
  },
  {
    id: '11',
    title: '단체 대표 캐릭터 디자인 투표',
    description: '단체 대표 캐릭터 디자인 선택 투표 템플릿입니다.',
    kind: PollKind.IssueElection,
    categories: [TemplateCategory.전체],
    poll: 단체대표캐릭터,
  },
  {
    id: '12',
    title: '대학교 홍보 슬로건 투표',
    description: '대학교 홍보 슬로건 선택 투표 템플릿입니다.',
    kind: PollKind.IssueElection,
    categories: [TemplateCategory.전체],
    poll: 대학교홍보슬로건,
  },
  {
    id: '13',
    title: '임직원 추석 선물 세트 투표',
    description: '명절 선물 세트 선택 투표 템플릿입니다.',
    kind: PollKind.IssueElection,
    categories: [TemplateCategory.전체],
    poll: 임직원추석선물,
  },
  {
    id: '14',
    title: '숏폼 공모전 점수 투표',
    description: '공모전 점수 심사를 위한 점수 투표 템플릿입니다.',
    kind: PollKind.IssueElection,
    categories: [TemplateCategory.전체],
    poll: 숏폼공모전,
  },
  {
    id: '15',
    title: '노래 경연 대회 점수 투표',
    description: '노래 경연 심사를 위한 점수 투표 템플릿입니다.',
    kind: PollKind.IssueElection,
    categories: [TemplateCategory.전체],
    poll: 노래경연대회,
  },
  {
    id: '16',
    title: '대학교 통합 찬반 투표',
    description: '대학교 통합 찬반 투표 템플릿입니다.',
    kind: PollKind.IssueElection,
    categories: [TemplateCategory.학교],
    poll: 대학교통합,
  },
  {
    id: '17',
    title: '노동조합 쟁의행위 찬반 투표',
    description: '노동조합 쟁의행위 찬반 투표 템플릿입니다.',
    kind: PollKind.IssueElection,
    categories: [TemplateCategory.노조],
    poll: 노동조합쟁의행위,
  },
  {
    id: '18',
    title: '올해의 K-POP 아이돌 인기 순위 투표',
    description: 'K-POP 아이돌 인기 순위 투표 템플릿입니다.',
    kind: PollKind.IssueElection,
    categories: [TemplateCategory.전체],
    poll: 올해의KPOP아이돌,
  },
  {
    id: '19',
    title: '여름 휴가 국내 여행지 인기 순위 투표',
    description: '여름 휴가 국내 여행지 인기 순위 투표 템플릿입니다.',
    kind: PollKind.IssueElection,
    categories: [TemplateCategory.전체],
    poll: 여름휴가국내여행지,
  },
  {
    id: '21',
    title: '굿즈 구매 신청 접수',
    description: '굿즈 구매 신청 접수 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 굿즈구매신청접수,
  },
  {
    id: '22',
    title: '단체옷 공동구매 수요 조사',
    description: '단체옷 공동구매 수요 조사 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 단체옷공동구매수요조사,
  },
  {
    id: '23',
    title: '단체옷 공동구매 신청 접수',
    description: '단체옷 공동구매 신청 접수 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 단체옷공동구매신청접수,
  },
  {
    id: '24',
    title: '단체옷 불량제품 환불 접수',
    description: '단체옷 불량제품 환불 접수 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 단체옷불량제품환불접수,
  },
  {
    id: '25',
    title: '직장 내 성희롱 및 성폭력 신고 접수',
    description: '직장 내 성희롱 및 성폭력 신고 접수 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 직장내성희롱및성폭력신고접수,
  },
  {
    id: '26',
    title: '부패 및 갑질 신고 접수',
    description: '부패 및 갑질 신고 접수 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 부패및갑질신고접수,
  },
  {
    id: '27',
    title: '교육 참가 신청 접수',
    description: '교육 참가 신청 접수 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 교육참가신청접수,
  },
  {
    id: '28',
    title: '버스 대절 신청 접수',
    description: '버스 대절 신청 접수 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 버스대절신청접수,
  },
  {
    id: '29',
    title: '버스 대절 수요 조사',
    description: '버스 대절 수요 조사 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 버스대절수요조사,
  },
  {
    id: '30',
    title: '대회 참가 신청 접수',
    description: '대회 참가 신청 접수 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 대회참가신청접수,
  },
  {
    id: '31',
    title: '이름 짓기 공모전 접수',
    description: '이름 짓기 공모전 접수 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 이름짓기공모전접수,
  },
  {
    id: '32',
    title: '노동 조합 노사상생 워크숍 만족도 조사',
    description: '노동 조합 노사상생 워크숍 만족도 조사 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.노조],
    poll: 노동조합워크숍만족도조사,
  },
  {
    id: '33',
    title: '화장품 신규 패키지 디자인 선호도 조사',
    description: '화장품 신규 패키지 디자인 선호도 조사 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 화장품패키지디자인선호도조사,
  },
  {
    id: '34',
    title: '굿즈 구매 수요 조사',
    description: '굿즈 구매 수요 조사 템플릿입니다.',
    kind: PollKind.Survey,
    categories: [TemplateCategory.전체],
    poll: 굿즈구매수요조사,
  },
];
