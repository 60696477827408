type Props = {
  color: string;
  className?: string;
};

function CloseIcon({ className, color }: Props) {
  return (
    <svg
      className={className}
      width='26'
      height='24'
      viewBox='0 0 26 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.04 7L8.04004 17M8.04004 7L18.04 17'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default CloseIcon;
