import { t } from 'i18next';

export const routesAfterSpaceSet = [
  { path: '/', main: t('admin:constants.nav.main.dashboard.title') },
  {
    path: '/service/notification/send',
    main: t('admin:constants.nav.main.notification.title'),
    sub: '메시지 보내기',
  },
  {
    path: '/service/notification/send/alimTalk',
    main: t('admin:constants.nav.main.notification.title'),
    sub: '메시지 보내기',
  },
  {
    path: '/service/notification/send/email',
    main: t('admin:constants.nav.main.notification.title'),
    sub: '메시지 보내기',
  },
  {
    path: '/service/notification/send/lms',
    main: t('admin:constants.nav.main.notification.title'),
    sub: '메시지 보내기',
  },
  {
    path: '/service/notification/history/sent',
    main: t('admin:constants.nav.main.notification.title'),
    sub: '전송 내역',
  },
  {
    path: '/service/notification/history/reserved',
    main: t('admin:constants.nav.main.notification.title'),
    sub: '전송 내역',
  },
  {
    path: '/settings/channel',
    main: '채널 설정',
    sub: '일반 설정',
  },
  {
    path: '/settings/subscription',
    main: '채널 설정',
    sub: '구독 및 결제',
  },
  {
    path: '/settings/subscription/purchase',
    main: '채널 설정',
    sub: '구독 및 결제',
  },
  {
    path: '/settings/subscription/history',
    main: '채널 설정',
    sub: '구독 및 결제',
  },
  {
    path: '/settings/managers',
    main: '채널 설정',
    sub: '매니저 관리',
  },
  {
    path: '/settings/voter-seats/history/purchase',
    main: '채널 설정',
    sub: '참여자 인증',
  },
  {
    path: '/settings/voter-seats/history/usage',
    main: '채널 설정',
    sub: '참여자 인증',
  },
  {
    path: '/settings/expiration',
    main: '채널 설정',
    sub: '개인정보 자동 파기',
  },
  {
    path: '/settings/integration',
    main: '채널 설정',
    sub: '연동',
  },
  {
    path: '/settings/integration/kakao/channel',
    main: '채널 설정',
    sub: '연동',
  },
  {
    path: '/service/notification/point/incoming',
    main: t('admin:constants.nav.main.notification.title'),
    sub: t('admin:constants.nav.main.notification.sub.point'),
  },
  {
    path: '/service/notification/point/usage',
    main: t('admin:constants.nav.main.notification.title'),
    sub: t('admin:constants.nav.main.notification.sub.point'),
  },
  {
    path: '/feedback',
    main: t('admin:constants.nav.system.feedback.title'),
  },
];

export const routesBeforeSpaceSet = [
  '/join',
  '/join/:alias/:role',
  'accept',
  'channels',
  'channels/new',
];
