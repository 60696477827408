import { Ref, forwardRef } from 'react';
import { Dropdown, Text } from '@pickme/design-system';

import PollBadge from 'components/features/poll-v2/Badge';

import { SURVEY_CATEGORY_LABELS, SURVEY_TYPES } from 'constants/poll-v2';

import { SurveyTypeCase, SurveyTypeCategory } from 'types/application-v2';

import {
  divider,
  menuContainer,
  menuItemStyle,
  menuTitleContainer,
  triggerContainer,
  triggerContents,
} from './index.css';

type Props = {
  type: SurveyTypeCase;
  isPrivacyQuestionDisabled: boolean;
  disabled?: boolean;
  onSelect: (type: SurveyTypeCase) => void;
};

function TypeDropdown(
  { type, isPrivacyQuestionDisabled, disabled, onSelect }: Props,
  ref: Ref<HTMLDivElement>,
) {
  const selectedItem = SURVEY_TYPES.find((item) => item.id === type);

  return (
    <Dropdown ref={ref}>
      <Dropdown.Trigger arrowColor='blue-gray-700' className={triggerContainer} disabled={disabled}>
        {selectedItem ? (
          <div className={triggerContents}>
            <PollBadge type={selectedItem.id} />
            <Text size={14} fontWeight={400}>
              {selectedItem.label}
            </Text>
          </div>
        ) : (
          '선택해주세요'
        )}
      </Dropdown.Trigger>

      <Dropdown.Menu className={menuContainer} width={248} maxHeight={400}>
        <Text className={menuTitleContainer} size={14} fontWeight={500} color='gray-700'>
          {SURVEY_CATEGORY_LABELS[SurveyTypeCategory.문제유형]}
        </Text>

        {SURVEY_TYPES.filter((item) => item.category === SurveyTypeCategory.문제유형).map(
          (item) => (
            <Dropdown.Item
              key={item.label}
              allowPropagation
              className={menuItemStyle}
              onClick={() => onSelect(item.id)}
            >
              <PollBadge type={item.id} />

              <Text size={14} fontWeight={400}>
                {item.label}
              </Text>
            </Dropdown.Item>
          ),
        )}

        <hr className={divider} />

        <Text className={menuTitleContainer} size={14} fontWeight={500} color='gray-700'>
          {SURVEY_CATEGORY_LABELS[SurveyTypeCategory.정보입력유형]}
        </Text>

        {SURVEY_TYPES.filter((item) => item.category === SurveyTypeCategory.정보입력유형).map(
          (item) => (
            <Dropdown.Item
              key={item.label}
              allowPropagation
              className={menuItemStyle}
              onClick={() => onSelect(item.id)}
            >
              <PollBadge type={item.id} />

              <Text size={14} fontWeight={400}>
                {item.label}
              </Text>
            </Dropdown.Item>
          ),
        )}

        {!isPrivacyQuestionDisabled && (
          <>
            <hr className={divider} />

            <Text className={menuTitleContainer} size={14} fontWeight={500} color='gray-700'>
              {SURVEY_CATEGORY_LABELS[SurveyTypeCategory.개인정보수집동의]}
            </Text>

            {SURVEY_TYPES.filter(
              (item) => item.category === SurveyTypeCategory.개인정보수집동의,
            ).map((item) => (
              <Dropdown.Item
                key={item.label}
                allowPropagation
                className={menuItemStyle}
                onClick={() => onSelect(item.id)}
              >
                <PollBadge type={item.id} />

                <Text size={14} fontWeight={400}>
                  {item.label}
                </Text>
              </Dropdown.Item>
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const SurveyTypeDropdown = forwardRef(TypeDropdown);
export default SurveyTypeDropdown;
