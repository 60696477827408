import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import PollApplicationHeaderV2 from 'components/features/application-v2/Header';
import PollV2Tabs from 'components/features/application-v2/Tabs';
import SettingsPanel, { MenuValue } from 'components/features/poll-settings-v2/Panel';

import { usePollOutletContext } from 'hooks/poll-v2/usePollOutletContext';

import { ApplicationStepV2 } from 'types/application-v2';

import { container, menuContent } from './index.css';

const SET_BACKGROUND_COLOR_MENUS: MenuValue[] = [MenuValue.Collaborator];

function PollSettingsPageV2() {
  const context = usePollOutletContext();
  const { kind, pollId } = context;

  const navigate = useNavigate();

  const parentPath = `/poll/settings/${kind}/${pollId}`;
  const match = useMatch(`${parentPath}/:lastPathname`);
  const activeMenu = (match?.params.lastPathname || MenuValue.Collaborator) as MenuValue;

  const onMenuClick = (value: MenuValue) => navigate(`${parentPath}/${value}`);

  if (!pollId) {
    return null;
  }

  return (
    <div
      className={container({ setBackgroundColor: SET_BACKGROUND_COLOR_MENUS.includes(activeMenu) })}
    >
      <PollApplicationHeaderV2
        onClickTitle={() => {
          navigate(`/poll/application/${kind}/${pollId}?step=${ApplicationStepV2.Intro}`, {
            unstable_viewTransition: true,
          });
        }}
      >
        <PollV2Tabs />
      </PollApplicationHeaderV2>

      <SettingsPanel activeMenu={activeMenu} onMenuClick={onMenuClick} />

      <div className={menuContent}>
        <Outlet context={context} />
      </div>
    </div>
  );
}

export default PollSettingsPageV2;
