import dayjs from 'libs/dayjs';
import { difference, every, includes, remove, union } from 'lodash-es';
import { OtpMethod, QuestionKind, Survey, VoterAuthMethodType } from '@pickme/core';
import { Checkbox, Text, Tooltip, colors } from '@pickme/design-system';

import PollBadge from 'components/features/poll-v2/Badge';
import TableEmptyState from 'components/common/table/EmptyState';
import StarRating from 'components/features/poll-result-v2/StarRating';

import { SurveyTypeCase } from 'types/application-v2';

import { useIsEllipsis } from 'hooks/useIsEllipsis';
import { SurveyResponse } from 'models/poll-result-v2/response/survey';

import style from '../index.css';

type Props = {
  responses: SurveyResponse[];
  poll: Survey;
  checkedIds: string[];
  setCheckedIds: (value: string[]) => void;
};

function SurveyResponseTable({ responses, poll, checkedIds, setCheckedIds }: Props) {
  const responseIds = responses.map((response) => response.id);
  const areAllIdsChecked = () =>
    responseIds.length > 0 && every(responseIds, (id) => includes(checkedIds, id));

  const isPollSetupToShowEmail =
    (poll.voterAuthMethod.type === VoterAuthMethodType.VoterBook ||
      poll.voterAuthMethod.type === VoterAuthMethodType.OTP) &&
    (poll.voterAuthMethod.otpMethod.includes(OtpMethod.Email) ||
      poll.voterAuthMethod.otpMethod.includes(OtpMethod.All));
  const isPollSetupToShowPhoneNumber =
    (poll.voterAuthMethod.type === VoterAuthMethodType.VoterBook ||
      poll.voterAuthMethod.type === VoterAuthMethodType.OTP) &&
    (poll.voterAuthMethod.otpMethod.includes(OtpMethod.SMS) ||
      poll.voterAuthMethod.otpMethod.includes(OtpMethod.All));

  const isNameVisible = !poll.isAnonymousVoting;

  const isEmailVisible = !poll.isAnonymousVoting && isPollSetupToShowEmail;
  const isPhoneNumberVisible = !poll.isAnonymousVoting && isPollSetupToShowPhoneNumber;

  const checkboxCellWidth = document.getElementById('header-checkbox')?.offsetWidth || 0;
  const nameCellWidth = document.getElementById('header-name')?.offsetWidth || 0;
  const emailCellWidth = document.getElementById('header-email')?.offsetWidth || 0;

  const fixedCellStyles = [
    { left: 0 },
    { left: checkboxCellWidth },
    { left: checkboxCellWidth + nameCellWidth },
    {
      left: checkboxCellWidth + nameCellWidth + emailCellWidth,
      borderRight: `3px solid ${colors['blue-gray-200']}`,
    },
  ];

  return (
    <div className={style.container}>
      <table className={style.table}>
        <colgroup>
          <col />
          {isNameVisible && <col />}
          {isEmailVisible && <col />}
          {isPhoneNumberVisible && <col />}
          <col />
          {poll.questions.map(() => (
            <col width='100%' />
          ))}
        </colgroup>
        <thead>
          <tr>
            <th className={style.stickyCell} style={fixedCellStyles[0]} id='header-checkbox'>
              <div className={style.checkboxCell}>
                <Checkbox
                  checked={areAllIdsChecked()}
                  onChange={(event) => {
                    if (event.target.checked) {
                      const addedIds = union(checkedIds, responseIds);
                      setCheckedIds(addedIds);
                    } else {
                      const removedIds = difference(checkedIds, responseIds);
                      setCheckedIds(removedIds);
                    }
                  }}
                  className={style.checkbox}
                />
              </div>
            </th>

            {isNameVisible && (
              <th className={style.stickyCell} style={fixedCellStyles[1]} id='header-name'>
                <Text size={14} fontWeight={500} className={style.dateCell}>
                  이름
                </Text>
              </th>
            )}

            {isEmailVisible && (
              <th className={style.stickyCell} style={fixedCellStyles[2]} id='header-email'>
                <Text size={14} fontWeight={500} className={style.dateCell}>
                  이메일
                </Text>
              </th>
            )}

            {isPhoneNumberVisible && (
              <th className={style.stickyCell} style={fixedCellStyles[3]} id='header-phone'>
                <Text size={14} fontWeight={500} className={style.dateCell}>
                  휴대폰번호
                </Text>
              </th>
            )}

            <th>
              <Text size={14} fontWeight={500} className={style.dateCell}>
                제출 일시
              </Text>
            </th>

            {poll.questions.map((question, index) => {
              const badgeType = SURVEY_BADGE_TYPES[question.kind];
              return (
                <th key={`response table header ${index}`}>
                  <div className={style.headerCell}>
                    {badgeType && <PollBadge type={badgeType} number={index + 1} />}
                    <Text size={14} fontWeight={500} className={style.headerText}>
                      {question.title}
                    </Text>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {responses.length > 0 ? (
            responses.map((response) => (
              <tr key={`response table header ${response.id}`}>
                <td className={style.stickyCell} style={fixedCellStyles[0]}>
                  <div className={style.checkboxCell}>
                    <Checkbox
                      checked={checkedIds.includes(response.id)}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setCheckedIds([...checkedIds, response.id]);
                        } else {
                          const removedIds = [...checkedIds];
                          remove(removedIds, (id) => id === response.id);
                          setCheckedIds(removedIds);
                        }
                      }}
                      className={style.checkbox}
                    />
                  </div>
                </td>

                {isNameVisible && (
                  <td className={style.stickyCell} style={fixedCellStyles[1]}>
                    <Text size={14} fontWeight={500} color='gray-800' className={style.dateCell}>
                      {response.name || '-'}
                    </Text>
                  </td>
                )}

                {isEmailVisible && (
                  <td className={style.stickyCell} style={fixedCellStyles[2]}>
                    <Text size={14} fontWeight={500} color='gray-800' className={style.dateCell}>
                      {response.email || '-'}
                    </Text>
                  </td>
                )}

                {isPhoneNumberVisible && (
                  <td className={style.stickyCell} style={fixedCellStyles[3]}>
                    <Text size={14} fontWeight={500} color='gray-800' className={style.dateCell}>
                      {response.phoneNumber || '-'}
                    </Text>
                  </td>
                )}

                <td>
                  <Text size={14}>
                    {dayjs(response.timestamp).tz().format('YY.MM.DD (dd) HH:mm')}
                  </Text>
                </td>

                {response.responses.map((responseAnswer, resultIndex) => {
                  const key = `response table body ${response.id} ${resultIndex}`;

                  if (!responseAnswer) {
                    return <td key={key} aria-label='no response' />;
                  }

                  if ('answers' in responseAnswer) {
                    return (
                      <td key={key}>
                        {responseAnswer.answers.map((answer, checkboxAnswerIndex) => (
                          <SurveyResponseAnswerCell
                            key={`${key}-${checkboxAnswerIndex}`}
                            keyId={`${key}-${checkboxAnswerIndex}`}
                            answer={answer}
                          />
                        ))}
                      </td>
                    );
                  }

                  return (
                    <td key={key}>
                      <SurveyResponseAnswerCell keyId={key} answer={responseAnswer} />
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <TableEmptyState />
          )}
        </tbody>
      </table>
    </div>
  );
}

export default SurveyResponseTable;

function SurveyResponseAnswerCell({
  keyId,
  answer,
}: {
  keyId: string;
  answer: { text: number | string; imageUrl?: string; videoUrl?: string; maxStar?: number };
}) {
  const isEllipsis = useIsEllipsis(keyId, []);

  return (
    <Tooltip
      message={`${answer.text}`}
      placement='bottom'
      className={style.tooltip}
      disabled={!isEllipsis}
    >
      <div className={style.content}>
        {answer.imageUrl && <img src={answer.imageUrl} alt='selected' className={style.image} />}

        {answer.videoUrl && (
          <iframe
            width='90'
            height='60'
            src={answer.videoUrl}
            title='selected'
            referrerPolicy='strict-origin-when-cross-origin'
            style={{ border: 0 }}
          />
        )}

        {answer.maxStar && (
          <StarRating average={Number(answer.text)} starCount={answer.maxStar} size='sm' />
        )}

        <Text id={keyId} size={14} className={style.bodyCellText}>
          {answer.text || '-'}
        </Text>
      </div>
    </Tooltip>
  );
}

const SURVEY_BADGE_TYPES = {
  [QuestionKind.객관식]: SurveyTypeCase.객관식,
  [QuestionKind.이미지]: SurveyTypeCase.이미지선택,
  [QuestionKind.동영상]: SurveyTypeCase.동영상선택,
  [QuestionKind.드롭다운]: SurveyTypeCase.드롭다운,
  [QuestionKind.선호도]: SurveyTypeCase.선호도평가,
  [QuestionKind.선형배율]: SurveyTypeCase.선형배율,
  [QuestionKind.점수]: SurveyTypeCase.점수평가,
  [QuestionKind.별점]: SurveyTypeCase.별점,
  [QuestionKind.이메일]: SurveyTypeCase.이메일주소,
  [QuestionKind.링크]: SurveyTypeCase.링크,
  [QuestionKind.휴대폰번호]: SurveyTypeCase.휴대폰번호,
  [QuestionKind.날짜]: SurveyTypeCase.날짜,
  [QuestionKind.시간]: SurveyTypeCase.시간,
  [QuestionKind.주관식]: SurveyTypeCase.주관식,
  [QuestionKind.개인정보수집]: null,
};
