import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';

import { spaceState } from 'states/space';

import { useGetChannel } from 'query-hooks/channel-v2';

export const useActiveChannel = () => {
  const queryClient = useQueryClient();
  const [activeChannelId, setActiveChannelId] = useRecoilState(spaceState.activeSpaceId);
  const { data: activeChannel, isError } = useGetChannel({
    key: activeChannelId,
    type: 'objectId',
  });

  useEffect(() => {
    if (isError && activeChannelId) {
      queryClient.invalidateQueries(['auth-v2']);
      queryClient.invalidateQueries(['channels-v2']);
      queryClient.invalidateQueries(['my-channels-v2']);
      setActiveChannelId('');
    }
  }, [isError, activeChannelId]);

  return { activeChannel, activeChannelId, setActiveChannelId };
};
