import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getIntroFormSchema } from 'functions/validator/application-v2/intro';
import { getEndingFormSchema } from 'functions/validator/application-v2/ending';
import { getSettingsFormSchema } from 'functions/validator/application-v2/settings';
import { getVoterBookFormSchema } from 'functions/validator/application-v2/voterbook';
import { getIssueElectionFormSchema } from 'functions/validator/application-v2/issue-election';

import { POLL_THEMES } from 'constants/theme-v2';
import {
  ENDING_FORM_DEFAULT_VALUES,
  INTRO_FORM_DEFAULT_VALUES,
  SETTINGS_FORM_DEFAULT_VALUES,
  VOTER_BOOK_FORM_DEFAULT_VALUES,
} from 'constants/application-v2';

import {
  IssueElectionForm as IssueElectionFormType,
  EndingForm as EndingFormType,
  IntroForm as IntroFormType,
  SettingsForm as SettingsFormType,
  CustomThemeForm,
  ThemeNames,
  VoterBookForm as VoterBookFormType,
  ThemeType,
  VoterFields,
  VotersFormContext,
} from 'types/application-v2';

const introSchema = getIntroFormSchema();
const endingSchema = getEndingFormSchema();
const settingsSchema = getSettingsFormSchema();
const voterBookSchema = getVoterBookFormSchema();
const electionFormSchema = getIssueElectionFormSchema();

function useIssueElectionForms() {
  const [voters, setVoters] = useState<VoterFields[]>([]);
  const [invalidVoters, setInvalidVoters] = useState<VoterFields[]>([]);

  const introForm = useForm<IntroFormType>({
    defaultValues: INTRO_FORM_DEFAULT_VALUES,
    resolver: yupResolver(introSchema),
    mode: 'onTouched',
  });

  const issueElectionForm = useForm<IssueElectionFormType>({
    defaultValues: {
      issueElections: [],
    },
    resolver: yupResolver(electionFormSchema),
    mode: 'onSubmit',
  });

  const customThemeForm = useForm<CustomThemeForm>({
    defaultValues: {
      type: ThemeType.Custom,
      colors: {
        primary: POLL_THEMES[ThemeNames.White].colors.primary,
        secondary: POLL_THEMES[ThemeNames.White].colors.secondary,
      },
    },
  });

  const endingForm = useForm<EndingFormType>({
    defaultValues: ENDING_FORM_DEFAULT_VALUES,
    resolver: yupResolver(endingSchema),
    mode: 'onTouched',
  });

  const settingsForm = useForm<SettingsFormType>({
    defaultValues: SETTINGS_FORM_DEFAULT_VALUES,
    resolver: yupResolver(settingsSchema),
    mode: 'onTouched',
  });

  const voterBookForm = useForm<VoterBookFormType>({
    defaultValues: VOTER_BOOK_FORM_DEFAULT_VALUES,
    resolver: yupResolver(voterBookSchema),
    mode: 'onSubmit',
  });

  const isValid =
    introForm.formState.isValid &&
    issueElectionForm.formState.isValid &&
    endingForm.formState.isValid &&
    settingsForm.formState.isValid;

  console.log(
    introForm.formState.isValid,
    issueElectionForm.formState.isValid,
    endingForm.formState.isValid,
    settingsForm.formState.isValid,
  );

  console.log(
    introForm.formState.errors,
    issueElectionForm.formState.errors,
    endingForm.formState.errors,
    settingsForm.formState.errors,
  );

  const getValues = () => ({
    customThemeForm: customThemeForm.getValues(),
    issueElectionForm: issueElectionForm.getValues(),
    endingForm: endingForm.getValues(),
    introForm: introForm.getValues(),
    settingsForm: settingsForm.getValues(),
    voterBookForm: voterBookForm.getValues(),
    votersForm: { voters, invalidVoters },
  });

  const isDirty =
    customThemeForm.formState.isDirty ||
    issueElectionForm.formState.isDirty ||
    endingForm.formState.isDirty ||
    introForm.formState.isDirty ||
    settingsForm.formState.isDirty ||
    voterBookForm.formState.isDirty;

  const votersForm: VotersFormContext = {
    voters,
    setVoters,
    invalidVoters,
    setInvalidVoters,
  };

  return {
    introForm,
    issueElectionForm,
    customThemeForm,
    endingForm,
    settingsForm,
    voterBookForm,
    votersForm,
    isValid,
    isDirty,
    getValues,
  };
}

export default useIssueElectionForms;
