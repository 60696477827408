import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Plan } from '@pickme/core';
import { t } from 'i18next';

import ApplicationLayout from 'components/common/layout/Application';
import CompleteModal from 'components/features/application/modal/Complete';
import ElectionForm from 'components/features/application/form/Election';
import PlanField from 'components/features/application/field/PlanField';

import { useEditPoll } from 'query-hooks/poll';

import { useElectionForm } from 'hooks/poll/usePollForm';
import { usePollFormStepFromHashRouter } from 'hooks/poll/usePollFormStepFromHashRouter';
import { useInitializeElectionEditForm } from 'hooks/poll/useInitializeEditForm';
import { useGetFeatures } from 'hooks/useGetFeatures';

import { getDirtyFieldsFromElectionForm } from 'functions/application';

import { PollFormStep } from 'types/application';

function ElectionEdit() {
  const navigate = useNavigate();
  const id = useParams<{ id: string }>().id ?? '';
  const { pathname } = useLocation();

  const [isCompleteModalVisible, setCompleteModalVisible] = useState(false);

  const forms = useElectionForm();
  const { basicForm, subElectionsForm, votersForm, additionalForm } = forms;
  useInitializeElectionEditForm(id, forms);

  const isBasicFormActive = basicForm.formState.isValid;
  const isSubElectionsFormActive = subElectionsForm.formState.isValid;

  const incorrectVoterCounts = votersForm.watch('incorrectVoters').length;
  const isVotersFormActive = votersForm.formState.isValid && incorrectVoterCounts === 0;
  const isAdditionalFormActive =
    isBasicFormActive && isSubElectionsFormActive && isVotersFormActive;

  const step = usePollFormStepFromHashRouter({
    isSecondStepAccessible: isBasicFormActive,
    isThirdStepAccessible: isSubElectionsFormActive,
    isForthStepAccessible: isBasicFormActive && isSubElectionsFormActive && isVotersFormActive,
  });

  const { isLoading, mutate, isSuccess } = useEditPoll();

  const onSubmit = () => {
    if (!id) {
      return;
    }

    if (
      !(
        basicForm.formState.isValid &&
        subElectionsForm.formState.isValid &&
        votersForm.formState.isValid &&
        additionalForm.formState.isValid
      )
    ) {
      return;
    }

    const body = getDirtyFieldsFromElectionForm({
      basicForm,
      subElectionsForm,
      votersForm,
      additionalForm,
    });
    mutate({ id, body, kind: 'Election' });
  };

  useEffect(() => {
    if (isSuccess) {
      setCompleteModalVisible(true);
    }
  }, [isSuccess]);

  const { isPaymentFailed } = useGetFeatures();
  const subscriptionType = basicForm.watch('subscriptionType');
  useEffect(() => {
    if (subscriptionType && subscriptionType !== Plan.Free && isPaymentFailed) {
      navigate(-1);
    }
  }, [isPaymentFailed, subscriptionType]);

  return (
    <ApplicationLayout<PollFormStep>
      items={[
        {
          value: 0,
          title: t('admin:pages.poll.election.stages.0'),
          active: isBasicFormActive,
          selected: step === 0,
        },
        {
          value: 1,
          title: t('admin:pages.poll.election.stages.1'),
          active: isSubElectionsFormActive,
          selected: step === 1,
          disabled: !isBasicFormActive,
        },
        {
          value: 2,
          title: t('admin:pages.poll.election.stages.2'),
          active: isVotersFormActive,
          selected: step === 2,
          disabled: !(isBasicFormActive && isSubElectionsFormActive),
        },
        {
          value: 3,
          title: t('admin:pages.poll.election.stages.3'),
          active: isAdditionalFormActive,
          selected: step === 3,
          disabled: !isAdditionalFormActive,
        },
      ]}
      title={t('admin:pages.poll.election.title.edit')}
      rightComponent={
        <PlanField
          kind='election'
          selectedPlan={basicForm.watch('subscriptionType')}
          onSelect={(plan) =>
            basicForm.setValue('subscriptionType', plan, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            })
          }
          isEdit
        />
      }
      onClose={() => navigate('/', { replace: true, unstable_viewTransition: true })}
      onSelect={(selectedStep: PollFormStep) => {
        if (
          (step === 0 && !basicForm.formState.isValid) ||
          (step === 1 && !subElectionsForm.formState.isValid) ||
          (step === 2 && !votersForm.formState.isValid) ||
          (step === 3 && !additionalForm.formState.isValid)
        ) {
          return;
        }

        navigate(`${pathname}#${selectedStep + 1}`, {
          unstable_viewTransition: true,
          replace: true,
        });
      }}
    >
      <ElectionForm
        step={step}
        isLoading={isLoading}
        basicForm={basicForm}
        subElectionsForm={subElectionsForm}
        votersForm={votersForm}
        additionalForm={additionalForm}
        isEdit
        onSubmit={onSubmit}
      />

      <CompleteModal
        type='edit'
        kind='election'
        isVisible={isCompleteModalVisible}
        onClose={() => {
          setCompleteModalVisible(false);
          navigate(`/poll/election/${id}`, {
            unstable_viewTransition: true,
          });
        }}
      />
    </ApplicationLayout>
  );
}

export default ElectionEdit;
