import clsx from 'clsx';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { PollGroup } from '@pickme/core';
import { Text, PlainButton } from '@pickme/design-system';

import DdayBadge from 'components/features/poll/DdayBadge';
import PollMoreDropdown, { DROPDOWN_CLASS } from 'components/features/home/PollMoreDropdown';
import DynamicButtonByPollGroup from 'components/features/home/cards/Poll/DynamicButtonByPollGroup';

import { formatNumber } from 'functions/utils';
import { getGroupColorFromPollGroup } from 'functions/poll';

import { pollShareModal } from 'states/modal';

import { Poll } from 'types/poll';

import ShareIcon from 'resources/icons/table/buttons/near_me.svg';
import ElectionIcon from 'resources/icons/poll-home/election.svg';
import SurveyIcon from 'resources/icons/poll-home/survey.svg';

import { badge, card, contents, date, rightSection } from '../../common.css';
import { statusColor } from './index.css';

type Props = {
  poll: Poll;
};

function HomePollCard({ poll }: Props) {
  const navigate = useNavigate();
  const setPollShareModal = useSetRecoilState(pollShareModal);

  const color = getGroupColorFromPollGroup(poll.group);

  const now = dayjs();
  const start = dayjs(poll.startDate);
  const isBefore = now.isBefore(start);

  const isDdayBadgeVisible = poll.group === PollGroup.Ready || poll.group === PollGroup.Ongoing;
  const isPollRateVisible = poll.kind === 'Election' && poll.group === PollGroup.Ongoing;

  const pollKind = poll.kind.toLowerCase();
  const navigateToEditPage = () =>
    navigate(`/poll/${pollKind}/edit/${poll._id}`, { unstable_viewTransition: true });

  return (
    <div
      className={card}
      role='button'
      tabIndex={0}
      onClick={(event) => {
        const clickedElement = event.target as HTMLElement;

        const isDropdownChild = clickedElement.closest(`.${DROPDOWN_CLASS}`);

        if (isDropdownChild) {
          return;
        }

        navigateToEditPage();
      }}
      onKeyDown={(event) => {
        if (event.key !== 'Enter') {
          return;
        }

        navigateToEditPage();
      }}
    >
      <div className={statusColor} style={{ backgroundColor: color }} />

      <div className={contents.container}>
        <div className={contents.left}>
          <div className={badge.container}>
            <img src={poll.kind === 'Election' ? ElectionIcon : SurveyIcon} alt='poll kind' />

            {isDdayBadgeVisible && (
              <DdayBadge
                startDate={poll.startDate}
                endDate={poll.endDate.date}
                unsetBorder
                className={clsx({ [badge.item]: isBefore })}
              />
            )}

            <div className={badge.item}>구성원 투표</div>

            <div className={badge.item}>{formatNumber(poll.pollRate.voteCount)}명 참여</div>

            {isPollRateVisible && (
              <div className={badge.item}>투표율 {poll.pollRate.rate * 100}%</div>
            )}
          </div>

          <Text size={16} fontWeight={600} color='gray-800'>
            {poll.name}
          </Text>

          <Text size={14} fontWeight={500} color='gray-500' className={date}>
            {dayjs(poll.startDate).format('YY.MM.DD (dd) HH:mm')} ~
            {dayjs(poll.endDate.date).format('YY.MM.DD (dd) HH:mm')}
          </Text>
        </div>

        <div className={contents.right}>
          <DynamicButtonByPollGroup poll={poll} />

          <PlainButton
            className={rightSection.iconButton}
            onClick={(e) => {
              e.stopPropagation();
              setPollShareModal({ pollId: poll._id, pollName: poll.name, isVisible: true });
            }}
          >
            <img src={ShareIcon} alt='share poll' />
          </PlainButton>

          <PollMoreDropdown poll={poll} triggerClassName={rightSection.iconButton} />
        </div>
      </div>
    </div>
  );
}

export default HomePollCard;
