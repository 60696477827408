import { t } from 'i18next';

import { createSelectItems } from 'functions/utils';

import { TabItemWithPath } from 'types/common';
import { MessagingMethod, MessagingStatus, RecipientType, TemplateType } from 'types/message';

export const MESSAGE_SEND_TABS: TabItemWithPath[] = [
  {
    text: '카카오 알림톡',
    path: 'alimTalk',
    value: 'alimTalk',
  },
  {
    text: '문자',
    path: 'lms',
    value: 'lms',
  },
  {
    text: '이메일',
    path: 'email',
    value: 'email',
  },
];

export const HISTORY_TABS: TabItemWithPath[] = [
  {
    text: '전송 이력',
    path: 'sent',
    value: 'sent',
  },
  {
    text: '예약 관리',
    path: 'reserved',
    value: 'reserved',
  },
];

export const MESSAGE_POINT_TABS: TabItemWithPath[] = [
  {
    text: t('admin:constants.notification.tabs.point.0'),
    path: 'incoming',
    value: 'incoming',
  },
  {
    text: t('admin:constants.notification.tabs.point.1'),
    path: 'usage',
    value: 'usage',
  },
];

export const MESSAGING_DEFAULT_SENDER = {
  [MessagingMethod.AlimTalk]: '[기본] 치즈버튼',
  [MessagingMethod.Lms]: '1533-1912',
  [MessagingMethod.Email]: '[기본] 치즈버튼',
};

export const TEMPLATE_TYPE_LABELS: Record<TemplateType, string> = {
  '1': '시작 안내',
  '2': '참여 독려',
  '3': '결과 안내',
  '4': '일시정지 안내',
  '5': '재시작 안내',
};

export const TEMPLATE_TYPE_SELECT_ITEMS = Object.entries(TEMPLATE_TYPE_LABELS).map(
  ([key, value], index) => ({
    label: `[템플릿 ${index + 1}] ${value}`,
    value: key,
  }),
);

export const ALIMTALK_TEMPLATE_TYPE_SELECT_ITEMS = [
  {
    label: `[템플릿 1] 시작 안내`,
    value: '1',
  },
  {
    label: `[템플릿 2] 참여 독려`,
    value: '2',
  },
  {
    label: `[템플릿 3] 결과 안내`,
    value: '3',
  },
  {
    label: `[템플릿 4] 일시정지 안내`,
    value: '4',
  },
  {
    label: `[템플릿 5] 재시작 안내`,
    value: '5',
  },
];

export const EDITOR_TEMPLATE_VARIABLES = [
  {
    value: 'userName',
    label: '# 대상자 이름',
    color: 'white',
    backgroundColor: '#0067E7',
    disabledColor: '#363E86',
    disabledBackgroundColor: '#E0ECFE',
  },
  {
    value: 'pollTitle',
    label: '# 제목',
    color: 'white',
    backgroundColor: '#7342C2',
    disabledColor: '#573985',
    disabledBackgroundColor: '#ECE3FA',
  },
  {
    value: 'periods',
    label: '# 기간',
    color: 'white',
    backgroundColor: '#FF9838',
    disabledColor: '#756B62',
    disabledBackgroundColor: '#FFF3D4',
  },
  {
    value: 'participateUrl',
    label: '# 참여 URL',
    color: 'white',
    backgroundColor: '#13A56C',
    disabledColor: '#677266',
    disabledBackgroundColor: '#E9F4E9',
  },
  {
    value: 'resultUrl',
    label: '# 결과확인 URL',
    color: 'white',
    backgroundColor: '#607AAC',
    disabledColor: '#303E59',
    disabledBackgroundColor: '#E2E6ED',
  },
];

export const EDITOR_TEMPLATES: Record<TemplateType, { subject: string; contents: string }> = {
  '1': {
    subject: '시작 안내',
    contents:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">안녕하세요. </span><button type="button" data-lexical-template-value="userName" data-lexical-template-label="# 대상자 이름" style="color: rgb(54, 62, 134); background-color: rgb(224, 236, 254);">{{userName}}</button><span style="white-space: pre-wrap;">님!</span></p><p class="editor-paragraph" dir="ltr"><button type="button" data-lexical-template-value="pollTitle" data-lexical-template-label="# 제목" style="color: rgb(87, 57, 133); background-color: rgb(236, 227, 250);">{{pollTitle}}</button><span style="white-space: pre-wrap;">가 시작되었습니다. 많은 관심과 적극적인 참여 부탁드립니다.</span></p><p class="editor-paragraph"><br></p><p class="editor-paragraph"><button type="button" data-lexical-template-value="periods" data-lexical-template-label="# 기간" style="color: rgb(117, 107, 98); background-color: rgb(255, 243, 212);">{{periods}}</button></p><p class="editor-paragraph"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">투표는 아래 URL 클릭을 통해 진행가능 합니다.</span></p><p class="editor-paragraph"><button type="button" data-lexical-template-value="participateUrl" data-lexical-template-label="# 참여 URL" style="color: rgb(103, 114, 102); background-color: rgb(233, 244, 233);">{{participateUrl}}</button></p>',
  },
  '2': {
    subject: '참여 안내',
    contents:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">안녕하세요. </span><button type="button" data-lexical-template-value="userName" data-lexical-template-label="# 대상자 이름" style="color: rgb(54, 62, 134); background-color: rgb(224, 236, 254);">{{userName}}</button><span style="white-space: pre-wrap;">님!</span></p><p class="editor-paragraph" dir="ltr"><button type="button" data-lexical-template-value="pollTitle" data-lexical-template-label="# 제목" style="color: rgb(87, 57, 133); background-color: rgb(236, 227, 250);">{{pollTitle}}</button><span style="white-space: pre-wrap;">가 시작되었습니다. 많은 관심과 적극적인 참여 부탁드립니다.</span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">* 본 메세지는 투표에 참여하지 않은 대상자에게만 발송되는 메세지입니다.</span></p><p class="editor-paragraph" dir="ltr"><br></p><p class="editor-paragraph"><button type="button" data-lexical-template-value="periods" data-lexical-template-label="# 기간" style="color: rgb(117, 107, 98); background-color: rgb(255, 243, 212);">{{periods}}</button></p><p class="editor-paragraph"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">투표는 아래 URL 클릭을 통해 진행가능 합니다.</span></p><p class="editor-paragraph"><button type="button" data-lexical-template-value="participateUrl" data-lexical-template-label="# 참여 URL" style="color: rgb(103, 114, 102); background-color: rgb(233, 244, 233);">{{participateUrl}}</button></p>',
  },
  '3': {
    subject: '결과 확인',
    contents:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">안녕하세요. </span><button type="button" data-lexical-template-value="userName" data-lexical-template-label="# 대상자 이름" style="color: rgb(54, 62, 134); background-color: rgb(224, 236, 254);">{{userName}}</button><span style="white-space: pre-wrap;">님!</span></p><p class="editor-paragraph" dir="ltr"><button type="button" data-lexical-template-value="pollTitle" data-lexical-template-label="# 제목" style="color: rgb(87, 57, 133); background-color: rgb(236, 227, 250);">{{pollTitle}}</button><span style="white-space: pre-wrap;">가 종료되었습니다. 결과를 확인해 주세요.</span></p><p class="editor-paragraph" dir="ltr"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">결과는 아래 URL 클릭을 통해 확인가능 합니다.</span></p><p class="editor-paragraph"><button type="button" data-lexical-template-value="resultUrl" data-lexical-template-label="# 결과확인 URL" style="color: rgb(48, 62, 89); background-color: rgb(195, 204, 222);">{{resultUrl}}</button></p>',
  },
  '4': {
    subject: '일시정지 안내',
    contents:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">안녕하세요. </span><button type="button" data-lexical-template-value="userName" data-lexical-template-label="# 대상자 이름" style="color: rgb(54, 62, 134); background-color: rgb(224, 236, 254);">{{userName}}</button><span style="white-space: pre-wrap;">님!</span></p><p class="editor-paragraph" dir="ltr"><button type="button" data-lexical-template-value="pollTitle" data-lexical-template-label="# 제목" style="color: rgb(87, 57, 133); background-color: rgb(236, 227, 250);">{{pollTitle}}</button><span style="white-space: pre-wrap;">가 일시정지 되었습니다. 재시작되기 전까지 참여가 일시적으로 제한됩니다.</span></p>',
  },
  '5': {
    subject: '재시작 안내',
    contents:
      '<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">안녕하세요. </span><button type="button" data-lexical-template-value="userName" data-lexical-template-label="# 대상자 이름" style="color: rgb(54, 62, 134); background-color: rgb(224, 236, 254);">{{userName}}</button><span style="white-space: pre-wrap;">님!</span></p><p class="editor-paragraph" dir="ltr"><button type="button" data-lexical-template-value="pollTitle" data-lexical-template-label="# 제목" style="color: rgb(87, 57, 133); background-color: rgb(236, 227, 250);">{{pollTitle}}</button><span style="white-space: pre-wrap;">가 재시작되었습니다.</span></p><p class="editor-paragraph"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">투표는 아래 URL 클릭을 통해 진행가능 합니다.</span></p><p class="editor-paragraph"><button type="button" data-lexical-template-value="participateUrl" data-lexical-template-label="# 참여 URL" style="color: rgb(103, 114, 102); background-color: rgb(233, 244, 233);">{{participateUrl}}</button></p>',
  },
};

export const MESSAGING_METHOD_LABELS = {
  all: '전체 메시지 타입',
  [MessagingMethod.AlimTalk]: '카카오 알림톡',
  [MessagingMethod.Lms]: '문자',
  [MessagingMethod.Email]: '이메일',
};

export const MESSAGING_METHOD_SELECT_ITEMS = createSelectItems<MessagingMethod | 'all'>(
  MESSAGING_METHOD_LABELS,
);

export const MESSAGING_STATUS_LABELS = {
  [MessagingStatus.Complete]: '전송 완료',
  [MessagingStatus.Pending]: '전송 요청',
  [MessagingStatus.Sending]: '전송중',
};

export const RECIPIENT_TYPE_LABELS = {
  [RecipientType.All]: '전체',
  [RecipientType.NonVotedVoters]: '미참여자',
};
