import {
  EXTERNAL_SOCIAL_TITLE_MAP,
  ExternalChannelEditRequestBody,
  ExternalChannelResponse,
  ExternalManagerResponse,
  ExternalMyChannelResponse,
  ManagerRoleTitleV2,
  ManagerStatus,
} from '@pickme/core';

import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import {
  ChannelCreateForm,
  ChannelEditForm,
  ChannelAcceptForm,
  ChannelTransferRequestBody,
  ChannelQueryType,
  TransferredChannelResponse,
  TransferInfoResponse,
} from 'types/channel-v2';

const APIS: Apis = {
  createChannel: { method: 'POST', url: '/v2/organization' },
  getChannel: { method: 'GET', url: '/v2/organization/:key' },
  getMyChannels: { method: 'GET', url: '/v2/user/my/organizations' },
  editChannel: { method: 'PUT', url: '/v2/organization/:id' },
  joinChannel: { method: 'POST', url: '/v2/organization/:channelId/apply' },
  getManagers: { method: 'GET', url: '/api/admin' },
  approveManager: { method: 'PUT', url: '/v2/admin/examine/:managerId' },
  deleteManager: { method: 'PUT', url: '/v2/admin/examine/:managerId' },
  editManager: { method: 'PUT', url: '/v2/admin/:managerId' },
  removeChannel: { method: 'DELETE', url: '/api/organization' },
  leaveChannel: { method: 'POST', url: '/api/organization/exit' },
  checkAliasDuplicated: { method: 'GET', url: '/api/organization/check/duplicate/:alias' },
  channelTransfer: { method: 'POST', url: '/v2/organization/transfer/request' },
  acceptChannelSubscribingPlan: {
    method: 'POST',
    url: '/v2/organization/:channelId/transfer/accept-with-payment',
  },
  acceptFreePlanChannel: {
    method: 'POST',
    url: '/v2/organization/:channelId/transfer/accept',
  },
  getChannelTransferInfo: {
    method: 'GET',
    url: '/api/organization/transfer-request/:transferRequestId',
  },
  getTransferredChannelInfo: {
    method: 'GET',
    url: '/v2/organization/:channelId/transfer',
  },
};

export const createGetChannelRequest = ({ key, type }: { key: string; type: ChannelQueryType }) =>
  createHttpRequest<ExternalChannelResponse>({
    ...APIS.getChannel,
    params: {
      key,
    },
    query: {
      type,
    },
    skipOrganizationOidCheck: true,
    withoutOrganizationOid: true,
  });

export const createGetMyChannelsRequest = () =>
  createHttpRequest<ExternalMyChannelResponse[]>({
    ...APIS.getMyChannels,
    skipOrganizationOidCheck: false,
    withoutOrganizationOid: true,
  });

export const createJoinChannelRequest = ({
  channelId,
  role,
}: {
  channelId: string;
  role: ManagerRoleTitleV2;
}) =>
  createHttpRequest<ExternalChannelResponse>({
    ...APIS.joinChannel,
    params: {
      channelId,
    },
    data: {
      role,
    },
    skipOrganizationOidCheck: true,
    withoutOrganizationOid: true,
  });

export const createGetManagersRequest = ({
  page,
  size = 20,
  status,
}: {
  page: number;
  size?: number;
  status?: ManagerStatus;
}) => {
  const parsedQuery: { page: number; size?: number; status?: ManagerStatus } = {
    page,
    size,
  };

  if (status) {
    parsedQuery.status = status;
  }

  return createHttpRequest<{
    totalCount: number;
    endPage: number;
    admins: ExternalManagerResponse[];
  }>({
    ...APIS.getManagers,
    query: parsedQuery,
  });
};

export const createApproveManagerRequest = ({ managerId }: { managerId: string }) =>
  createHttpRequest({
    ...APIS.approveManager,
    params: {
      managerId,
    },
    data: {
      approval: true,
    },
  });

export const createDeleteManagerRequest = ({ managerId }: { managerId: string }) =>
  createHttpRequest({
    ...APIS.deleteManager,
    params: {
      managerId,
    },
    data: {
      approval: false,
    },
  });

export const createEditManagerRequest = ({
  managerId,
  role,
}: {
  managerId: string;
  role: ManagerRoleTitleV2;
}) =>
  createHttpRequest({
    ...APIS.editManager,
    params: {
      managerId,
    },
    data: {
      role,
    },
  });

export const createEditChannelRequest = (id: string, body: ChannelEditForm) => {
  const parsedData: ExternalChannelEditRequestBody = {
    name: body.name,
    alias: body.alias,
    description: body.description,
    logo: body.logo,
    cover: body.cover,
    snsLinks: body.socials.map((social) => ({
      platform: EXTERNAL_SOCIAL_TITLE_MAP[social.platform],
      link: social.url,
    })),
  };

  return createHttpRequest({
    ...APIS.editChannel,
    params: { id },
    data: parsedData,
  });
};

export const createRemoveChannelRequest = () =>
  createHttpRequest({
    ...APIS.removeChannel,
  });

export const createLeaveChannelRequest = () =>
  createHttpRequest({
    ...APIS.leaveChannel,
  });

export const createChannelRequest = (data: ChannelCreateForm) =>
  createHttpRequest<ExternalChannelResponse>({
    ...APIS.createChannel,
    data,
    withoutOrganizationOid: true,
  });

export const createGetChannelAliasDuplicationRequest = (alias: string) =>
  // true => 중복
  // false => 사용가능
  createHttpRequest<{ result: boolean }>({ ...APIS.checkAliasDuplicated, params: { alias } });

export const createChannelTransferRequest = (data: ChannelTransferRequestBody) =>
  createHttpRequest({
    ...APIS.channelTransfer,
    apiVersion: 2,
    data,
  });

export const createAcceptChannelSubscribingPlanRequest = (data: ChannelAcceptForm) =>
  createHttpRequest({
    ...APIS.acceptChannelSubscribingPlan,
    withoutOrganizationOid: true,
    apiVersion: 2,
    params: {
      channelId: data.channelId,
    },
    data: {
      authKey: data.authKey,
      customerKey: data.customerKey,
    },
  });

export const createAcceptFreePlanChannelRequest = (channelId: string) =>
  createHttpRequest({
    ...APIS.acceptFreePlanChannel,
    apiVersion: 2,
    withoutOrganizationOid: true,
    params: {
      channelId,
    },
  });

export const createGetTransferredChannelInfoRequest = (channelId: string) =>
  createHttpRequest<TransferredChannelResponse>({
    ...APIS.getTransferredChannelInfo,
    apiVersion: 2,
    params: {
      channelId,
    },
    withoutOrganizationOid: true,
  });

export const createGetTransferInfoRequest = (transferRequestId: string) =>
  createHttpRequest<TransferInfoResponse>({
    ...APIS.getChannelTransferInfo,
    params: {
      transferRequestId,
    },
    withoutOrganizationOid: true,
  });
