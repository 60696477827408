import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import BasicForm from 'components/features/application/form/Basic';
import QuestionsForm from 'components/features/application/form/Questions';
import VoterForm from 'components/features/application/form/Voter';
import AdditionalForm from 'components/features/application/form/Additional';

import {
  AdditionalFormBody,
  BasicFormBody,
  PollFormStep,
  QuestionsFormBody,
  VoterFormBody,
} from 'types/application';

type Props = {
  step: PollFormStep;
  basicForm: UseFormReturn<BasicFormBody>;
  questionsForm: UseFormReturn<QuestionsFormBody>;
  votersForm: UseFormReturn<VoterFormBody>;
  additionalForm: UseFormReturn<AdditionalFormBody>;
  isLoading: boolean;
  isEdit?: boolean;
  onSubmit: () => void;
};

function SurveyForm({
  step,
  basicForm,
  questionsForm,
  votersForm,
  additionalForm,
  isLoading,
  isEdit,
  onSubmit,
}: Props) {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const subscriptionType = basicForm.watch('subscriptionType');

  return (
    <div>
      <FormProvider {...basicForm}>
        {step === 0 && (
          <BasicForm
            kind='survey'
            isEdit={isEdit}
            onSubmit={() => {
              navigate(`${pathname}${search}#2`);
            }}
          />
        )}
      </FormProvider>

      <FormProvider {...questionsForm}>
        {step === 1 && (
          <QuestionsForm
            onSubmit={() => {
              navigate(`${pathname}${search}#3`);
            }}
          />
        )}
      </FormProvider>

      <FormProvider {...votersForm}>
        {step === 2 && (
          <VoterForm
            kind='Survey'
            selectedPlan={subscriptionType}
            pollName={basicForm.watch('name') || ''}
            onSubmit={() => {
              navigate(`${pathname}${search}#4`);
            }}
          />
        )}
      </FormProvider>

      <FormProvider {...additionalForm}>
        {step === 3 && (
          <AdditionalForm
            hasInstantVoter={!!votersForm.watch('hasInstantVoter')}
            kind='survey'
            isLoading={isLoading}
            isEdit={isEdit}
            onSubmit={onSubmit}
          />
        )}
      </FormProvider>
    </div>
  );
}

export default SurveyForm;
