import * as yup from 'yup';

import {
  INTRO_TITLE_MAX_LENGTH,
  INTRO_START_BUTTON_MAX_LENGTH,
  INTRO_DESCRIPTION_MAX_LENGTH,
} from 'constants/application-v2';

import { CoverMediaType } from 'types/application-v2';

export const getIntroFormSchema = () =>
  yup.object().shape({
    title: yup
      .string()
      .required('제목을 입력해주세요')
      .max(INTRO_TITLE_MAX_LENGTH, `제목은 최대 ${INTRO_TITLE_MAX_LENGTH}자까지 입력 가능합니다`),
    buttonText: yup
      .string()
      .max(
        INTRO_START_BUTTON_MAX_LENGTH,
        `시작 버튼 텍스트는 최대 ${INTRO_START_BUTTON_MAX_LENGTH}자까지 입력 가능합니다`,
      ),
    cover: yup.object().shape({
      type: yup.mixed<CoverMediaType>().oneOf([CoverMediaType.Image, CoverMediaType.Video]),
      url: yup.string().url(),
    }),
    description: yup
      .string()
      .test(
        'description-length',
        `설명은 최대 ${INTRO_DESCRIPTION_MAX_LENGTH}자까지 입력 가능합니다`,
        (value) => {
          const parsedValue = value?.replace(/<(img|iframe)[^>]*>/g, '!')?.replace(/<[^>]*>/g, '');

          return !parsedValue || parsedValue.length <= INTRO_DESCRIPTION_MAX_LENGTH;
        },
      ),
  });
