import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ProfileV2 } from '@pickme/core';

import { createGetProfile, createSignOutRequest } from 'apis/auth-v2';

import { EMPTY_STRING } from 'constants/common';

import { spaceState } from 'states/space';
import { redirectToAuth } from 'utils/redirect';

export const useGetProfile = (options?: { suspense: boolean; disabledToAuth?: boolean }) => {
  const queryClient = useQueryClient();
  const [activeChannelId, setActiveChannelId] = useRecoilState(spaceState.activeSpaceId);

  return useQuery(
    ['auth-v2', 'profile', activeChannelId],
    async () => {
      try {
        const { data } = await createGetProfile();

        return new ProfileV2(data, activeChannelId);
      } catch (error) {
        setActiveChannelId('');

        if (!options?.disabledToAuth) {
          redirectToAuth();
        }

        throw error;
      }
    },
    {
      suspense: options?.suspense,
      refetchOnReconnect: !!queryClient.getQueryData(['auth-v2', 'profile']),
      refetchOnWindowFocus: !!queryClient.getQueryData(['auth-v2', 'profile']),
      useErrorBoundary: false,
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    },
  );
};

export const useSignOut = () => {
  const queryClient = useQueryClient();
  const setActiveChannelId = useSetRecoilState(spaceState.activeSpaceId);

  return useMutation(
    async () => {
      await createSignOutRequest();
    },
    {
      onSuccess: (_, { redirectToIntroduction }: { redirectToIntroduction: boolean }) => {
        if (redirectToIntroduction) {
          window.location.href =
            import.meta.env.MODE === 'dev.local' ? '/' : import.meta.env.VITE_INTRO_URL;
        }

        setActiveChannelId(EMPTY_STRING);
        queryClient.resetQueries();
      },
    },
  );
};
