import { useEffect, useState } from 'react';
import { Modal } from '@pickme/ui';
import { Text, PlainButton, Button } from '@pickme/design-system';
import moment from 'moment-timezone';

import CloseImage from 'resources/icons/common/close.svg';
import CheeseButtonLogo from './cheese-logo.svg';
import Transfer from './transfer.png';

import { popup } from './index.css';

const LOCAL_STORAGE_POPUP = 'pickme-popup-closed-at';

function CheeseButtonModal() {
  const [isVisible, setVisible] = useState(false);

  const onClose = () => setVisible(false);

  useEffect(() => {
    const modalClosedAt = window.localStorage.getItem(LOCAL_STORAGE_POPUP);

    if (!moment(modalClosedAt).isValid() || moment().isAfter(modalClosedAt, 'day')) {
      setVisible(true);
      window.localStorage.removeItem(LOCAL_STORAGE_POPUP);
    }
  }, []);

  return (
    <Modal
      size='lg'
      className='cheese-button'
      containerClassName={popup.modal}
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className={popup.header}>
        <PlainButton
          type='button'
          onClick={() => {
            onClose();
          }}
        >
          <img src={CloseImage} alt='close' className={popup.close} />
        </PlainButton>
      </div>

      <div className={popup.body}>
        <Text size={40} mobileSize={30} fontWeight={700}>
          서비스 이름 변경 및<br />
          도메인 이전 안내
        </Text>

        <div className={popup.nameContainer}>
          <Text size={22} mobileSize={18} fontWeight={600} color='primary-500'>
            픽미의 새 이름,
          </Text>
          <img src={CheeseButtonLogo} alt='cheese button' className={popup.logo} />
        </div>

        <Text size={16} mobileSize={14} className={popup.mainText}>
          안녕하세요. 픽미 운영팀입니다. 지난 4년간 픽미를 이용해주신 고객분들께 보답하기 위해
          <b>2024년 10월 14일부터 픽미가 치즈버튼으로 새롭게 출발합니다.</b>
          <br />
          서비스 이름 변경과 함께 대규모 업데이트된 서비스, 치즈버튼을 지금 확인하세요!
        </Text>

        <div>
          <img src={Transfer} alt='pick me > cheese button' className={popup.transferImage} />
          <div className={popup.transferTextContainer}>
            <Text size={12} color='gray-400' className={popup.transferText}>
              www.ohpick.me
            </Text>
            <Text size={12} color='gray-400' className={popup.transferText}>
              www.cheesebutton.io
            </Text>
          </div>
        </div>

        <div className={popup.buttons}>
          <a
            href={`${import.meta.env.VITE_BLOG_URL}/post/공지사항_서비스_이름_변경_및_도메인_이전_안내/`}
            target='_blank'
            rel='noreferrer'
          >
            <Button fullWidth size='xl' className={popup.button}>
              <Text size={16} mobileSize={14} fontWeight={500} color='white'>
                공지사항 확인하기
              </Text>
            </Button>
          </a>

          <a href='https://www.cheesebutton.io/' target='_blank' rel='noreferrer'>
            <Button fullWidth size='xl' variant='black' className={popup.button}>
              <Text size={16} mobileSize={14} fontWeight={500} color='white'>
                치즈버튼 바로가기
              </Text>
            </Button>
          </a>
        </div>
      </div>

      <label htmlFor='save-close' className={popup.footer}>
        <input
          className={popup.checkbox}
          id='save-close'
          type='checkbox'
          value='check'
          onChange={(event) => {
            if (event.target.checked) {
              setClosedAtInStorage();
            }

            onClose();
          }}
        />
        <Text size={14} color='gray-600'>
          오늘 하루 동안 보지 않기
        </Text>
      </label>
    </Modal>
  );
}

export default CheeseButtonModal;

const setClosedAtInStorage = () => {
  window.localStorage.setItem(LOCAL_STORAGE_POPUP, moment().toLocaleString());
};
