import { useMutation, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import {
  createOrderResultRequest,
  createCancelPollRequest,
  createPausePollRequest,
  createRestartPollRequest,
  createRemovePollRequest,
  createPollEarlyExitRequest,
} from 'apis/poll-actions';

import { toast } from 'states/toast';
import { pollViewer } from 'states/poll-viewer';
import { pollActionModal } from 'states/modal';

import {
  sendPollEarlyExitEvent,
  sendPollOrderEvent,
  sendPollPauseEvent,
  sendPollRemoveEvent,
  sendPollRestartEvent,
} from 'functions/analytics/poll';

import { Poll, PollKind, Voter } from 'types/poll';
import type { AxiosError } from 'axios';
import channelTalk from 'functions/channel-talk';
import { useNavigate } from 'react-router-dom';

export const useOrderResult = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ id }: { kind: PollKind; id: string; showToast?: boolean }) => {
      const { data } = await createOrderResultRequest(id);
      return data;
    },
    {
      onSuccess: (_, { kind, id, showToast = true }) => {
        const pollData = queryClient.getQueryData<Poll>(['poll', id]);
        const voters = queryClient.getQueryData<Voter[]>(['poll', id, 'voters']);
        if (pollData) {
          sendPollOrderEvent({
            pollId: id,
            kind,
            plan: pollData.subscriptionType,
            periods: pollData.periods,
            voters: voters?.length ?? 0,
          });
        }

        channelTalk.track('poll count');

        queryClient.invalidateQueries('polls');
        queryClient.invalidateQueries(['poll', id]);
        queryClient.invalidateQueries(['polls-stats']);

        if (showToast) {
          setToast({
            isVisible: true,
            type: 'success',
            message:
              kind === 'Election'
                ? t('admin:query-hooks.poll-actions.useOrderResult.onSuccess.toast.election')
                : t('admin:query-hooks.poll-actions.useOrderResult.onSuccess.toast.survey'),
          });
        }
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const useCancelPoll = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ id }: { kind: PollKind; id: string }) => {
      const { data } = await createCancelPollRequest(id);
      return data;
    },
    {
      onSuccess: (_, { kind, id }) => {
        queryClient.invalidateQueries('polls');
        queryClient.invalidateQueries(['poll', id]);
        queryClient.invalidateQueries(['polls-stats']);

        setToast({
          isVisible: true,
          type: 'success',
          message:
            kind === 'Election'
              ? t('admin:query-hooks.poll-actions.useCancelPoll.onSuccess.toast.election')
              : t('admin:query-hooks.poll-actions.useCancelPoll.onSuccess.toast.survey'),
        });
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const usePausePoll = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ id }: { kind: PollKind; id: string }) => {
      const { data } = await createPausePollRequest(id);
      return data;
    },
    {
      onSuccess: (_, { kind, id }) => {
        const pollData = queryClient.getQueryData<Poll>(['poll', id]);
        if (pollData) {
          sendPollPauseEvent({
            pollId: id,
            kind,
            periods: pollData?.periods ?? [],
          });
        }

        queryClient.invalidateQueries('polls');
        queryClient.invalidateQueries(['poll', id]);
        queryClient.invalidateQueries(['polls-stats']);

        setToast({
          isVisible: true,
          type: 'success',
          message:
            kind === 'Election'
              ? t('admin:query-hooks.poll-actions.usePausePoll.onSuccess.toast.election')
              : t('admin:query-hooks.poll-actions.usePausePoll.onSuccess.toast.survey'),
        });
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const useRestartPoll = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ id }: { kind: PollKind; id: string }) => {
      const { data } = await createRestartPollRequest(id);
      return data;
    },
    {
      onSuccess: (_, { kind, id }) => {
        const pollData = queryClient.getQueryData<Poll>(['poll', id]);
        if (pollData) {
          sendPollRestartEvent({
            pollId: id,
            kind,
            periods: pollData?.periods ?? [],
          });
        }

        queryClient.invalidateQueries('polls');
        queryClient.invalidateQueries(['poll', id]);
        queryClient.invalidateQueries(['polls-stats']);

        setToast({
          isVisible: true,
          type: 'success',
          message:
            kind === 'Election'
              ? t('admin:query-hooks.poll-actions.useRestartPoll.onSuccess.toast.election')
              : t('admin:query-hooks.poll-actions.useRestartPoll.onSuccess.toast.survey'),
        });
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const useEarlyExitPoll = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ id }: { kind: PollKind; id: string }) => {
      const { data } = await createPollEarlyExitRequest(id);
      return data;
    },
    {
      onSuccess: (_, { kind, id }) => {
        const pollData = queryClient.getQueryData<Poll>(['poll', id]);
        if (pollData) {
          sendPollEarlyExitEvent({
            pollId: id,
            kind,
          });
        }

        queryClient.invalidateQueries('polls');
        queryClient.invalidateQueries(['poll', id]);
        queryClient.invalidateQueries(['polls-stats']);

        setToast({
          isVisible: true,
          type: 'success',
          message:
            kind === 'Election'
              ? t('admin:query-hooks.poll-actions.useEarlyExitPoll.onSuccess.toast.election')
              : t('admin:query-hooks.poll-actions.useEarlyExitPoll.onSuccess.toast.survey'),
        });
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};

export const useRemovePoll = () => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);
  const setPollViewer = useSetRecoilState(pollViewer);
  const setPollActionModal = useSetRecoilState(pollActionModal);

  return useMutation(
    async ({ id }: { kind: PollKind; id: string }) => {
      const { data } = await createRemovePollRequest(id);
      return data;
    },
    {
      onSuccess: (_, { kind, id }) => {
        navigate('/', {
          replace: true,
          unstable_viewTransition: true,
        });
        const pollData = queryClient.getQueryData<Poll>(['poll', id]);
        if (pollData) {
          sendPollRemoveEvent({
            pollId: id,
            kind,
            group: pollData.group,
          });
        }

        queryClient.invalidateQueries('polls');
        queryClient.invalidateQueries(['poll', id]);
        queryClient.invalidateQueries(['poll-stats']);

        setPollActionModal({
          isVisible: false,
          kind,
          type: 'remove',
          id: '',
        });

        setPollViewer({
          isVisible: false,
          id: '',
        });

        setToast({
          isVisible: true,
          type: 'success',
          message: t('admin:query-hooks.poll-actions.useRemovePoll.onSuccess.toast'),
        });
      },
      onError: (error: AxiosError) => {
        setToast({
          type: 'error',
          isVisible: true,
          message: error.message,
        });
      },
    },
  );
};
