import { Button, Modal, Pagination, Table } from '@pickme/design-system';
import { useEffect, useState } from 'react';
import { CollaboratorRoleV2, PollStatus } from '@pickme/core';

import Spinner from 'components/common/Spinner';
import PollSearchForm from 'components/features/poll-v2/form/Search';
import TableRow from 'components/features/application-v2/form/Ending/NextContents/Modal/Row';

import { useGetPollSummaries } from 'query-hooks/poll-v2';

import { PollKindForSearchV2, PollSearchGroupV2, PollSearchQueryV2 } from 'types/poll-v2';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  selectedPollIds: string[];
  setSelectedPollIds: (pollIds: string[]) => void;
};

function PollSelectModal({ isVisible, onClose, selectedPollIds, setSelectedPollIds }: Props) {
  const [selectedPollIdsValue, setSelectedPollIdsValue] = useState<string[]>([]);
  const [pollSearchQuery, setPollSearchQuery] =
    useState<PollSearchQueryV2>(DEFAULT_POLL_SEARCH_QUERY);

  const { data, isLoading } = useGetPollSummaries({
    kind: pollSearchQuery.kind,
    groups: pollSearchQuery.groups,
    status: PollStatus.Approved,
    page: pollSearchQuery.page,
    keyword: pollSearchQuery.keyword,
    roles: CollaboratorRoleV2.Editor,
  });

  useEffect(() => {
    if (isVisible) {
      setPollSearchQuery(DEFAULT_POLL_SEARCH_QUERY);
    }
  }, [isVisible]);

  useEffect(() => {
    setSelectedPollIdsValue(selectedPollIds);
  }, [selectedPollIds]);

  return (
    <Modal isOpened={isVisible} onClose={onClose} size='3xl'>
      <Modal.Header showCloseButton>다음 참여 콘텐츠 설정하기</Modal.Header>
      <Modal.Body gap={12}>
        <PollSearchForm
          values={pollSearchQuery}
          onSubmit={(form) => setPollSearchQuery({ ...form, page: 1 })}
        />
        <Table>
          <colgroup>
            <col width={60} />
            <col />
            <col width={120} />
            <col width={120} />
            <col width={80} />
          </colgroup>

          <Table.Header>
            <Table.Tr>
              <Table.Th />
              <Table.Th textAlign='start'>콘텐츠</Table.Th>
              <Table.Th textAlign='center'>참여자 수</Table.Th>
              <Table.Th textAlign='center'>참여설정</Table.Th>
              <Table.Th textAlign='center'>상태</Table.Th>
            </Table.Tr>
          </Table.Header>

          <Table.Body>
            {isLoading && (
              <Table.Tr>
                <Table.Td colSpan={6}>
                  <Spinner />
                </Table.Td>
              </Table.Tr>
            )}

            {data?.contents && data?.contents.length > 0 ? (
              data.contents.map((pollSummary) => (
                <TableRow
                  isSelected={selectedPollIdsValue.includes(pollSummary._id)}
                  pollSummary={pollSummary}
                  onSelect={(selected) => {
                    const pollIds = selectedPollIdsValue.slice();
                    if (selected) {
                      if (pollIds.length === 2) {
                        pollIds.shift();
                      }
                      pollIds.push(pollSummary._id);
                    } else {
                      const index = pollIds.indexOf(pollSummary._id);
                      pollIds.splice(index, 1);
                    }

                    setSelectedPollIdsValue(pollIds);
                  }}
                />
              ))
            ) : (
              <Table.Tr>
                <Table.Td colSpan={5}>표시할 콘텐츠가 없습니다.</Table.Td>
              </Table.Tr>
            )}
          </Table.Body>
        </Table>

        <Pagination
          currentPage={pollSearchQuery.page || 1}
          totalPage={data?.endPage || 1}
          onMove={(targetPage) => {
            setPollSearchQuery({
              ...pollSearchQuery,
              page: targetPage,
            });
          }}
        />
      </Modal.Body>

      <Modal.Action>
        <Button
          size='md'
          fullWidth
          onClick={() => {
            setSelectedPollIds(selectedPollIdsValue);
          }}
        >
          추가하기
        </Button>
      </Modal.Action>
    </Modal>
  );
}

export default PollSelectModal;

const DEFAULT_POLL_SEARCH_QUERY: PollSearchQueryV2 = {
  page: 1,
  keyword: '',
  kind: PollKindForSearchV2.All,
  groups: PollSearchGroupV2.All,
};
