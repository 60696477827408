export enum MessagingKind {
  PollMessaging = 'PollMessaging',
}

export enum MessagingStatus {
  Complete = 'complete',
  Pending = 'pending',
  Sending = 'sending',
}

export enum RecipientType {
  NonVotedVoters = 'nonVotedVoters',
  All = 'all',
}

export type PollMessaging = {
  pollId: string;
  pollName: string;
  recipientType: RecipientType;
  usageAmount: number;
} & Messaging;

export type Messaging = {
  _id: string;
  kind: MessagingKind;
  status: MessagingStatus;
  detail: MessagingDetail;
  senderId: string;
  pollName: string;
  usageAmount: number;
  organizationId: string;
  count: number;
  sendRequestDate: string;
  sendCompletionDate: string;
};

export enum MessagingMethod {
  AlimTalk = 'kakao',
  Lms = 'lms',
  Email = 'email',
}

export type MessagingStatusPerGroup = Record<string, boolean>;

export type TemplateType = '1' | '2' | '3' | '4' | '5';

export type AlimTalkMessagingDetail = {
  type: MessagingMethod.AlimTalk;
  sendResult: {
    kakao: number;
    lms: number;
    failed: number;
  };
  statusPerGroup: MessagingStatusPerGroup;
  customChannelId?: string;
  templateType: TemplateType;
};

export type LmsMessagingDetail = {
  type: MessagingMethod.Lms;
  sendResult: {
    lms: number;
    failed: number;
  };
  statusPerGroup: MessagingStatusPerGroup;
  template: string;
  styledTemplate: string;
  subject: string;
};

export type EmailMessagingDetail = {
  type: MessagingMethod.Email;
  sendResult: {
    email: number;
    failed: number;
  };
  template: string;
  subject: string;
};

export type MessagingDetail = AlimTalkMessagingDetail | LmsMessagingDetail | EmailMessagingDetail;

export type TargetField = {
  pollIds: string[];
  recipientType: RecipientType;
};

export type AlimTalkForm = {
  channelId: string;
  recipientType: RecipientType;
  templateType: TemplateType;
  dateTime?: DateTime;
} & TargetField;

export type AlimTalkSendRequestBody = {
  pollIds: string[];
  recipientType: RecipientType;
  templateType: TemplateType;
  channelId?: string;
  date?: string;
};

export type LmsForm = {
  recipientType: RecipientType;
  template: string;
  styledTemplate: string;
  templateType: TemplateType;
  subject?: string;
  sender?: string;
  dateTime?: DateTime;
} & TargetField;

export type LmsSendRequestBody = {
  pollIds: string[];
  recipientType: RecipientType;
  template: string;
  styledTemplate: string;
  subject?: string;
  date?: string;
};

export type EmailForm = {
  recipientType: RecipientType;
  template: string;
  templateType: TemplateType;
  subject?: string;
  sender?: string;
  dateTime?: DateTime;
} & TargetField;

export type EmailSendRequestBody = {
  pollIds: string[];
  recipientType: RecipientType;
  template: string;
  subject?: string;
  date?: string;
};

export type MessagingSearchQuery = {
  page?: number;
  methodType?: MessagingMethod;
  period?: string;
  status?: MessagingStatus[];
};

export type MessagingSearchForm = {
  page: number;
  methodType: MessagingMethod | 'all';
  month: string | 'all';
  status: MessagingStatus[];
};

export type DateTime = {
  date: string;
  time: string;
};

export enum MessagingType {
  Reserve = 'reserve',
  Send = 'send',
}
