import moment from 'moment-timezone';
import { Text, Callout } from '@pickme/ui';
import { PollGroupBadge, Switch } from '@pickme/design-system';
import ReactHtmlParser from 'html-react-parser';
import { Link } from 'react-router-dom';
import { Plan } from '@pickme/core';
import { t } from 'i18next';
import { isEqual } from 'lodash-es';

import SubscriptionBadge from 'components/features/badge/SubscriptionBadge';
import MemberLevelBadge from 'components/features/badge/MemberLevel';

import ElectionCard from 'components/features/poll/PollDetail/ElectionCard';
import QuestionCard from 'components/features/poll/PollDetail/QuestionCard';
import InstantVoterCard from 'components/features/poll/PollDetail/InstantVoterCard';
import VoterCard from 'components/features/poll/PollDetail/VoterCard';

import { Poll } from 'types/poll';
import { VoterAuthMethod } from 'types/application';

import './index.scss';

type Props = {
  poll?: Poll;
  onClose?: () => void;
};

function PollDetail({ poll, onClose }: Props) {
  const pollKindText =
    poll?.kind === 'Election'
      ? t('admin:terms.poll.kind.election')
      : t('admin:terms.poll.kind.survey');

  return (
    <div className='poll-detail'>
      <div className='poll-detail__section' id='additional'>
        <div className='poll-detail__section__body poll-detail__section__card'>
          <div className='poll-detail__row poll-detail__row--justify'>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {t('admin:terms.space.plan')}
            </Text>{' '}
            <SubscriptionBadge type={poll?.subscriptionType || Plan.Free} />
          </div>

          <div className='poll-viewer__row poll-viewer__row--justify'>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {t('admin:components.features.poll.pollDetail.detail.additional.status')}
            </Text>

            {poll && <PollGroupBadge group={poll.group} />}
          </div>

          <div className='poll-viewer__row poll-viewer__row--justify'>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {t('admin:components.features.poll.pollDetail.detail.additional.admin')}
            </Text>

            <div className='poll-detail__row'>
              {poll && poll.author ? (
                <>
                  {poll?.author?.level && <MemberLevelBadge level={poll.author.level} />}

                  <Text type='b3' fontWeight={500}>
                    {poll?.author?.name ?? ''} {poll?.author?.email ?? ''}
                  </Text>
                </>
              ) : (
                <Text type='b3' fontWeight={500}>
                  탈퇴한 유저입니다
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='poll-detail__section'>
        <div className='poll-detail__section__header'>
          <Text type='b1' fontWeight={600}>
            {t('admin:components.features.poll.pollDetail.detail.basic.title')}
          </Text>
        </div>

        <div className='poll-detail__section__body poll-detail__section__card'>
          <div className='poll-detail__row poll-detail__row--justify'>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {pollKindText}{' '}
              {t('admin:components.features.poll.pollDetail.detail.basic.period.label')}
            </Text>

            <div>
              {poll?.periods.map(({ startDate, endDate }) => {
                const formattedStartDate = moment(startDate)
                  .tz('Asia/Seoul')
                  .format('YYYY-MM-DD (ddd) HH:mm');
                const formattedEndDate = moment(endDate)
                  .tz('Asia/Seoul')
                  .format('YYYY-MM-DD (ddd) HH:mm');

                return (
                  <Text type='b3' fontWeight={500} key={`${startDate}${endDate}`}>
                    {t('admin:components.features.poll.pollDetail.detail.basic.period.value', {
                      startDate: formattedStartDate,
                      endDate: formattedEndDate,
                    })}
                  </Text>
                );
              })}
            </div>
          </div>

          <div className='poll-detail__section__body__field'>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {pollKindText}{' '}
              {t('admin:components.features.poll.pollDetail.detail.basic.content.label')}
            </Text>
            <div className='poll-detail__description'>
              {ReactHtmlParser(
                poll?.description ||
                  t('admin:components.features.poll.pollDetail.detail.basic.content.blank'),
              )}
            </div>
          </div>

          {poll?.coverImageFileUrl && (
            <div className='poll-detail__section__body__field'>
              <Text type='b3' fontWeight={400} color='gray-400'>
                {t('admin:components.features.poll.pollDetail.detail.basic.cover.label')}
              </Text>

              <div className='poll-detail__cover'>
                <img src={poll.coverImageFileUrl} alt='cover' />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='poll-detail__section' id='polls'>
        <div className='poll-detail__section__header'>
          <Text type='b1' fontWeight={600}>
            {t('admin:components.features.poll.pollDetail.detail.poll.title')}
          </Text>
        </div>

        <div className='poll-detail__section__body'>
          {poll?.kind === 'Election'
            ? poll?.subElections?.map((election) => (
                <ElectionCard key={election._id} election={election} />
              ))
            : poll?.questions?.map((question) => (
                <QuestionCard key={question._id} question={question} />
              ))}
        </div>
      </div>

      <div className='poll-detail__section' id='voter'>
        <div className='poll-detail__section__header'>
          <Text type='b1' fontWeight={600}>
            {t('admin:components.features.poll.pollDetail.detail.voter.title')}
          </Text>
        </div>

        {poll?.hasInstantVoter && ['ready', 'ongoing', 'ended'].includes(poll.group) && (
          <Callout
            id='instantVoterbook'
            bulletStyle='none'
            variant='primary'
            sentences={[t('admin:components.features.poll.pollDetail.detail.voter.instantVoter.0')]}
          />
        )}

        {poll?.voterbookExpiry && (
          <Callout
            id='discard'
            bulletStyle='none'
            variant={poll?.isVoterBookDeleted ? 'error' : 'warning'}
            sentences={[
              <Text type='b3' fontWeight={400}>
                {poll?.isVoterBookDeleted
                  ? t('admin:components.features.poll.pollDetail.detail.voter.voterBook.deleted', {
                      date: moment(poll.voterbookExpiry)
                        .tz('Asia/Seoul')
                        .format(
                          t(
                            'admin:components.features.poll.pollDetail.detail.voter.voterBook.format',
                          ),
                        ),
                    })
                  : t(
                      'admin:components.features.poll.pollDetail.detail.voter.voterBook.notDeleted',
                      {
                        date: moment(poll.voterbookExpiry)
                          .tz('Asia/Seoul')
                          .format(
                            t(
                              'admin:components.features.poll.pollDetail.detail.voter.voterBook.format',
                            ),
                          ),
                        prefix: poll.hasInstantVoter
                          ? t(
                              'admin:components.features.poll.pollDetail.detail.voter.voterBook.target',
                            )
                          : '',
                      },
                    )}
                <Link
                  to='/settings/poll/discard-data'
                  onClick={() => {
                    if (onClose) {
                      onClose();
                    }
                  }}
                >
                  <b>{t('admin:components.features.poll.pollDetail.detail.voter.remove')}</b>
                </Link>
              </Text>,
            ]}
          />
        )}

        {poll?.hasInstantVoter ? (
          <InstantVoterCard
            pollId={poll?._id || ''}
            pollName={poll?.name ?? ''}
            pollGroup={poll?.group}
            isDownloadButtonVisible={!poll?.isVoterBookDeleted}
          />
        ) : (
          <VoterCard
            pollId={poll?._id || ''}
            pollName={poll?.name ?? ''}
            pollGroup={poll?.group}
            isDownloadButtonVisible={!poll?.isVoterBookDeleted}
          />
        )}
      </div>

      <div className='poll-detail__section' id='additional'>
        <div className='poll-detail__section__header'>
          <Text type='b1' fontWeight={600}>
            {t('admin:components.features.poll.pollDetail.detail.setting.title')}
          </Text>
        </div>

        <div className='poll-detail__section__body poll-detail__section__card'>
          <div className='poll-detail__row poll-detail__row--justify'>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {t('admin:components.features.poll.pollDetail.detail.setting.authMethods.label')}
            </Text>
            <Text type='b3' fontWeight={500}>
              {parseVoterAuthMethods(poll?.voterAuthMethods ?? [])}
            </Text>
          </div>

          {poll?.kind === 'Election' && (
            <div className='poll-detail__row poll-detail__row--justify'>
              <Text type='b3' fontWeight={400} color='gray-400'>
                {t('admin:components.features.poll.pollDetail.detail.setting.countable')}
              </Text>
              <Text type='b3' fontWeight={500}>
                {poll?.openThreshold ?? 0}%
              </Text>
            </div>
          )}

          <div className='poll-detail__row poll-detail__row--justify'>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {poll?.hasInstantVoter
                ? t(
                    'admin:components.features.poll.pollDetail.detail.setting.realTime.instantVoter',
                  )
                : t('admin:components.features.poll.pollDetail.detail.setting.realTime.rate')}
            </Text>

            <Switch checked={poll?.isOpenPollRate} disabled />
          </div>

          <div className='poll-detail__row poll-detail__row--justify'>
            <Text type='b3' fontWeight={400} color='gray-400'>
              {t('admin:components.features.poll.pollDetail.detail.setting.count')}
            </Text>

            <Switch checked={poll?.isOpenPollResult} disabled />
          </div>

          {poll?.kind === 'Survey' && (
            <div className='poll-detail__row poll-detail__row--justify'>
              <Text type='b3' fontWeight={400} color='gray-400'>
                {t('admin:components.features.poll.pollDetail.detail.setting.result')}
              </Text>

              <Switch checked={poll.allowRealTimeResult} disabled />
            </div>
          )}

          {poll?.kind === 'Survey' && (
            <div className='poll-detail__row poll-detail__row--justify'>
              <Text type='b3' fontWeight={400} color='gray-400'>
                {t('admin:components.features.poll.pollDetail.detail.setting.openVotes')}
              </Text>

              <Switch checked={poll.hasOpenVotes} disabled />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PollDetail;

function parseVoterAuthMethods(authMethods: VoterAuthMethod[]) {
  if (isEqual(authMethods, [VoterAuthMethod.Email])) {
    return t('admin:components.features.poll.pollDetail.detail.setting.authMethods.email');
  }

  if (isEqual(authMethods, [VoterAuthMethod.Phone])) {
    return t('admin:components.features.poll.pollDetail.detail.setting.authMethods.phone');
  }

  return t('admin:components.features.poll.pollDetail.detail.setting.authMethods.all');
}
