import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { PlainButton, Text } from '@pickme/design-system';

import BackIcon from 'resources/icons/nav/back.svg';

import { navbar, main, outlet } from './index.css';
import UserMenu from '../MainV2/Navbar/UserMenu';

type Props = {
  title?: string;
  to?: string;
  showUserMenu?: boolean;
};

function NavigateBackLayout({ title, to, showUserMenu }: Props) {
  const navigate = useNavigate();
  const [customNavigate, setCustomNavigate] = useState<null | Function>(null);

  return (
    <main className={main}>
      <header className={navbar.container}>
        <PlainButton
          onClick={() => {
            if (typeof customNavigate !== null && !!customNavigate) {
              customNavigate();
              return;
            }

            if (to) {
              navigate(to);
              return;
            }
            navigate(-1);
          }}
        >
          <img src={BackIcon} alt='back' />
        </PlainButton>

        {title && (
          <Text size={14} fontWeight={500}>
            {title}
          </Text>
        )}

        {showUserMenu && <UserMenu disabledToShowFeedback />}
      </header>

      <div className={outlet}>
        <Outlet context={{ setCustomNavigate }} />
      </div>
    </main>
  );
}

export default NavigateBackLayout;
