import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { Text } from '@pickme/design-system';

import ProgressBar from 'components/common/ProgressBar';
import OrganizationNameForm from 'components/features/channel-v2/form/create/Name';
import OrganizationAliasForm from 'components/features/channel-v2/form/create/Alias';
import OrganizationImagesForm from 'components/features/channel-v2/form/create/Images';
import OrganizationCreateComplete from 'components/features/channel-v2/form/create/Complete';

import { useCreateChannel } from 'query-hooks/channel-v2';

import { conditionalTransitionCallback } from 'functions/dom';

import { COVER_GALLERY_IMAGES } from 'constants/cover-gallery';

import { ChannelCreateForm, ChannelFormStep } from 'types/channel-v2';

import { container, stepStyle, title } from 'pages/auth/common.css';
import style from './index.css';

function ChannelCreatePageV2() {
  const { setCustomNavigate } = useOutletContext<{
    setCustomNavigate: (customNavigate: null | Function) => void;
  }>();

  const channelFormRef = useRef<HTMLFormElement>(null);
  const [step, setStep] = useState<ChannelFormStep>(ChannelFormStep.Name);

  useEffect(() => {
    if (step === ChannelFormStep.Alias) {
      setCustomNavigate(() => () => setStep(ChannelFormStep.Name));
      return;
    }

    if (step === ChannelFormStep.Image) {
      setCustomNavigate(() => () => setStep(ChannelFormStep.Alias));
      return;
    }

    setCustomNavigate(null);
  }, [step]);

  const { mutate, isLoading, isSuccess, data: channel } = useCreateChannel();

  const channelForm = useForm<ChannelCreateForm>({
    mode: 'onTouched',
    defaultValues: {
      name: '',
      alias: '',
    },
  });

  const onSubmit = (data: ChannelCreateForm) => {
    if (isLoading) {
      return;
    }

    const coverImage = data.cover || COVER_GALLERY_IMAGES[Math.floor(Math.random() * 14)].url;

    mutate({ ...data, cover: coverImage });
  };

  const ChannelFormSteps = Object.values(ChannelFormStep);
  const currentStepIndex = ChannelFormSteps.findIndex((formStep) => formStep === step) + 1;

  useEffect(() => {
    if (isSuccess) {
      setStep(ChannelFormStep.Complete);
    }
  }, [isSuccess]);

  return (
    <div className={container}>
      <ProgressBar total={ChannelFormSteps.length} now={currentStepIndex} />

      <Text size={16} color='black' className={stepStyle}>
        Step {currentStepIndex}.
      </Text>

      <div className={title}>
        <Text size={30} mobileSize={24} fontWeight={500} color='black' className={style.title}>
          {STEP_TITLES[step].title}
        </Text>

        {!STEP_TITLES[step].required && (
          <Text
            size={26}
            mobileSize={20}
            fontWeight={500}
            color='gray-400'
            className={style.optionalText}
          >
            (선택)
          </Text>
        )}
      </div>

      {step === ChannelFormStep.Name && (
        <OrganizationNameForm
          name={channelForm.watch('name')}
          onNext={(name) => {
            conditionalTransitionCallback(() => {
              setStep(ChannelFormStep.Alias);
              channelForm.setValue('name', name);
            });
          }}
        />
      )}

      {step === ChannelFormStep.Alias && (
        <OrganizationAliasForm
          alias={channelForm.watch('alias')}
          onNext={(alias) => {
            conditionalTransitionCallback(() => {
              setStep(ChannelFormStep.Image);
              channelForm.setValue('alias', alias);
            });
          }}
        />
      )}

      {step === ChannelFormStep.Image && (
        <OrganizationImagesForm
          isLoading={isLoading}
          onNext={({ logo, cover }) => {
            conditionalTransitionCallback(() => {
              if (logo) {
                channelForm.setValue('logo', logo);
              }

              if (cover) {
                channelForm.setValue('cover', cover);
              }

              channelFormRef.current?.requestSubmit();
            });
          }}
        />
      )}

      {step === ChannelFormStep.Complete && (
        <OrganizationCreateComplete
          id={channel?._id || ''}
          name={channel?.name || ''}
          alias={channel?.alias || ''}
          logo={channel?.logo || ''}
          cover={channel?.cover || ''}
        />
      )}

      <FormProvider {...channelForm}>
        <form onSubmit={channelForm.handleSubmit(onSubmit)} ref={channelFormRef}>
          <input hidden {...channelForm.register('name')} />
          <input hidden {...channelForm.register('alias')} />
        </form>
      </FormProvider>
    </div>
  );
}

export default ChannelCreatePageV2;

const STEP_TITLES = {
  [ChannelFormStep.Name]: { title: '채널의 이름을 입력해 주세요', required: true },
  [ChannelFormStep.Alias]: { title: '채널의 주소를 만들어 주세요', required: true },
  [ChannelFormStep.Image]: {
    title: '채널의 프로필 및 커버이미지를 등록해 주세요',
    required: false,
  },
  [ChannelFormStep.Complete]: { title: '채널이 개설되었어요!', required: true },
};
