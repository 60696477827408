import { PollKind } from '@pickme/core';
import { Spinner } from '@pickme/ui';
import { Button, Modal, OutlineButton, Text } from '@pickme/design-system';

import { PeriodGroup } from 'models/application-v2/common/period-group';

import { PeriodGroup as PeriodGroupType } from 'types/application-v2';
import { PollKindV2 } from 'types/poll-v2';

import { container, label, periods, row } from './index.css';

type Props = {
  isVisible: boolean;
  isLoading: boolean;
  kind: PollKindV2 | PollKind;
  periodGroups: PeriodGroupType[];
  onClose: () => void;
  onSubmit: () => void;
};

const TITLE = {
  [PollKindV2.CandidateElection]: '선거를 예약하시겠어요?',
  [PollKindV2.IssueElection]: '투표를 예약하시겠어요?',
  [PollKindV2.Survey]: '설문을 예약하시겠어요?',
};

const SUBTITLE = {
  [PollKindV2.CandidateElection]: '다음 일정으로 선거를 예약하시겠어요?',
  [PollKindV2.IssueElection]: '다음 일정으로 투표를 예약하시겠어요?',
  [PollKindV2.Survey]: '다음 일정으로 설문을 예약하시겠어요?',
};

function ReserveModal({ kind, isVisible, isLoading, periodGroups, onClose, onSubmit }: Props) {
  return (
    <Modal isOpened={isVisible} onClose={onClose} size='sm'>
      <Modal.Header showCloseButton>{TITLE[kind]}</Modal.Header>

      <Modal.Body className={container}>
        <Text size={16} fontWeight={400} color='gray-700'>
          {SUBTITLE[kind]}
        </Text>

        <div className={periods}>
          {periodGroups.map((periodGroupValue, index) => {
            const periodGroup = new PeriodGroup(periodGroupValue.values);

            return (
              <div key={index} className={row}>
                <div className={label}>
                  <Text size={14} fontWeight={500} color='blue-gray-500'>
                    기간 {index + 1}
                  </Text>
                </div>
                <Text size={14} fontWeight={400} color='gray-700'>
                  {periodGroup.getPeriodExpression('YYYY년 MM월 DD일 HH:mm')}
                </Text>
              </div>
            );
          })}
        </div>
      </Modal.Body>

      <Modal.Action justifyEnd>
        <OutlineButton variant='gray-200' onClick={() => onClose()}>
          닫기
        </OutlineButton>
        <Button width={144} variant='primary' onClick={onSubmit} disabled={isLoading}>
          {isLoading ? <Spinner /> : '예약하기'}
        </Button>
      </Modal.Action>
    </Modal>
  );
}

export default ReserveModal;
