import * as yup from 'yup';
import { isNaN, isNumber } from 'lodash-es';

import { Question, SurveyPage, SurveyTypeCase } from 'types/application-v2';
import {
  ANSWER_MAX_LENGTH,
  MULTIPLE_SELECTION_ALLOWED_SURVEY_TYPE,
  QUESTION_DESCRIPTION_MAX_LENGTH,
  QUESTION_TITLE_MAX_LENGTH,
  RANK_WEIGHT_MAX_VALUE,
  RANK_WEIGHT_MIN_VALUE,
  SCALE_MAX_VALUE,
  SCALE_MIN_VALUE,
  SCORE_MAX_VALUE,
  SCORE_MIN_VALUE,
  SURVEY_PAGE_DESCRIPTION_MAX_LENGTH,
  SURVEY_PAGE_TITLE_MAX_LENGTH,
  SHOULD_HAVE_ANSWER_SURVEY_TYPE,
  SHOULD_HAVE_TEXT_IN_ANSWER_SURVEY_TYPE,
  SHOULD_HAVE_IMAGE_IN_ANSWER_SURVEY_TYPE,
  SHOULD_HAVE_VIDEO_IN_ANSWER_SURVEY_TYPE,
  STAR_TOTAL_MAX_VALUE,
  STAR_TOTAL_MIN_VALUE,
  SHOULD_HAVE_UNIQUE_ANSWER_VALUE,
} from 'constants/application-v2';

export const getSurveyFormSchema = (): yup.ObjectSchema<{
  pages: SurveyPage[];
}> =>
  yup.object().shape({
    pages: yup
      .array()
      .of(
        yup.object({
          id: yup.string().optional(),
          title: yup
            .string()
            .max(
              SURVEY_PAGE_TITLE_MAX_LENGTH,
              `페이지 제목은 ${SURVEY_PAGE_TITLE_MAX_LENGTH}자 이하로 입력해 주세요.`,
            )
            .required('선거 제목을 입력해 주세요.'),
          description: yup
            .string()
            .optional()
            .max(
              SURVEY_PAGE_DESCRIPTION_MAX_LENGTH,
              `페이지 설명은 ${SURVEY_PAGE_DESCRIPTION_MAX_LENGTH}자 이하로 입력해 주세요.`,
            ),
          questions: yup
            .array()
            .of(questionSchema)
            .required('질문을 1개 이상 등록해주세요')
            .min(1, '질문을 1개 이상 등록해주세요'),
        }),
      )
      .required('설문 페이지를 1개 이상 등록해주세요')
      .min(1, '설문 페이지를 1개 이상 등록해주세요'),
  });

const questionSchema: yup.ObjectSchema<Question> = yup.object({
  id: yup.string().required(),
  type: yup.mixed<SurveyTypeCase>().oneOf(Object.values(SurveyTypeCase)).required(),
  title: yup.string().when('type', (type, schema) => {
    if (type[0] === SurveyTypeCase.개인정보수집) {
      return schema.strip();
    }

    return schema
      .required('질문 제목을 입력해 주세요')
      .max(
        QUESTION_TITLE_MAX_LENGTH,
        `질문 제목은 ${QUESTION_TITLE_MAX_LENGTH}자 이하로 입력해 주세요.`,
      );
  }),
  description: yup
    .string()
    .optional()
    .max(
      QUESTION_DESCRIPTION_MAX_LENGTH,
      `질문 설명은 ${QUESTION_DESCRIPTION_MAX_LENGTH}자 이하로 입력해 주세요.`,
    ),
  imageUrl: yup.string().url().strip(),
  answers: yup.array().when('type', (type, schema) => {
    if (!SHOULD_HAVE_ANSWER_SURVEY_TYPE.includes(type[0])) {
      return schema.strip();
    }

    return schema
      .of(
        yup.object({
          id: yup.string().required(),
          value: yup
            .string()
            .max(ANSWER_MAX_LENGTH, `항목은 ${ANSWER_MAX_LENGTH}자 이하로 입력해 주세요.`)
            .test('conditional', '항목을 입력해 주세요', (value) => {
              if (!SHOULD_HAVE_TEXT_IN_ANSWER_SURVEY_TYPE.includes(type[0])) {
                return true;
              }

              return !!value;
            }),
          imageUrl: yup
            .string()
            .url('이미지 URL을 입력해 주세요')
            .test('conditional', '이미지 URL을 입력해 주세요', (value) => {
              if (!SHOULD_HAVE_IMAGE_IN_ANSWER_SURVEY_TYPE.includes(type[0])) {
                return true;
              }

              return !!value;
            }),
          videoUrl: yup
            .string()
            .url('동영상 URL을 입력해 주세요')
            .test('conditional', '동영상 URL을 입력해 주세요', (value) => {
              if (!SHOULD_HAVE_VIDEO_IN_ANSWER_SURVEY_TYPE.includes(type[0])) {
                return true;
              }

              return !!value;
            }),
        }),
      )
      .required('항목을 1개 이상 입력해 주세요')
      .min(1, '항목을 1개 이상 입력해 주세요')
      .test('unique-values', '항목들이 중복될 수 없습니다.', (answers, context) => {
        if (!SHOULD_HAVE_UNIQUE_ANSWER_VALUE.includes(type[0])) {
          return true;
        }
        if (!answers || answers.length === 0) return true;

        // value의 중복 여부를 체크
        const values = answers.map((answer) => answer.value);
        const duplicates: number[] = [];

        // 중복 체크 로직
        values.forEach((value, index) => {
          if (values.indexOf(value) !== index) {
            duplicates.push(index); // 중복된 항목의 인덱스 저장
          }
        });

        if (duplicates.length > 0) {
          const lastDuplicateIndex = duplicates[duplicates.length - 1]; // 마지막 중복 인덱스

          return context.createError({
            type: 'unique-values',
            path: `${context.path}.${lastDuplicateIndex}.value`,
            message: '항목들이 중복될 수 없습니다.',
          });
        }

        return true;
      });
  }),
  allowMultipleSelectionEnabled: yup.boolean().optional(),
  allowMultipleSelectionMinValue: yup
    .number()
    .when(['type', 'allowMultipleSelectionEnabled'], ([type, enabled], schema) => {
      if (!enabled || !MULTIPLE_SELECTION_ALLOWED_SURVEY_TYPE.includes(type)) {
        return schema.strip();
      }

      return schema.required('최소값을 입력해 주세요').min(1, '최소값을 입력해 주세요');
    })
    .test('min-less-than-max', '최소값은 최대값 보다 작아야 합니다.', (value, context) => {
      const { type, allowMultipleSelectionMaxValue, allowMultipleSelectionEnabled } =
        context.parent;
      if (
        !allowMultipleSelectionEnabled ||
        !MULTIPLE_SELECTION_ALLOWED_SURVEY_TYPE.includes(type)
      ) {
        return true;
      }

      if (!value) {
        return false;
      }

      return value <= allowMultipleSelectionMaxValue;
    }),
  allowMultipleSelectionMaxValue: yup
    .number()
    .when(['type', 'allowMultipleSelectionEnabled'], ([type, enabled], schema) => {
      if (!enabled || !MULTIPLE_SELECTION_ALLOWED_SURVEY_TYPE.includes(type)) {
        return schema.strip();
      }

      return schema.required('최대값을 입력해 주세요').min(1, '최대값을 입력해 주세요');
    })
    .test('max-greater-than-min', '최소값은 최대값 보다 작아야 합니다.', (value, context) => {
      const { type, allowMultipleSelectionEnabled, answers } = context.parent;
      if (
        !allowMultipleSelectionEnabled ||
        !MULTIPLE_SELECTION_ALLOWED_SURVEY_TYPE.includes(type)
      ) {
        return true;
      }

      if (!value) {
        return false;
      }

      return value <= answers?.length;
    }),
  minScore: yup
    .number()
    .typeError('최소 점수를 입력해 주세요')
    .when(['type'], ([type], schema) => {
      if (type !== SurveyTypeCase.점수평가) {
        return schema.strip();
      }

      return schema
        .required('최소 점수를 입력해 주세요')
        .typeError('최소 점수를 입력해 주세요')
        .min(SCORE_MIN_VALUE, `최소 ${SCORE_MIN_VALUE}점 이상 입력해 주세요.`)
        .max(SCORE_MAX_VALUE, `최대 ${SCORE_MAX_VALUE}점 이하로 입력해 주세요.`)
        .test('min-less-than-max', '최소 점수는 최대 점수보다 작아야 합니다.', (value, context) => {
          const { maxScore } = context.parent;
          if (!maxScore) {
            return true;
          }

          return value < maxScore;
        });
    }),
  maxScore: yup.number().when(['type', 'minScore'], ([type], schema) => {
    if (type !== SurveyTypeCase.점수평가) {
      return schema.strip();
    }

    return schema
      .typeError('최대 점수를 입력해 주세요')
      .required('최대 점수를 입력해 주세요')

      .min(SCORE_MIN_VALUE, `최소 ${SCORE_MIN_VALUE}점 이상 입력해 주세요.`)
      .max(SCORE_MAX_VALUE, `최대 ${SCORE_MAX_VALUE}점 이하로 입력해 주세요.`);
  }),
  rank: yup.number().when('type', (type, schema) => {
    if (type[0] === SurveyTypeCase.선호도평가) {
      return schema
        .required('순위를 입력해 주세요')
        .min(1, '순위를 입력해 주세요')
        .test(
          'rank-less-than-candidate-count',
          '순위는 항목 수보다 작아야 합니다.',
          (value, context) => {
            const { answers } = context.parent;

            return value <= answers.length;
          },
        );
    }

    return schema.strip();
  }),
  rankWeights: yup
    .array()
    .of(yup.number().optional())
    .when('type', (type, schema) => {
      if (type[0] !== SurveyTypeCase.선호도평가) {
        return schema.strip();
      }

      return schema
        .required('순위 점수를 입력해 주세요')
        .test(
          'rank-weight-count-equal-rank',
          '순위 점수의 개수와 순위의 개수가 같아야 합니다.',
          (rankWeights, context) => {
            const { rank } = context.parent;

            let path = '';
            let message = '';
            rankWeights.forEach((rankWeight, index) => {
              if (!rankWeight || typeof rankWeight !== 'number' || isNaN(rankWeight)) {
                if (!path && !message) {
                  path = `${context.path}.${index}`;
                  message = '순위 점수를 입력해 주세요.';
                  return;
                }
              }

              if (typeof rankWeight !== 'number') {
                if (!path && !message) {
                  path = `${context.path}.${index}`;
                  message = '순위 점수를 입력해 주세요.';
                  return;
                }
                return;
              }

              if (rankWeight && rankWeight < RANK_WEIGHT_MIN_VALUE) {
                if (!path && !message) {
                  path = `${context.path}.${index}`;
                  message = `${RANK_WEIGHT_MIN_VALUE}에서 ${RANK_WEIGHT_MAX_VALUE} 사이의 값을 입력해 주세요.`;
                }
              }

              if (rankWeight && rankWeight > RANK_WEIGHT_MAX_VALUE) {
                if (!path && !message) {
                  path = `${context.path}.${index}`;
                  message = `${RANK_WEIGHT_MIN_VALUE}에서 ${RANK_WEIGHT_MAX_VALUE} 사이의 값을 입력해 주세요.`;
                }
              }
            });

            if (path && message) {
              return context.createError({
                path,
                message,
              });
            }

            if (rankWeights.length !== rank) {
              return context.createError({
                path: 'rankWeights',
                message: '순위 점수의 개수와 순위의 개수가 같아야 합니다.',
              });
            }

            for (let i = 1; i < rankWeights.length; i += 1) {
              const prevValue = rankWeights[i - 1];
              const currentValue = rankWeights[i];
              if (isNumber(prevValue) && isNumber(currentValue)) {
                if (currentValue > prevValue) {
                  return context.createError({
                    path: `${context.path}.${i}`,
                    message: '순위 점수는 이전 값보다 크지 않아야 합니다.',
                  });
                }
              }
            }

            return true;
          },
        );
    }),
  scaleMinLabel: yup.string().optional(),
  scaleMaxLabel: yup.string().optional(),
  scaleMinValue: yup.number().when('type', (type, schema) => {
    if (type[0] !== SurveyTypeCase.선형배율) {
      return schema.strip();
    }

    return schema
      .required('최소값을 입력해 주세요')
      .min(SCALE_MIN_VALUE, `최소 ${SCALE_MIN_VALUE} 이상 입력해 주세요.`)
      .max(SCALE_MAX_VALUE, `최대 ${SCALE_MAX_VALUE} 이하로 입력해 주세요.`);
  }),
  scaleMaxValue: yup.number().when('type', (type, schema) => {
    if (type[0] !== SurveyTypeCase.선형배율) {
      return schema.strip();
    }

    return schema
      .required('최소값을 입력해 주세요')
      .min(SCALE_MIN_VALUE, `최소 ${SCALE_MIN_VALUE} 이상 입력해 주세요.`)
      .max(SCALE_MAX_VALUE, `최대 ${SCALE_MAX_VALUE} 이하로 입력해 주세요.`)
      .test('min-less-than-max', '최대값은 최소값보다 커야 합니다.', (value, context) => {
        const { scaleMinValue } = context.parent;
        return value > scaleMinValue;
      });
  }),
  starTotal: yup.number().when('type', (type, schema) => {
    if (type[0] !== SurveyTypeCase.별점) {
      return schema.strip();
    }

    return schema
      .required('별점 개수를 입력해 주세요')
      .min(STAR_TOTAL_MIN_VALUE, '별점 개수를 입력해 주세요')
      .max(STAR_TOTAL_MAX_VALUE, '별점 개수를 입력해 주세요');
  }),
  starUnit: yup.number().when('type', (type, schema) => {
    if (type[0] !== SurveyTypeCase.별점) {
      return schema.strip();
    }

    return schema
      .required('별점 단위를 입력해 주세요')
      .oneOf([0.5, 1], '별점 단위를 입력해 주세요');
  }),
  isLong: yup.boolean().optional(),
  shuffle: yup.boolean().optional(),
  required: yup.boolean().optional(),
  privacyAgreementsItems: yup.string().when('type', (type, schema) => {
    if (type[0] !== SurveyTypeCase.개인정보수집) {
      return schema.strip();
    }

    return schema.required('수집하는 개인 정보 항목을 입력해 주세요');
  }),
  privacyAgreementsPurpose: yup.string().when('type', (type, schema) => {
    if (type[0] !== SurveyTypeCase.개인정보수집) {
      return schema.strip();
    }

    return schema.required('수집 및 이용 목적을 입력해 주세요');
  }),
  privacyAgreementsPeriod: yup.string().when('type', (type, schema) => {
    if (type[0] !== SurveyTypeCase.개인정보수집) {
      return schema.strip();
    }

    return schema.required('보유 및 이용 기간을 입력해 주세요');
  }),
});
