import * as yup from 'yup';

import { PeriodActionType, VoterType } from 'types/application-v2';

export const getSettingsFormSchema = () =>
  yup.object().shape({
    voterType: yup
      .mixed<VoterType>()
      .oneOf([VoterType.All, VoterType.Password, VoterType.VoterBook, VoterType.OTP])
      .required('Voter type is required'),
    passCode: yup.string().when('voterType', (voterType, schema) => {
      if (voterType[0] === VoterType.Password) {
        return schema
          .required('참여코드를 입력해주세요.')
          .min(4, '참여코드는 4자 이상이어야 합니다.')
          .max(8, '참여코드는 8자 이하여야 합니다.');
      }

      return schema.notRequired();
    }),
    voterSeats: yup.string().when('voterType', (voterType, schema) => {
      if (voterType[0] === VoterType.OTP) {
        return schema
          .required('최대 참여 인원수를 입력해주세요.')
          .min(0, '최대 참여 인원수를 입력해주세요.');
      }

      return schema.notRequired();
    }),
    authMethods: yup.array().when('voterType', (voterType, schema) => {
      if (voterType[0] === VoterType.OTP) {
        return schema.required('참여자 인증 수단을 선택해주세요.');
      }

      return schema.notRequired();
    }),
    periods: yup.object().shape({
      type: yup
        .mixed<PeriodActionType>()
        .oneOf([PeriodActionType.Instantly, PeriodActionType.Custom])
        .required(),
      groups: yup.array().when('type', (periodActionType, schema) => {
        if (periodActionType[0] === PeriodActionType.Instantly) {
          return schema.notRequired();
        }

        return schema
          .of(
            yup.object().shape({
              id: yup.string().optional(),
              values: yup
                .array()
                .of(
                  yup.object().shape({
                    startDate: yup.date().required(),
                    endDate: yup
                      .date()
                      .required()
                      .test(
                        'startDate',
                        '시작 시간보다 늦은 시간을 입력해주세요',
                        (endDate, { parent }) => endDate > parent.startDate,
                      ),
                  }),
                )
                .min(1, '기간을 입력해주세요'),
            }),
          )
          .min(1, '기간을 입력해주세요');
      }),
    }),
    openVoteCounts: yup.boolean(),
    preventDuplicatedVote: yup.boolean(),
    allowRealtimeResult: yup.boolean(),
    allowAnonymousVote: yup.boolean(),
  });
