import { forwardRef, InputHTMLAttributes, Ref } from 'react';
import { colors, IconButton, Tooltip } from '@pickme/design-system';
import clsx from 'clsx';

import CircularCloseIcon from 'resources/icons/application-v2/circular-close.svg';
import ErrorIcon from 'resources/icons/application-v2/error-indicator.svg';

import {
  button,
  container,
  indicatorButton,
  indicatorIcon,
  input,
  showIconAlwaysStyle,
  tooltip,
} from './index.css';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  isValid?: boolean;
  borderColor?: (typeof colors)['primary-500'] | (typeof colors)['blue-gray-400'];
  showIconAlways?: boolean;
  ignorePadding?: boolean;
  onButtonClick?: () => void;
  isButtonDisabled?: boolean;
  errorMessage?: string;
};

function Input(
  {
    showIconAlways,
    borderColor = colors['primary-500'],
    ignorePadding,
    isValid = true,
    errorMessage,
    ...props
  }: Props,
  ref: Ref<HTMLInputElement>,
) {
  return (
    <div
      className={clsx(
        container({
          borderColor,
          isValid,
        }),
        {
          [showIconAlwaysStyle]: showIconAlways,
        },
      )}
    >
      <input {...props} className={clsx(props.className, input)} ref={ref} />

      {!isValid && (
        <Tooltip message={errorMessage || ''} className={tooltip}>
          <button type='button' className={indicatorButton} tabIndex={-1}>
            <img className={indicatorIcon} src={ErrorIcon} alt='error' />
          </button>
        </Tooltip>
      )}

      {!props.isButtonDisabled && showIconAlways && (
        <IconButton
          className={button}
          icon={CircularCloseIcon}
          alt='remove'
          disabled={props.isButtonDisabled}
          onClick={() => {
            if (props.onButtonClick) {
              props.onButtonClick();
            }
          }}
        />
      )}
    </div>
  );
}

export default forwardRef<HTMLInputElement, Props>(Input);
