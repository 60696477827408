import {
  ChannelV2,
  ExternalContractType,
  Manager,
  ManagerRoleTitleV2,
  ManagerStatus,
  MyChannelV2,
  PlanV2,
} from '@pickme/core';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import {
  createGetChannelRequest,
  createGetManagersRequest,
  createJoinChannelRequest,
  createApproveManagerRequest,
  createDeleteManagerRequest,
  createEditManagerRequest,
  createGetMyChannelsRequest,
  createEditChannelRequest,
  createLeaveChannelRequest,
  createRemoveChannelRequest,
  createChannelRequest,
  createGetChannelAliasDuplicationRequest,
  createChannelTransferRequest,
  createAcceptChannelSubscribingPlanRequest,
  createAcceptFreePlanChannelRequest,
  createGetTransferredChannelInfoRequest,
  createGetTransferInfoRequest,
} from 'apis/channel-v2';

import { useToast } from 'hooks/useToast';

import { organizationAliasValidator } from 'functions/validator/channel-v2';

import { toast } from 'states/toast';
import { spaceState } from 'states/space';

import {
  ChannelCreateForm,
  ChannelEditForm,
  ChannelAcceptForm,
  ChannelTransferRequestBody,
  ChannelQueryType,
  TransferInfo,
} from 'types/channel-v2';

export const useGetChannel = ({ key, type }: { key: string; type: ChannelQueryType }) =>
  useQuery(
    ['channels-v2', key, type],
    async () => {
      const { data } = await createGetChannelRequest({ key, type });

      return new ChannelV2(data);
    },
    {
      enabled: !!key && !!type,
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
    },
  );

export const useGetMyChannels = () =>
  useQuery(['my-channels-v2'], async () => {
    const { data } = await createGetMyChannelsRequest();
    const channels = data.map((channelResponse) => new MyChannelV2(channelResponse));

    return channels;
  });

export const useGetManagers = ({ page = 1, size }: { page?: number; size?: number }) =>
  useQuery(
    ['channels-v2', 'managers-v2', { page, size }],
    async () => {
      const { data } = await createGetManagersRequest({
        page,
        size,
      });

      return {
        page,
        total: data.totalCount,
        endPage: data.endPage,
        contents: data.admins.map((managerResponse) => new Manager(managerResponse)),
      };
    },
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
    },
  );

export const useGetAllApprovedManagers = () =>
  useInfiniteQuery(
    ['managers-v2', 'all'],
    async ({ pageParam = 1 }) => {
      const { data } = await createGetManagersRequest({
        page: pageParam,
        size: 999999,
        status: ManagerStatus.Approved,
      });

      return {
        total: data.totalCount,
        endPage: data.endPage,
        pageParam,
        contents: data.admins.map((managerResponse) => new Manager(managerResponse)),
      };
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        allPages.length < lastPage.endPage && lastPage.pageParam + 1,
      staleTime: 30 * 1000,
      cacheTime: 30 * 1000,
    },
  );

export const useApproveManager = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ managerId }: { managerId: string }) => {
      const { data } = await createApproveManagerRequest({ managerId });

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['channels-v2', 'managers-v2']);
        setToast({
          isVisible: true,
          type: 'success',
          message: '구성원이 성공적으로 추가되었습니다.',
        });
      },
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useDeleteManager = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ managerId }: { managerId: string }) => {
      const { data } = await createDeleteManagerRequest({ managerId });

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['channels-v2', 'managers-v2']);
        setToast({
          isVisible: true,
          type: 'success',
          message: '구성원이 채널에서 내보내졌습니다.',
        });
      },
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useEditManagerRole = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ managerId, role }: { managerId: string; role: ManagerRoleTitleV2 }) => {
      const { data } = await createEditManagerRequest({
        managerId,
        role,
      });

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['channels-v2', 'managers-v2']);
        setToast({
          isVisible: true,
          type: 'success',
          message: '매니저 설정이 성공적으로 변경되었습니다.',
        });
      },
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useJoinChannel = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ channelId, role }: { channelId: string; role: ManagerRoleTitleV2 }) => {
      const { data } = await createJoinChannelRequest({ channelId, role });

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['channels-v2']);
        queryClient.invalidateQueries('auth');

        setToast({
          isVisible: true,
          type: 'success',
          message: '합류 요청에 성공했어요. 소유자의 승인 후 이용할 수 있어요.',
        });
      },
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useEditChannel = () => {
  const setToast = useSetRecoilState(toast);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, form }: { id: string; form: ChannelEditForm }) => {
      const { data } = await createEditChannelRequest(id, form);

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['channels-v2']);
        setToast({
          isVisible: true,
          type: 'success',
          message: '채널 정보가 변경되었습니다.',
        });
      },
      onError: () => {
        setToast({
          isVisible: true,
          type: 'error',
          message: '채널 정보 변경에 실패했습니다.',
        });
      },
    },
  );
};

export const useRemoveChannel = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);

  return useMutation(
    async () => {
      await createRemoveChannelRequest();
    },
    {
      onSuccess: () => {
        setActiveSpaceId('');
        queryClient.resetQueries();
        navigate('/channels', {
          replace: true,
          unstable_viewTransition: true,
        });
      },
      onError: () => {
        setToast({
          isVisible: true,
          type: 'error',
          message: '채널 삭제에 실패했습니다.',
        });
      },
    },
  );
};

export const useLeaveChannel = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);
  const setActiveSpaceId = useSetRecoilState(spaceState.activeSpaceId);

  return useMutation(
    async () => {
      await createLeaveChannelRequest();
    },
    {
      onSuccess: () => {
        setToast({
          isVisible: true,
          type: 'notification',
          message: '더이상 채널에 접근할 수 없습니다.',
        });
        setActiveSpaceId('');
        queryClient.invalidateQueries(['channels-v2']);
        navigate('/channels', {
          replace: true,
          unstable_viewTransition: true,
        });
      },
      onError: (error: AxiosError) => {
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message,
        });
      },
    },
  );
};

export const useCreateChannel = () => {
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async (body: ChannelCreateForm) => {
      const { data } = await createChannelRequest(body);

      return new ChannelV2(data);
    },
    {
      onError: () => {
        setToast({
          isVisible: true,
          type: 'error',
          message: '새로운 채널 생성에 실패했습니다.',
        });
      },
    },
  );
};

export const useGetChannelAliasDuplication = (alias: string) =>
  useQuery(['channel-alias-duplication', alias], async () => {
    if (!alias || !organizationAliasValidator(alias)) {
      return false;
    }

    const { data } = await createGetChannelAliasDuplicationRequest(alias);
    return data.result;
  });

export const useChannelTransfer = () =>
  useMutation(async (data: ChannelTransferRequestBody) => {
    await createChannelTransferRequest(data);
  });

export const useAcceptChannelSubscribingPlan = () => {
  const { setToast } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setActiveChannelId = useSetRecoilState(spaceState.activeSpaceId);

  return useMutation(
    async (data: ChannelAcceptForm) => {
      await createAcceptChannelSubscribingPlanRequest(data);
    },
    {
      onSuccess: (_, data) => {
        queryClient.resetQueries();
        setActiveChannelId(data.channelId);
        navigate('/', {
          replace: true,
          unstable_viewTransition: true,
        });
        setToast({
          isVisible: true,
          type: 'success',
          message: '채널의 소유권 양도가 완료되었습니다.',
        });
      },
    },
  );
};

export const useAcceptFreePlanChannel = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setToast } = useToast();
  const setActiveChannelId = useSetRecoilState(spaceState.activeSpaceId);

  return useMutation(
    async (channelId: string) => {
      await createAcceptFreePlanChannelRequest(channelId);
      return channelId;
    },
    {
      onSuccess: (channelId) => {
        queryClient.resetQueries();
        setActiveChannelId(channelId);
        navigate('/', {
          replace: true,
          unstable_viewTransition: true,
        });
        setToast({
          isVisible: true,
          message: '채널의 소유권 양도가 완료되었습니다.',
          type: 'success',
        });
      },
    },
  );
};

export const useGetTransferredChannelInfo = (channelId: string) =>
  useQuery(
    ['channel-transfer', channelId],
    async () => {
      const { data } = await createGetTransferredChannelInfoRequest(channelId);
      const plan = new PlanV2(
        data.subscription.type,
        data.subscription.contractType === ExternalContractType.Trial,
      );

      return { ...data, plan };
    },
    {
      enabled: !!channelId,
    },
  );

export const useGetTransferInfo = (transferRequestId: string) =>
  useQuery(
    ['channel-transfer', transferRequestId],
    async () => {
      const { data } = await createGetTransferInfoRequest(transferRequestId);
      const transferInfo: TransferInfo = {
        _id: data._id,
        id: data.id,
        channelId: data.organizationOid,
        customerKey: data.customerKey,
        email: data.email,
        expiresAt: data.expiresAt,
        isCardRegistrationRequired: data.isCardRegistrationRequired,
        requestedAt: data.requestedAt,
      };
      return transferInfo;
    },
    {
      enabled: !!transferRequestId,
    },
  );
