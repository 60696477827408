import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Button, PlainButton } from '@pickme/design-system';

import ScrollToTopButton from 'components/common/ScrollToTopButton';
import ChannelInfo from 'components/features/home-v2/ChannelInfo';
import HomePollSearchKindTabsV2 from 'components/features/home-v2/PollSearchKindTabs';
import PollGroupSelect from 'components/features/home-v2/PollGroupSelect';
import HomePollCardsV2 from 'components/features/home-v2/HomePollCards';
import HomePendingPollCards from 'components/features/home-v2/HomePendingPollCards';
import PollActionsModal from 'components/features/home-v2/modal/PollActions';
import PollSearchInput from 'components/features/home-v2/PollSearchInput';

import { useGetProfile } from 'query-hooks/auth-v2';
import { useGetPendingPollsCount, useGetPollStats } from 'query-hooks/poll-v2';

import { useActiveChannel } from 'hooks/useActiveChannel';

import { EMPTY_STRING } from 'constants/common';

import { PollSearchGroupV2, PollKindForSearchV2 } from 'types/poll-v2';

import AddIcon from 'resources/icons/dashboard/add.svg';

import {
  HomePollSearchQuery,
  useHomePollSearchQueryParamsV2,
} from './useHomePollSearchQueryParamsV2';

import { container, top, bottom, whiteBackground, wrapper, grayRoundedButton } from './index.css';

function HomeV2() {
  const navigate = useNavigate();

  const [params, setParams] = useHomePollSearchQueryParamsV2(DEFAULT_POLL_SEARCH_QUERY);

  const [isPendingVisible, setIsPendingVisible] = useState(false);
  const [isKeywordSearchMode, setIsKeywordSearchMode] = useState(!!params.keyword);

  const { data: profile } = useGetProfile();
  const { activeChannel: channel } = useActiveChannel();

  const { data: pollStatistics } = useGetPollStats({
    kind: params.kind,
    channelId: channel?._id || EMPTY_STRING,
  });
  const { data: pendingCount } = useGetPendingPollsCount({
    kind: params.kind,
    authorId: profile?.adminId || EMPTY_STRING,
    keyword: params.keyword,
  });

  const methods = useForm<HomePollSearchQuery>({
    defaultValues: params,
  });

  const onSubmit = methods.handleSubmit((data) => {
    setIsKeywordSearchMode(!!data.keyword);
    setParams({ ...data });
  });

  return (
    <div className={container}>
      <div className={whiteBackground} />

      <div className={wrapper.parent}>
        <form className={wrapper.child} onSubmit={onSubmit}>
          <div className={top.container}>
            <ChannelInfo
              channelAlias={channel?.alias || EMPTY_STRING}
              channelName={channel?.name || EMPTY_STRING}
              channelLogo={channel?.logo || EMPTY_STRING}
            />

            <div className={top.tabs}>
              <Controller
                control={methods.control}
                name='kind'
                render={({ field }) => (
                  <HomePollSearchKindTabsV2
                    selected={field.value}
                    onClick={(value) => {
                      field.onChange(value);
                      onSubmit();
                    }}
                  />
                )}
              />

              <Button
                icon={AddIcon}
                onClick={() => navigate('/poll/init', { unstable_viewTransition: true })}
                className={top.newButton}
              >
                새로 만들기
              </Button>
            </div>
          </div>

          <div className={bottom.container}>
            <div className={bottom.searchBar}>
              <div className={bottom.pollStatus}>
                <Controller
                  control={methods.control}
                  name='groups'
                  render={({ field }) => (
                    <PollGroupSelect
                      selected={field.value}
                      onSelect={(groups) => {
                        field.onChange(groups);
                        onSubmit();
                      }}
                      pollStatistics={pollStatistics}
                      disableOpen={isPendingVisible}
                      triggerClassName={grayRoundedButton({ active: !isPendingVisible })}
                      onTriggerClick={() => setIsPendingVisible(false)}
                    />
                  )}
                />

                <PlainButton
                  className={grayRoundedButton({ active: isPendingVisible })}
                  onClick={() => setIsPendingVisible(true)}
                >
                  저장 {pendingCount}
                </PlainButton>

                {/* TODO: 갤러리뷰 모드의 대시보드가 피그마에 추가되면 갤러리뷰 / 테이블뷰 선택 버튼을 추가해야 합니다. */}
              </div>

              <Controller
                control={methods.control}
                name='keyword'
                render={({ field }) => (
                  <PollSearchInput
                    value={field.value}
                    onChange={field.onChange}
                    onClear={() => {
                      field.onChange(EMPTY_STRING);
                      onSubmit();
                    }}
                    onSubmit={onSubmit}
                  />
                )}
              />
            </div>

            {isPendingVisible ? (
              <HomePendingPollCards query={params} isKeywordSearchMode={isKeywordSearchMode} />
            ) : (
              <HomePollCardsV2 query={params} isKeywordSearchMode={isKeywordSearchMode} />
            )}
          </div>
        </form>
      </div>

      <ScrollToTopButton />

      <PollActionsModal />
    </div>
  );
}

export default HomeV2;

const DEFAULT_POLL_SEARCH_QUERY = {
  kind: PollKindForSearchV2.All,
  groups: PollSearchGroupV2.All,
  page: 1,
  keyword: EMPTY_STRING,
};
