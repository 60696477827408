import { CollaboratorRoleV2, Poll, PollGroup, PollStatus, VoterAuthMethodType } from '@pickme/core';

import { ApplicationMode } from './application-v2';

export enum PollKindV2 {
  CandidateElection = 'candidate-election',
  IssueElection = 'issue-election',
  Survey = 'survey',
}

export enum PollKindForSearchV2 {
  All = 'all',
  CandidateElection = 'candidate-election',
  IssueElection = 'issue-election',
  Survey = 'survey',
}

export enum PollAction {
  시작하기 = '시작하기',
  저장 = '저장',
  수정하기 = '수정하기',
  일시중지 = '일시 중지',
  재시작하기 = '재시작하기',
  종료하기 = '종료하기',
  강제종료 = '강제 종료',
  개표하기 = '개표하기',
  예약하기 = '예약하기',
  예약취소 = '예약취소',
}

export type VoterV2 = {
  userId?: string;
  name?: string;
  phoneNumber?: string;
  email?: string;
  categoryValues?: string[];
  hasVoted?: boolean;
};

export type ThemeColors = {
  primary: string;
  secondary: string;
  tertiary: string;
  inactive: string;
};

export type PollOutletContext = {
  title: string;
  setTitle: (title: string) => void;
  pollId?: string;
  draftId?: string;
  templateId?: string;
  poll?: Poll | null;
  template?: Poll | null;
  kind: PollKindV2;
  mode: ApplicationMode;
  checkFieldEditable: (
    step: 'before' | 'ongoing' | 'after',
    options?: {
      exclude?: boolean;
    },
  ) => boolean;
  isPreviewEnabled: boolean;
  togglePreviewEnabled: () => void;
  themeColors: ThemeColors;
  setThemeColors: (theme: ThemeColors) => void;
};

export enum PollSearchGroupV2 {
  All = 'all',
  Pending = 'unknown',
  Ready = PollGroup.Ready,
  Ongoing = PollGroup.Ongoing,
  Ended = PollGroup.Ended,
  Paused = PollGroup.Paused,
}
export type ApprovedPollSearchGroup = Exclude<PollSearchGroupV2, PollSearchGroupV2.Pending>;

export type PollSearchQueryV2 = {
  kind?: PollKindForSearchV2;
  groups?: PollSearchGroupV2;
  status?: PollStatus;
  voterAuthMethods?: VoterAuthMethodType[];
  page?: number;
  size?: number;
  authorId?: string;
  roles?: CollaboratorRoleV2;
  keyword?: string;
};

export type PollStatisticsResponseV2 = {
  ready: number;
  ongoing: number;
  pause: number;
  post: number;
};

export type PollStatisticsV2 = Record<ApprovedPollSearchGroup, number>;

export type PollSearchFormV2 = {
  kind: PollKindForSearchV2;
  groups: PollSearchGroupV2;
  keyword: string;
};
