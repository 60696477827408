import {
  Tooltip,
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import style from './index.css';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler);

type Item = { dataX: string; dataY: number; tooltipItems: { label: string; value: string }[] };

type Props = {
  items: Item[];
  XLabels: string[];
  YLabels?: { min?: number; max?: number; stepSize?: number };
  animation?: boolean;
};

function LineChart({ items, XLabels, YLabels, animation = false }: Props) {
  const dataXLength = items.length;

  const data = {
    labels: dataXLength === 1 ? [...items, ''] : items,
    datasets: [
      {
        label: 'filled',
        data:
          dataXLength === 1 ? [items[0].dataY, items[0].dataY] : items.map(({ dataY }) => dataY),
        borderWidth: 2,
        borderColor: (context: any) => makeGradient({ context, isTransparent: false }),
        pointBackgroundColor: (context: any) => makeGradient({ context, isTransparent: false }),
        pointRadius: 0,
        pointHoverRadius: 4,
        backgroundColor: (context: any) => makeGradient({ context, isTransparent: true }),
        fill: true,
        tension: 0.1,
      },
    ],
  };

  const options = {
    aspectRatio: 3,
    scales: {
      y: {
        min: YLabels?.min,
        max: YLabels?.max,
        grid: { display: true },
        border: { display: false, dash: [2, 4] },
        ticks: { stepSize: YLabels?.stepSize },
      },
      x: {
        grid: { display: false },
        border: { display: false },
        ticks: {
          callback: (index: number) => {
            const item = XLabels[index];
            return item;
          },
        },
      },
    },
    plugins: {
      datalabels: { display: false },
      tooltip: {
        enabled: false,
        external: (ctx: any) =>
          externalTooltipHandler({
            context: ctx,
            items,
            isInvisibleLastTooltip: dataXLength === 1,
          }),
        interaction: {
          intersect: false,
          mode: 'nearest',
          includeInvisible: true,
        },
      },
    },
    animation,
  };

  return (
    <div className={style.container}>
      {/* @ts-ignore */}
      <Line data={data} options={options} />
    </div>
  );
}

export default LineChart;

const makeGradient = ({ context, isTransparent }: { context: any; isTransparent: boolean }) => {
  const { chart } = context;
  const { ctx, chartArea } = chart;

  if (!chartArea) {
    return null;
  }

  const gradient = ctx!.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
  gradient.addColorStop(0, `#067DFD${isTransparent ? '20' : ''}`);
  gradient.addColorStop(0.5, `#2240CA${isTransparent ? '20' : ''}`);
  gradient.addColorStop(1, `#622BB6${isTransparent ? '20' : ''}`);

  return gradient;
};

const externalTooltipHandler = ({
  context,
  items,
  isInvisibleLastTooltip,
}: {
  context: any;
  items: Item[];
  isInvisibleLastTooltip: boolean;
}) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  while (tooltipEl.firstChild) {
    tooltipEl.firstChild.remove();
  }

  const tooltipData = tooltip.dataPoints[0];
  const tooltipIndex = tooltipData.dataIndex;

  if (isInvisibleLastTooltip && tooltipIndex === items.length) {
    tooltipEl.remove();
    return;
  }

  const tooltipHeader = document.createElement('div');
  tooltipHeader.classList.add(style.tooltip.header);

  const tooltipBody = document.createElement('div');
  tooltipBody.classList.add(style.tooltip.body);

  const item = items[tooltipIndex];
  const dataX = item?.dataX || '';
  const tooltipItems = item?.tooltipItems || [];

  makeFilledTooltipHeader(tooltipHeader, dataX);
  makeFilledTooltipBody(tooltipBody, tooltipItems);
  tooltipEl.style.transform = 'translate(0%, 10%)';

  tooltipEl.appendChild(tooltipHeader);
  tooltipEl.appendChild(tooltipBody);

  tooltipEl.style.opacity = 1;
  tooltipEl.style.width = '9.375rem';

  tooltipEl.style.left = `${tooltip.caretX}px`;
  tooltipEl.style.top = `${tooltip.caretY}px`;

  const isNearByRight = tooltip.caretX > chart.chartArea.right - 50;
  const isNearByBottom = tooltip.caretY > chart.chartArea.bottom - 50;

  if (isNearByRight && !isNearByBottom) {
    tooltipEl.style.transform = 'translate(-80%, 10%)';
  }

  if (!isNearByRight && isNearByBottom) {
    tooltipEl.style.transform = 'translate(0%, -10%)';
  }

  if (isNearByRight && isNearByBottom) {
    tooltipEl.style.transform = 'translate(-80%, -10%)';
  }
};

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add(style.tooltip.container);

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const makeFilledTooltipHeader = (tooltipHeader: HTMLDivElement, name: string) => {
  const headerTitle = document.createElement('span');
  headerTitle.innerHTML = name;

  tooltipHeader.appendChild(headerTitle);
};

const makeFilledTooltipBody = (
  tooltipBody: HTMLDivElement,
  tooltipItems: { value: string; label: string }[],
) => {
  tooltipItems.forEach(({ label, value }) => {
    const tooltipBodyItem = document.createElement('div');
    tooltipBodyItem.classList.add(style.tooltip.bodyItem);

    const tooltipLabel = document.createElement('span');
    tooltipLabel.innerHTML = label;

    const tooltipValue = document.createElement('span');
    tooltipValue.innerHTML = value;

    tooltipBodyItem.appendChild(tooltipLabel);
    tooltipBodyItem.appendChild(tooltipValue);

    tooltipBody.appendChild(tooltipBodyItem);
  });
};
