import * as yup from 'yup';
import { AuthType } from 'types/application-v2';

export const getVoterBookFormSchema = () =>
  yup.object({
    authType: yup
      .mixed<AuthType>()
      .oneOf(Object.values(AuthType), '참여자 인증 수단을 선택해주세요.')
      .required('참여자 인증 수단을 선택해주세요.'),
    authMethod: yup.array().when('authType', (authType, schema) => {
      if (authType[0] !== AuthType.OTP) {
        return schema.required('참여자 인증 수단을 선택해주세요.');
      }

      return schema.notRequired();
    }),
    additionalFieldNames: yup.array().of(yup.string()),
  });

// voters: yup
//   .array()
//   .of(
//     yup
//       .object()
//       .shape({
//         name: yup.string().required('이름은 필수 입력 사항입니다.'),
//         email: yup
//           .string()
//           .test('is-valid-email-or-empty', '올바른 이메일 형식이 아닙니다.', (value) => {
//             if (!value) return true;

//             return yup.string().email().isValidSync(value);
//           }),
//         phoneNumber: yup
//           .string()
//           .optional()
//           .test('is-valid-phone-or-empty', '올바른 전화번호 형식이 아닙니다.', (value) => {
//             if (!value) return true;

//             return yup
//               .string()
//               .matches(/^\d{3}-\d{3,4}-\d{4}|\d{10,11}$/)
//               .isValidSync(value);
//           }),
//         additionalFields: yup.array().of(yup.string()),
//       })
//       .test(
//         'email-or-phone',
//         '이메일 또는 전화번호 중 하나는 필수 입력 사항입니다.',
//         (value, context) => {
//           const { email, phoneNumber } = value;
//           if (email || phoneNumber) {
//             return true;
//           }

//           const errors = [
//             context.createError({
//               path: `${context.path}.email`,
//               message: '이메일 또는 전화번호 중 하나는 필수 입력 사항입니다.',
//             }),
//             context.createError({
//               path: `${context.path}.phoneNumber`,
//               message: '이메일 또는 전화번호 중 하나는 필수 입력 사항입니다.',
//             }),
//           ];

//           throw new yup.ValidationError(errors);
//         },
//       ),
//   )
//   .min(1, '참여자를 추가해주세요.')
//   .test('unique-email-phone', '이메일 또는 전화번호가 중복되었습니다.', (voters, context) => {
//     if (!voters) return true;

//     const emailCounts = new Map();
//     const phoneNumberCounts = new Map();
//     const errors: yup.ValidationError[] = [];

//     voters.forEach((voter) => {
//       const { email, phoneNumber } = voter;

//       if (email) {
//         emailCounts.set(email, (emailCounts.get(email) || 0) + 1);
//       }

//       if (phoneNumber) {
//         phoneNumberCounts.set(phoneNumber, (phoneNumberCounts.get(phoneNumber) || 0) + 1);
//       }
//     });

//     voters.forEach((voter, index) => {
//       const { email, phoneNumber } = voter;

//       if (email && emailCounts.get(email) > 1) {
//         errors.push(
//           context.createError({
//             path: `voters.${index}.email`,
//             message: '이메일이 중복되었습니다.',
//           }),
//         );
//       }

//       if (phoneNumber && phoneNumberCounts.get(phoneNumber) > 1) {
//         errors.push(
//           context.createError({
//             path: `voters.${index}.phoneNumber`,
//             message: '전화번호가 중복되었습니다.',
//           }),
//         );
//       }
//     });

//     if (errors.length > 0) {
//       throw new yup.ValidationError(errors);
//     }

//     return true;
//   }),
