import { isUndefined, omit, uniq } from 'lodash-es';
import { useEffect, useState } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Button, Callout, Dropdown, IconInput, OutlineButton, PlainButton, Text } from '@pickme/ui';
import { Plan } from '@pickme/core';
import { t } from 'i18next';

import InstantVoter from 'components/features/application/form/Voter/InstantVoter';
import VoterFormHeader from 'components/features/application/form/Voter/Header';
import VoterFormRow from 'components/features/application/form/Voter/Row';
import Pagination from 'components/common/Pagination';

import AddSingleVoterModal from 'components/features/application/form/Voter/AddSingleVoter';
import AddVotersModal from 'components/features/application/form/Voter/AddVoters';
import AddVotersResult from 'components/features/application/form/Voter/AddVotersResult';
import RemoveVoterModal from 'components/features/application/form/Voter/RemoveVoter';
import CategoryModal from 'components/features/application/form/Voter/Category';
import IncorrectVotersModal from 'components/features/application/form/Voter/IncorrectVoters';

import { toast } from 'states/toast';

import { useGetFeatures } from 'hooks/useGetFeatures';
import { usePollStarted } from 'hooks/poll/usePollData';

import {
  computeCategories,
  validateRow,
  validateRowInDuplication,
} from 'functions/validator/voter';
import { parseSheetAsVoters, downloadCurrentVoterBook } from 'functions/sheet';

import { IncorrectVoter, VoterBody, VoterFormBody } from 'types/application';
import { PollKind } from 'types/poll';

import { VoterError } from 'utils/error';

import SearchIcon from 'resources/icons/application/search.png';
import DownloadIcon from 'resources/icons/application/download.png';
import PlusIcon from 'resources/icons/application/plus.png';
import InfoIcon from 'resources/icons/application/info.png';
import SingleIcon from 'resources/icons/application/single.png';
import GroupIcon from 'resources/icons/application/group.png';
import SearchPlaceholderIcon from 'resources/icons/table/search.png';
import CloseIcon from 'resources/icons/application/close.png';

import './index.scss';

type Props = {
  kind: PollKind;
  pollName: string;
  selectedPlan: Plan;
  onSubmit: (body: VoterFormBody) => void;
};

function VoterForm({ kind, pollName, selectedPlan, onSubmit }: Props) {
  const [submittedKeyword, setSubmittedKeyword] = useState('');
  const [page, setPage] = useState(1);

  const setToast = useSetRecoilState(toast);
  const { voterLimit, type: organizationPlanType } = useGetFeatures();

  const [selectedVoters, selectVoters] = useState<number[]>([]);

  const [isProcessRunning, setProcessRunning] = useState(false);
  const [isAddVotersModalVisible, setAddVotersModalVisible] = useState(false);
  const [isCategoryModalVisible, setCategoryModalVisible] = useState(false);
  const [isIncorrectVotersModalVisible, setIncorrectVotersModalVisible] = useState(false);
  const [computedCategoriesInfo, setComputedCategoriesInfo] = useState<{
    categories: { name: string; count: number }[];
    unset: number;
    total: number;
  }>();
  const [resultModal, setResultModal] = useState<{
    isVisible: boolean;
    success?: number;
    failure?: number;
  }>({ isVisible: false });

  const [removeVoterModal, setRemoveVoterModal] = useState<{
    isVisible: boolean;
    selectedIndex?: number;
    name?: string;
  }>({ isVisible: false });

  const [addSingleVoterModal, setAddSingleVoterModal] = useState<{
    isVisible: boolean;
    selectedIndex?: number;
    voter?: VoterBody;
    wrongFields?: string[];
  }>({
    isVisible: false,
  });

  const voterForm = useFormContext<VoterFormBody>();
  const { control, handleSubmit, getValues, resetField } = voterForm;
  const searchForm = useForm<{ keyword: string }>();
  const isPollStarted = usePollStarted();
  const hasInstantVoter = voterForm.watch('hasInstantVoter');

  const voterBook = useFieldArray<VoterFormBody, 'voterBook'>({
    name: 'voterBook',
    control,
    rules: {
      validate: (fields) => {
        if (voterForm.getValues('hasInstantVoter')) {
          return true;
        }

        if (fields.length === 0) {
          return t('admin:components.features.application.form.voter.errorMessages.required');
        }

        if (selectedPlan === Plan.Free && fields.length > 30) {
          return t(
            'admin:components.features.application.form.voter.errorMessages.plan.capacity.free',
          );
        }

        if (selectedPlan !== Plan.Free) {
          if (
            selectedPlan !== Plan.Enterprise &&
            selectedPlan !== Plan.Campus &&
            fields.length < 31
          ) {
            return t(
              'admin:components.features.application.form.voter.errorMessages.plan.capacity.charged.min',
            );
          }

          if (fields.length > voterLimit) {
            return t(
              'admin:components.features.application.form.voter.errorMessages.plan.capacity.charged.max',
              {
                number: voterLimit,
              },
            );
          }
        }

        return true;
      },
    },
  });

  const incorrectVoters = useFieldArray<VoterFormBody, 'incorrectVoters'>({
    name: 'incorrectVoters',
    control,
    rules: {
      validate: (fields) => {
        if (fields.length !== 0) {
          return t('admin:components.features.application.form.voter.errorMessages.incorrectVoter');
        }

        return true;
      },
    },
  });

  const onSubmitSingleVoter = (voter: VoterBody) => {
    setProcessRunning(true);

    const isEdit =
      (addSingleVoterModal.voter && !isUndefined(addSingleVoterModal?.selectedIndex)) || false;

    try {
      if (!voter.email && !voter.phoneNumber) {
        throw new VoterError(
          t('admin:components.features.application.form.voter.errorMessages.emailOrPhoneNumber'),
          ['email', 'phoneNumber'],
        );
      }

      validateRow(voter);

      const source = voterBook.fields.slice();
      const userIds = new Set<string>();
      const phoneNumbers = new Set<string>();
      const emails = new Set<string>();

      source.forEach(({ userId, phoneNumber, email }) => {
        if (userId) {
          userIds.add(userId);
        }
        phoneNumbers.add(phoneNumber || '');
        emails.add(email || '');
      });

      if (isEdit) {
        if (addSingleVoterModal.voter?.userId) {
          userIds.delete(addSingleVoterModal.voter?.userId as string);
        }

        if (addSingleVoterModal.voter?.phoneNumber) {
          phoneNumbers.delete(addSingleVoterModal.voter?.phoneNumber);
        }

        if (addSingleVoterModal.voter?.email) {
          emails.delete(addSingleVoterModal.voter?.email);
        }

        validateRowInDuplication(voter, userIds, phoneNumbers, emails);

        voterBook.update(addSingleVoterModal.selectedIndex as number, voter);
      } else {
        validateRowInDuplication(voter, userIds, phoneNumbers, emails);

        voterBook.insert(0, voter);
      }

      setAddSingleVoterModal({ isVisible: false });
      setToast({
        isVisible: true,
        type: 'success',
        message: t(
          'admin:components.features.application.form.voter.modals.addSingleVoter.messages.success',
        ),
      });
    } catch (error: any) {
      if (error instanceof VoterError) {
        setAddSingleVoterModal({ ...addSingleVoterModal, wrongFields: error.fields });
        setToast({
          isVisible: true,
          type: 'error',
          message: error.message || '',
        });
      }
    } finally {
      setProcessRunning(false);
    }
  };

  const onSubmitSpreadSheetFile = async (spreadSheetFile: File) => {
    setProcessRunning(true);
    try {
      const rows = await parseSheetAsVoters(spreadSheetFile);
      const source = voterBook.fields.slice();

      const userIds = new Set<string>();
      const phoneNumbers = new Set<string>();
      const emails = new Set<string>();

      source.forEach(({ userId, phoneNumber, email }) => {
        if (userId) {
          userIds.add(userId);
        }
        phoneNumbers.add(phoneNumber || '');
        emails.add(email || '');
      });

      const correctRowsSet = new Set<VoterBody>();
      const incorrectRowsSet = new Set<IncorrectVoter>();

      rows.forEach((row) => {
        try {
          validateRow(row);
          validateRowInDuplication(row, userIds, phoneNumbers, emails);

          correctRowsSet.add(row);
        } catch (error: any) {
          if (error instanceof VoterError) {
            incorrectRowsSet.add({ ...row, message: error.message, wrongFields: error.fields });
          }
        }
      });

      voterBook.insert(0, Array.from(correctRowsSet));
      incorrectVoters.insert(0, Array.from(incorrectRowsSet));

      setResultModal({
        isVisible: true,
        success: correctRowsSet.size,
        failure: incorrectRowsSet.size,
      });
    } catch {
      setToast({
        isVisible: true,
        type: 'error',
        message: t('admin:components.features.application.form.voter.errorMessages.template'),
      });
    } finally {
      setAddVotersModalVisible(false);
      setProcessRunning(false);
    }
  };

  const onSubmitIncorrectVoters = (rows: IncorrectVoter[]) => {
    setProcessRunning(true);
    resetField('incorrectVoters');

    const source = voterBook.fields.slice();

    const userIds = new Set<string>();
    const phoneNumbers = new Set<string>();
    const emails = new Set<string>();

    source.forEach(({ userId, phoneNumber, email }) => {
      if (userId) {
        userIds.add(userId);
      }
      phoneNumbers.add(phoneNumber || '');
      emails.add(email || '');
    });

    const correctRowsSet = new Set<VoterBody>();
    const incorrectRowsSet = new Set<IncorrectVoter>();

    rows.forEach((row) => {
      const parsedRow: IncorrectVoter = { ...row };
      if (row.phoneNumber) {
        parsedRow.phoneNumber = row.phoneNumber.replaceAll('-', '');
      }

      try {
        validateRow(parsedRow);
        validateRowInDuplication(parsedRow, userIds, phoneNumbers, emails);

        correctRowsSet.add(parsedRow);
      } catch (error: any) {
        if (error instanceof VoterError) {
          incorrectRowsSet.add({ ...parsedRow, message: error.message, wrongFields: error.fields });
        }
      }
    });

    if (incorrectRowsSet.size > 0) {
      setToast({
        isVisible: true,
        type: 'error',
        message: t('admin:components.features.application.form.voter.errorMessages.addVoters', {
          number: incorrectRowsSet.size,
        }),
      });
    }

    voterBook.insert(0, Array.from(correctRowsSet));
    incorrectVoters.insert(0, Array.from(incorrectRowsSet));

    setProcessRunning(false);
    setIncorrectVotersModalVisible(false);
  };

  const onError: SubmitErrorHandler<VoterFormBody> = (error) => {
    const message = error.voterBook?.root?.message || error.incorrectVoters?.root?.message || '';
    setToast({
      isVisible: true,
      type: 'error',
      message,
    });
  };

  const voterFields = voterBook.fields
    .map((field, index) => ({
      ...field,
      index,
    }))
    .filter((field) => {
      if (!submittedKeyword) {
        return true;
      }

      const parsedField = { ...field, id: '' };

      try {
        return JSON.stringify(omit(parsedField, ['index']))
          .toLowerCase()
          .includes(submittedKeyword.toLowerCase());
      } catch {
        return false;
      }
    });

  const onSearch: SubmitHandler<{ keyword: string }> = (data) => {
    setSubmittedKeyword(data.keyword);
    setPage(1);
  };

  useEffect(() => {
    selectVoters([]);
  }, [page]);

  const votersInThisPage = voterFields.slice((page - 1) * 10, page * 10);
  useEffect(() => {
    const isEmptyPage = votersInThisPage.length === 0;
    if (page !== 1 && isEmptyPage) {
      setPage(page - 1);
    }
  }, [page, votersInThisPage.length]);

  return (
    <div>
      <div className='application-form application-form--large voter-form'>
        <div className='voter-form__title'>
          <Text size='md2' fontWeight={600}>
            {t('admin:components.features.application.form.voter.title')}
          </Text>
        </div>

        <Callout
          id='voter'
          variant='primary'
          sentences={setCalloutSentences(organizationPlanType, voterLimit)}
        />

        {kind === 'Survey' && <InstantVoter disabled={isPollStarted} />}

        {!hasInstantVoter && (
          <FormProvider {...searchForm}>
            <form onSubmit={searchForm.handleSubmit(onSearch)}>
              <div className='application-form__row application-form__row--justify'>
                <div className='voter-form__search'>
                  <IconInput
                    id='keyword'
                    icon={SearchIcon}
                    size='lg'
                    placeholder={t(
                      'admin:components.features.application.form.voter.search.keyword.placeholder',
                    )}
                    width='100%'
                    maxLength={30}
                    {...searchForm.register('keyword')}
                  />
                  <Button size='md' type='submit'>
                    {t('admin:components.features.application.form.voter.search.button')}
                  </Button>
                </div>
              </div>

              {submittedKeyword && (
                <div className='voter-form__search-keyword'>
                  <div className='voter-form__search-keyword__badge'>
                    <Text type='b3' fontWeight={400} color='gray-400'>
                      {submittedKeyword}
                    </Text>
                    <PlainButton
                      type='button'
                      onClick={() => {
                        searchForm.reset();
                        setSubmittedKeyword('');
                        setPage(1);
                      }}
                    >
                      <img src={CloseIcon} alt='close' />
                    </PlainButton>
                  </div>
                </div>
              )}
            </form>
          </FormProvider>
        )}

        <div className='application-form__row application-form__row--justify'>
          {!hasInstantVoter && (
            <div className='voter-form__button-groups'>
              <OutlineButton
                type='button'
                variant='error'
                disabled={voterFields.length === 0 || isPollStarted}
                onClick={() => {
                  voterForm.setValue('voterBook', []);
                  voterForm.setValue('incorrectVoters', []);
                  selectVoters([]);
                }}
              >
                전체 삭제
              </OutlineButton>

              <OutlineButton
                type='button'
                variant='error'
                disabled={selectedVoters.length === 0}
                onClick={() => {
                  voterBook.remove(selectedVoters);
                  selectVoters([]);
                }}
              >
                선택 삭제
              </OutlineButton>

              <OutlineButton
                type='button'
                size='md'
                variant='success'
                onClick={() => {
                  downloadCurrentVoterBook(getValues('voterBook'), pollName);
                }}
              >
                <img src={DownloadIcon} alt='download' className='voter-form__button__icon' />
                {t('admin:components.features.application.form.voter.buttons.download')}
              </OutlineButton>

              <Dropdown
                button={
                  <OutlineButton type='button' size='md'>
                    <img src={PlusIcon} alt='add' className='voter-form__button__icon' />
                    {t('admin:components.features.application.form.voter.buttons.add')}
                  </OutlineButton>
                }
                items={[
                  {
                    icon: SingleIcon,
                    text: t(
                      'admin:components.features.application.form.voter.buttons.addSingleVoter',
                    ),
                    type: 'button',
                    onClick: () => setAddSingleVoterModal({ isVisible: true }),
                  },
                  {
                    icon: GroupIcon,
                    text: t('admin:components.features.application.form.voter.buttons.addVoters'),
                    type: 'button',
                    onClick: () => setAddVotersModalVisible(true),
                  },
                ]}
              />
            </div>
          )}

          {!hasInstantVoter && (
            <div className='voter-form__status'>
              <PlainButton>
                <Text type='b2' fontWeight={400} color='light-gray-500'>
                  {t('admin:components.features.application.form.voter.labels.voters.total', {
                    number: voterBook.fields.length + incorrectVoters.fields.length,
                  })}
                </Text>
              </PlainButton>

              <PlainButton>
                <Text type='b2' fontWeight={400} color='blue-500'>
                  {t('admin:components.features.application.form.voter.labels.voters.success', {
                    number: voterBook.fields.length,
                  })}
                </Text>
              </PlainButton>

              <PlainButton
                onClick={() => {
                  if (incorrectVoters.fields.length > 0) {
                    setIncorrectVotersModalVisible(true);
                  }
                }}
              >
                <Text type='b2' fontWeight={400} color='rose-500'>
                  {t('admin:components.features.application.form.voter.labels.voters.failure', {
                    number: incorrectVoters.fields.length,
                  })}
                </Text>
              </PlainButton>

              <PlainButton
                onClick={() => {
                  const result = computeCategories(voterBook.fields);
                  setComputedCategoriesInfo(result);
                  setCategoryModalVisible(true);
                }}
              >
                <Text type='b2' fontWeight={400} color='yellow-500'>
                  {t('admin:components.features.application.form.voter.labels.voters.category', {
                    number: uniq(
                      voterBook.fields
                        .slice()
                        .filter(({ category }) => !!category)
                        .map(({ category }) => category),
                    ).length,
                  })}
                </Text>
              </PlainButton>
            </div>
          )}
        </div>

        <FormProvider {...voterForm}>
          <form onSubmit={handleSubmit(onSubmit, onError)} className='voter-form__voters__form'>
            {!hasInstantVoter && (
              <>
                <div className='voter-form__voters'>
                  <VoterFormHeader
                    onCheck={() => {
                      selectVoters(
                        votersInThisPage
                          .filter(({ hasVoted }) => !hasVoted)
                          .map(({ index }) => index),
                      );
                    }}
                  />

                  {voterBook.fields.length > 0 && voterFields.length ? (
                    votersInThisPage.map((voter) => (
                      <VoterFormRow
                        key={voter.id}
                        voter={voter}
                        selected={selectedVoters.includes(voter.index)}
                        onSelect={(selected) => {
                          if (selected) {
                            const voters = selectedVoters.slice();
                            voters.push(voter.index);
                            selectVoters(voters);
                          } else {
                            const voters = selectedVoters.slice();
                            const index = voters.indexOf(voter.index);
                            voters.splice(index, 1);
                            selectVoters(voters);
                          }
                        }}
                        onEditButtonClick={() => {
                          setAddSingleVoterModal({
                            isVisible: true,
                            voter,
                            selectedIndex: voter.index,
                          });
                        }}
                        onRemoveButtonClick={() => {
                          setRemoveVoterModal({
                            isVisible: true,
                            selectedIndex: voter.index,
                            name: voter.name,
                          });
                        }}
                      />
                    ))
                  ) : (
                    <div className='voter-form__voters__empty'>
                      {voterBook.fields.length > 0 ? (
                        <>
                          <img src={SearchPlaceholderIcon} alt='search' />
                          <Text size='sm2'>
                            {t(
                              'admin:components.features.application.form.voter.search.result.blank.0',
                            )}
                          </Text>
                          <Text size='sm2'>
                            {t(
                              'admin:components.features.application.form.voter.search.result.blank.1',
                            )}
                          </Text>
                        </>
                      ) : (
                        <>
                          <img src={InfoIcon} alt='alert' />
                          <Text size='sm2'>
                            {t(
                              'admin:components.features.application.form.voter.search.result.empty.0',
                            )}
                          </Text>
                          <Text size='sm2'>
                            {t(
                              'admin:components.features.application.form.voter.search.result.empty.1',
                            )}
                          </Text>
                        </>
                      )}
                    </div>
                  )}
                </div>

                <Pagination
                  page={page || 1}
                  setPage={setPage}
                  endPage={Math.ceil(voterFields.length / 10) || 1}
                />
              </>
            )}

            <Button width='100%' size='lg' variant='primary' type='submit'>
              {t('admin:components.features.application.form.voter.buttons.submit')}
            </Button>
          </form>
        </FormProvider>
      </div>

      <AddSingleVoterModal
        isVisible={addSingleVoterModal.isVisible}
        isDisabled={isProcessRunning}
        wrongFields={addSingleVoterModal.wrongFields}
        onClose={() => setAddSingleVoterModal({ isVisible: false })}
        initialValue={addSingleVoterModal.voter}
        onSubmitVoter={(voter) => onSubmitSingleVoter(voter)}
      />

      <AddVotersModal
        isVisible={isAddVotersModalVisible}
        isLoading={isProcessRunning}
        onClose={() => setAddVotersModalVisible(false)}
        onSubmitVoters={(file) => onSubmitSpreadSheetFile(file)}
      />

      <RemoveVoterModal
        isVisible={removeVoterModal.isVisible}
        isDisabled={isProcessRunning}
        onClose={() => setRemoveVoterModal({ isVisible: false })}
        name={removeVoterModal.name || ''}
        onSubmit={() => {
          voterBook.remove(removeVoterModal.selectedIndex);
          setRemoveVoterModal({ isVisible: false });
        }}
      />

      <AddVotersResult
        isVisible={resultModal.isVisible}
        data={{ success: resultModal.success || 0, failure: resultModal.failure || 0 }}
        onClose={() => {
          setResultModal({ isVisible: false });
          setToast({
            isVisible: true,
            type: 'success',
            message: t(
              'admin:components.features.application.form.voter.modals.addVotersResult.messages.success',
            ),
          });
        }}
      />

      <IncorrectVotersModal
        isVisible={isIncorrectVotersModalVisible}
        incorrectVoters={getValues('incorrectVoters')}
        isLoading={isProcessRunning}
        onSubmit={(voters) => {
          onSubmitIncorrectVoters(voters);
        }}
        onClose={() => {
          setIncorrectVotersModalVisible(false);
        }}
      />

      <CategoryModal
        isVisible={isCategoryModalVisible}
        categories={computedCategoriesInfo?.categories}
        total={computedCategoriesInfo?.total}
        unset={computedCategoriesInfo?.unset}
        onClose={() => setCategoryModalVisible(false)}
      />
    </div>
  );
}

function setCalloutSentences(plan: Plan, voterLimit: number) {
  const sentences: React.ReactNode[] = [
    <>
      <Text type='b3' fontWeight={500}>
        {t('admin:components.features.application.form.voter.callout.free.0')}
      </Text>
      <Text type='b3' fontWeight={400}>
        {t('admin:components.features.application.form.voter.callout.free.1')}
      </Text>
      <Text type='b3' fontWeight={500}>
        {t('admin:components.features.application.form.voter.callout.free.2')}
      </Text>
      <Text type='b3' fontWeight={400}>
        {t('admin:components.features.application.form.voter.callout.free.3')}
      </Text>
    </>,
  ];

  if (plan !== Plan.Free) {
    if (voterLimit === 31) {
      sentences.push(
        <>
          <Text type='b3' fontWeight={400}>
            {t('admin:components.features.application.form.voter.callout.freeCapacity.0')}
          </Text>
          <Text type='b3' fontWeight={500}>
            {t('admin:components.features.application.form.voter.callout.freeCapacity.1')}
          </Text>
          <Text type='b3' fontWeight={400}>
            {t('admin:components.features.application.form.voter.callout.freeCapacity.2')}
          </Text>
        </>,
      );
    } else {
      sentences.push(
        <>
          <Text type='b3' fontWeight={400}>
            {t('admin:components.features.application.form.voter.callout.chargedCapacity.0')}
          </Text>
          <Text type='b3' fontWeight={500}>
            {t('admin:components.features.application.form.voter.callout.chargedCapacity.1', {
              max: voterLimit,
            })}
          </Text>
          <Text type='b3' fontWeight={400}>
            {t('admin:components.features.application.form.voter.callout.chargedCapacity.2')}
          </Text>
        </>,
      );
    }
  }

  return sentences;
}

export default VoterForm;
