import {
  IssueChoiceSubElection,
  IssueRankSubElection,
  IssueScoreSubElection,
  IssueSubElection,
  ElectionCategory,
  IssueElection,
  Issue,
} from '@pickme/core';
import { v4 as uuid } from 'uuid';

import { PollApplicationAdapter } from 'models/application-v2/adapters/poll/PollApplicationAdapter';
import { VoterBook } from 'models/poll-v2/Voterbook';

import {
  CandidateMeta,
  ElectionTypeCase,
  IssueElectionApplicationForms,
  IssueElectionForm,
} from 'types/application-v2';

const ELECTION_TYPE_MAPPER: { [key in ElectionCategory]: ElectionTypeCase } = {
  [ElectionCategory.선택투표]: ElectionTypeCase.선택투표,
  [ElectionCategory.순위투표]: ElectionTypeCase.순위투표,
  [ElectionCategory.점수투표]: ElectionTypeCase.점수투표,
  [ElectionCategory.찬반투표]: ElectionTypeCase.찬반투표,
};

export class IssueElectionApplicationAdapter extends PollApplicationAdapter {
  private _issueElection: IssueElection;

  constructor(issueElection: IssueElection, voterBook: VoterBook | null) {
    super(issueElection, voterBook);
    this._issueElection = issueElection;
  }

  static setMultipleSelectionValues(election: IssueSubElection): {
    allowMultipleSelectionEnabled: boolean;
    allowMultipleSelectionMinValue: number;
    allowMultipleSelectionMaxValue: number;
  } {
    if (election instanceof IssueChoiceSubElection && !election.isSingleChoice) {
      return {
        allowMultipleSelectionEnabled: true,
        allowMultipleSelectionMinValue: election.min || 1,
        allowMultipleSelectionMaxValue: election.max || 1,
      };
    }

    return {
      allowMultipleSelectionEnabled: false,
      allowMultipleSelectionMinValue: 1,
      allowMultipleSelectionMaxValue: 1,
    };
  }

  static setScoreValues(election: IssueSubElection): {
    minScore: number;
    maxScore: number;
  } {
    if (!(election instanceof IssueScoreSubElection)) {
      return {
        minScore: 0,
        maxScore: 100,
      };
    }

    return {
      minScore: election.min,
      maxScore: election.max,
    };
  }

  static setRankValues(election: IssueSubElection): {
    rank: number;
    rankWeights: number[];
  } {
    if (!(election instanceof IssueRankSubElection)) {
      return {
        rank: 1,
        rankWeights: [10],
      };
    }

    return {
      rank: election.electionCount,
      rankWeights: election.rankWeights,
    };
  }

  static setCandidates(candidates: Issue[]): CandidateMeta[] {
    return candidates.map((candidate) => ({
      id: uuid(),
      description: candidate.description,
      title: candidate.name,
      imageUrls: {
        main: candidate.imageUrl,
      },
    }));
  }

  getIssueElectionForm(): IssueElectionForm {
    return {
      issueElections: this._issueElection.subElections.map((election) => {
        const multipleSelectionValues =
          IssueElectionApplicationAdapter.setMultipleSelectionValues(election);
        const scoreValues = IssueElectionApplicationAdapter.setScoreValues(election);
        const rankValues = IssueElectionApplicationAdapter.setRankValues(election);
        const candidates = IssueElectionApplicationAdapter.setCandidates(election.issues);

        return {
          key: uuid(),
          type: ELECTION_TYPE_MAPPER[election.category],
          title: election.title,
          description: election.description?.text || '',
          imageUrl: election.description?.imageUrl,
          candidates,
          shuffle: election.shuffle,
          includeAbstention: election.includeAbstention,
          ...multipleSelectionValues,
          ...scoreValues,
          ...rankValues,
        };
      }),
    };
  }

  getForms(): IssueElectionApplicationForms {
    return {
      introForm: this.getIntroForm(),
      customThemeForm: this.getCustomThemeForm(),
      endingForm: this.getEndingForm(),
      settingsForm: this.getSettingsForm(),
      voterBookForm: this.getVoterBookForm(),
      issueElectionForm: this.getIssueElectionForm(),
      votersForm: this.getVotersForm(),
    };
  }
}
