import { Outlet } from 'react-router-dom';

import PollShareModal from 'components/features/modal/PollShareModal';
import PollActionModal from 'components/features/modal/PollActionModal';

import { useChannelTalk } from 'hooks/useChannelTalk';
import { useNavigateToRedirectUrl, useSetRedirectUrl } from 'hooks/useRedirectUrl';

function GlobalWrapper() {
  useChannelTalk();
  useSetRedirectUrl();
  useNavigateToRedirectUrl();

  return (
    <div>
      <Outlet />
      <PollShareModal />
      <PollActionModal />
    </div>
  );
}

export default GlobalWrapper;
