import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import {
  AlimTalkSendRequestBody,
  EmailSendRequestBody,
  LmsSendRequestBody,
  MessagingSearchQuery,
  PollMessaging,
} from 'types/message';

export const MESSAGE_APIS: Apis = {
  sendAlimTalk: { method: 'POST', url: '/api/messaging/kakao' },
  reserveAlimTalk: { method: 'POST', url: '/api/messaging/reservation/kakao' },
  sendLms: { method: 'POST', url: '/api/messaging/lms' },
  reserveLms: { method: 'POST', url: '/api/messaging/reservation/lms' },
  sendEmail: { method: 'POST', url: '/api/messaging/email' },
  reserveEmail: { method: 'POST', url: '/api/messaging/reservation/email' },
  getMessages: { method: 'GET', url: '/api/messaging' },
  getMessage: { method: 'GET', url: '/api/messaging/:id' },
  cancelReservation: { method: 'DELETE', url: '/api/messaging/:id/reservation' },
};

export const createSendAlimTalkRequest = (body: AlimTalkSendRequestBody) => {
  const data: AlimTalkSendRequestBody = {
    pollIds: body.pollIds,
    recipientType: body.recipientType,
    templateType: body.templateType,
  };

  if (body.date) {
    data.date = body.date;
  }

  if (body.channelId) {
    data.channelId = body.channelId;
  }

  return createHttpRequest({
    ...MESSAGE_APIS.sendAlimTalk,
    data,
  });
};

export const createReserveAlimTalkRequest = (body: AlimTalkSendRequestBody) => {
  const data: AlimTalkSendRequestBody = {
    pollIds: body.pollIds,
    recipientType: body.recipientType,
    templateType: body.templateType,
  };

  if (body.date) {
    data.date = body.date;
  }

  if (body.channelId) {
    data.channelId = body.channelId;
  }

  return createHttpRequest({
    ...MESSAGE_APIS.reserveAlimTalk,
    data,
  });
};

export const createSendLmsRequest = (data: LmsSendRequestBody) =>
  createHttpRequest({
    ...MESSAGE_APIS.sendLms,
    data,
  });

export const createReserveLmsRequest = (data: LmsSendRequestBody) =>
  createHttpRequest({
    ...MESSAGE_APIS.reserveLms,
    data,
  });

export const createSendEmailRequest = (data: EmailSendRequestBody) =>
  createHttpRequest({
    ...MESSAGE_APIS.sendEmail,
    data,
  });

export const createReserveEmailRequest = (data: EmailSendRequestBody) =>
  createHttpRequest({
    ...MESSAGE_APIS.reserveEmail,
    data,
  });

export const createGetMessagesRequest = (query: MessagingSearchQuery) =>
  createHttpRequest<{ results: PollMessaging[]; endPage: number }>({
    ...MESSAGE_APIS.getMessages,
    query,
  });

export const createGetMessageRequest = (id: string) =>
  createHttpRequest({
    ...MESSAGE_APIS.getMessage,
    params: {
      id,
    },
  });

export const createCancelReservationRequest = (id: string) =>
  createHttpRequest({
    ...MESSAGE_APIS.cancelReservation,
    params: {
      id,
    },
  });
