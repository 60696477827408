import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlainButton, Text } from '@pickme/design-system';
import { Modal, ModalActions, ModalBody, ModalHeader } from '@pickme/ui';
import { t } from 'i18next';

import { PollKindV2 } from 'types/poll-v2';

import CandidateElectionImage from 'resources/images/templates/election/manual.png';
import IssueElectionImage from 'resources/images/templates/election2/manual.png';
import SurveyImage from 'resources/images/templates/survey/manual.png';

import { body, button, bodyContainer, image, container } from './index.css';

const IMAGES = {
  [PollKindV2.CandidateElection]: CandidateElectionImage,
  [PollKindV2.IssueElection]: IssueElectionImage,
  [PollKindV2.Survey]: SurveyImage,
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

function ManualStartModalV2({ isVisible, onClose }: Props) {
  const navigate = useNavigate();
  const [kind, setKind] = useState<PollKindV2>(PollKindV2.CandidateElection);

  return (
    <Modal isVisible={isVisible} onClose={onClose} containerClassName={container}>
      <ModalHeader
        title={t('admin:components.features.template.modals.manualStart.title')}
        onClose={onClose}
        hideCloseButton
      />

      <ModalBody>
        <div className={bodyContainer}>
          {[
            { label: '선거', value: PollKindV2.CandidateElection },
            { label: '투표', value: PollKindV2.IssueElection },
            { label: '설문', value: PollKindV2.Survey },
          ].map((item) => (
            <KindButtonV2
              kind={item.value}
              isSelected={kind === item.value}
              onSelect={() => setKind(item.value)}
            />
          ))}
        </div>
      </ModalBody>

      <ModalActions
        buttons={[
          {
            text: t('admin:components.features.template.modals.manualStart.button'),
            onClick: () => {
              navigate(`/poll/application/${kind}`, {
                unstable_viewTransition: true,
              });
              onClose();
            },
          },
        ]}
      />
    </Modal>
  );
}

export default ManualStartModalV2;

function KindButtonV2({
  isSelected,
  onSelect,
  kind,
}: {
  isSelected: boolean;
  onSelect: () => void;
  kind: PollKindV2;
}) {
  return (
    <PlainButton className={button({ selected: isSelected })} onClick={() => onSelect()}>
      <img className={image} src={IMAGES[kind]} alt='kind' />
      <div className={body}>
        <Text size={20} fontWeight={600}>
          {BUTTON_TEXT[kind].title}
        </Text>
        <Text size={14} fontWeight={400} color='gray-700'>
          {BUTTON_TEXT[kind].description}
        </Text>
      </div>
    </PlainButton>
  );
}

const BUTTON_TEXT = {
  [PollKindV2.CandidateElection]: {
    title: '선거',
    description: '조직의 대표자 선출을 위한 선거를 만들어보세요',
  },
  [PollKindV2.IssueElection]: {
    title: '투표',
    description: '인기투표, 안건 찬반 투표, 공모전 심사까지 원하는대로 만드세요.',
  },
  [PollKindV2.Survey]: {
    title: '설문',
    description: '다양한 의견을 수집하기 위한 설문을 만들어보세요',
  },
};
