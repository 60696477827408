import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes, Ref } from 'react';
import { Text, Tooltip } from '@pickme/design-system';

import AlertIcon from 'resources/icons/application-v2/alert.svg';

import { textarea, container, inputContainer } from './index.css';

type CommonProps = {
  isValid?: boolean;
  errorMessage?: string;
  size?: 'sm' | 'md';
  containerClassName?: string;
  underlined?: boolean;
  tooltip?: {
    icon?: string;
    message: string;
  };
};

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}
type Props = Omit<InputProps, 'size'> & CommonProps;

function Input(
  {
    isValid = true,
    errorMessage,
    size = 'md',
    containerClassName,
    underlined = true,
    tooltip,
    ...props
  }: Props,
  ref: Ref<HTMLInputElement>,
) {
  return (
    <div className={clsx(container, containerClassName)}>
      <div
        className={inputContainer({
          underlined,
        })}
      >
        {tooltip && (
          <Tooltip message={tooltip.message} placement='left'>
            <img src={tooltip?.icon || AlertIcon} alt='error' />
          </Tooltip>
        )}

        <input
          ref={ref as Ref<HTMLInputElement>}
          {...(props as InputProps)}
          className={clsx(
            textarea({
              size,
              isValid,
            }),
            props.className,
          )}
          maxLength={props.maxLength}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
        />
      </div>

      {errorMessage && (
        <Text size={12} fontWeight={400} color='red-500'>
          {errorMessage}
        </Text>
      )}
    </div>
  );
}

const UnderlinedInput = forwardRef(Input);
export default UnderlinedInput;
