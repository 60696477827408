import { PollKind } from '@pickme/core';

export enum TemplateCategory {
  학교,
  협회,
  학회,
  동호회,
  노조,
  전체,
}

export type Template = {
  id: string;
  title: string;
  description: string;
  categories: TemplateCategory[];
  kind: PollKind;
  poll: any;
};
